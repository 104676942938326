import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { groupBy } from 'lodash';
// mui
import { useMediaQuery, useTheme } from '@mui/material';
// routes
import { useParams, useRouter } from 'routes/hook';
// hooks
import { useBoolean } from 'hooks/use-boolean';
import { useSocketContext } from 'hooks/use-socket-context';
// types
import {
    IBetSlip,
    ICompetitionEvent,
    IFullEvent,
    ILiveCategoryItem,
    ILiveEventItem,
    IPeriodTime,
    IPrematchCateogryItem
} from 'types/sports';
// api
import {
    getCompetitionEvents,
    getEvent,
    getEventCount,
    getLiveCategory,
    getLiveCategoryEvents,
    getPeriods,
    getPrematchCategory
} from 'api/sports';
//
import { SportContext } from './sport-context';

type SportProviderProps = {
    children: React.ReactNode;
};

const EVENT: IFullEvent = {
    eventId: 0,
    betCancellations: [],
    correlationId: '',
    createdAt: new Date().toString(),
    creationTime: new Date().toString(),
    eventInfo: {
        id: 0,
        name: '',
        status: 0,
        tradingStatus: 0,
        statusDescription: '',
        country: {
            id: 0,
            name: ''
        },
        sport: {
            id: 0,
            name: ''
        },
        competition: {
            id: 0,
            name: ''
        },
        season: {},
        competitors: [],
        startTime: '',
        isOutright: false,
        customDisplayData: {}
    },
    leagueId: 0,
    locationId: 0,
    markets: [],
    scoreboard: {
        time: 0,
        results: [],
        periods: [],
        incidents: [],
        currentPeriodId: 0,
        lastUpdateTime: '',
        homeGameScore: null,
        awayGameScore: null
    },
    sportId: 0,
    type: 0,
    updatedAt: new Date().toString(),
    version: 0,
    metaData: {},
    metaDataType: ''
};

const SportProvider = ({ children }: SportProviderProps) => {
    const router = useRouter();
    const location = useLocation();
    const param: any = useParams();

    const { socket } = useSocketContext();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isLive = useBoolean(false);
    const liveLoading = useBoolean(true);
    const eventLoading = useBoolean(true);
    const prematchLoading = useBoolean(true);
    const competitionLoading = useBoolean(true);
    const [eventId, setEventId] = useState('');
    const [sportId, setSportId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [competitionId, setCompetitionId] = useState('');
    const [period, setPeriod] = useState({ label: 'All', value: 'all' });
    const [counts, setCounts] = useState({ liveCount: 0, upcomingCount: 0 });
    const [periods, setPeriods] = useState<{ [key: string]: string }>({});
    const [betslip, setBetslip] = useState<IBetSlip[]>([]);
    const [event, setEvent] = useState<IFullEvent>(EVENT);
    const [liveCategory, setLiveCategory] = useState<ILiveCategoryItem[]>([]);
    const [liveEvents, setLiveEvents] = useState<{ [key: string]: ILiveEventItem }>({});
    const [prematchCategory, setPrematchCategory] = useState<IPrematchCateogryItem[]>([]);
    const [competitionEvents, setCompetitionEvents] = useState<{ [key: string]: ICompetitionEvent[] }>({});

    const marketType = useMemo(() => {
        if (!sportId) return [];
        if (isLive) {
            const sportItem = liveCategory.find((c) => String(c.sportId) === sportId);
            if (sportItem) {
                return sportItem.marketType;
            }
            return [];
        }
        const sportItem = prematchCategory.find((c) => String(c.sportId) === sportId);
        if (sportItem) {
            return sportItem.marketType;
        }
        return [];
    }, [sportId, prematchCategory, liveCategory, isLive]);

    const markets = useMemo(
        () =>
            groupBy(
                event.markets.sort((a, b) => a.id - b.id),
                'type'
            ),
        [event.markets]
    );

    const getMatchCount = async () => {
        try {
            const countData = await getEventCount();
            setCounts(countData);
        } catch (error) {
            console.log(error);
        }
    };

    const loadLiveCategory = async () => {
        try {
            liveLoading.onTrue();
            const data = await getLiveCategory();
            setLiveCategory(data);
            if (!location.pathname.split('/')[5] && sportId === '' && !isMobile) {
                const defaultItem = data[0];
                const defaultCompetitionId = Object.keys(defaultItem.country[0].events)[0];
                router.push(
                    `/sports/live/event-view/${defaultItem.sportId}/${defaultItem.country[0].countryId}/${defaultCompetitionId}/${defaultItem.country[0].events[defaultCompetitionId][0].id}`
                );
                // if (!param.eventId) {
                //         const sport = data[0];
                //         const country = sport.country[0];
                //         const competition = country.events[Object.keys(country.events)[0]][0].eventInfo;
                //         dispatch(
                //             setParamInfo({
                //                 ...paramInfo,
                //                 sportId: sport.sportId,
                //                 sportName: sport.sportName,
                //                 countryId: country.countryId,
                //                 countryName: country.countryName,
                //                 competitionId: competition.competition.id,
                //                 competitionName: competition.competition.name,
                //                 eventId: competition.id,
                //                 eventName: competition.name
                //             })
                //         );
                //         router.push(
                //             `${paths.sports.live.event_view}/${sport.sportId}/${country.countryName}/${competition.competition.id}/${competition.competition.name}/${competition.id}/${competition.name}`
                //         );
                //     } else {
                //         let currentActive = {
                //             ...param
                //         };
                //         const sportIndex = data.findIndex((sItem: any) => sItem.sportId === Number(currentActive.sportId));
                //         if (sportIndex !== -1) {
                //             const sport = data[sportIndex];
                //             const countryIndex = sport.country.findIndex((c: any) => c.countryName === param.countryId);
                //             if (countryIndex === -1) {
                //                 const country = sport.country[0];
                //                 const competition = country.events[Object.keys(country.events)[0]][0].eventInfo;
                //                 currentActive.competitionId = competition.competition.id;
                //                 currentActive.competitionName = competition.competition.name;
                //                 currentActive.countryName = country.countryName;
                //                 currentActive.countryId = country.countryId;
                //                 currentActive.eventId = competition.id;
                //                 currentActive.eventName = competition.name;
                //             } else {
                //                 const country = sport.country[countryIndex];
                //                 currentActive.countryName = country.countryName;
                //                 currentActive.countryId = country.countryId;
                //                 if (country.events[`${currentActive.competitionId}`]) {
                //                     const events = country.events[`${currentActive.competitionId}`];
                //                     const eventIndex = events.findIndex((c: any) => c.id === Number(currentActive.eventId));
                //                     if (eventIndex === -1) {
                //                         currentActive.eventId = events[0].id;
                //                         currentActive.eventName = events[0].eventInfo.name;
                //                     }
                //                 } else {
                //                     const competition = country.events[Object.keys(country.events)[0]][0].eventInfo;
                //                     currentActive.competitionId = competition.competition.id;
                //                     currentActive.competitionName = competition.competition.name;
                //                     currentActive.eventId = competition.id;
                //                     currentActive.eventName = competition.name;
                //                 }
                //             }
                //         } else {
                //             const sport = data[0];
                //             const country = sport.country[0];
                //             const competition = country.events[Object.keys(country.events)[0]][0].eventInfo;
                //             currentActive.competitionId = competition.competition.id;
                //             currentActive.competitionName = competition.competition.name;
                //             currentActive.countryName = country.countryName;
                //             currentActive.countryId = country.countryId;
                //             currentActive.eventId = competition.id;
                //             currentActive.eventName = competition.name;
                //         }
                //         dispatch(
                //             setParamInfo({
                //                 ...paramInfo,
                //                 ...currentActive
                //             })
                //         );
                //         router.push(
                //             `${paths.sports.live.event_view}/${currentActive.sportId}/${currentActive.countryName}/${currentActive.competitionId}/${currentActive.competitionName}/${currentActive.eventId}/${currentActive.eventName}`
                //         );
                // }
            }
            const events = await getLiveCategoryEvents();
            if (events) {
                setLiveEvents(events);
            }
        } catch (error) {
            console.error(error);
        } finally {
            liveLoading.onFalse();
        }
    };

    const loadPrematchCategory = useCallback(
        async (time: string = 'all') => {
            try {
                prematchLoading.onTrue();
                const data = await getPrematchCategory(time);
                setPrematchCategory(data);
                if (!location.pathname.split('/')[5] && sportId === '' && !isMobile) {
                    router.push(
                        `/sports/pre-match/event-view/${data[0].sportId}/${data[0].country[0].countryId}/${data[0].country[0].competition[0].competitionId}`
                    );
                    // if (!param.league) {
                    //     dispatch(
                    //         selectCompetition({
                    //             selectedCompetition: {
                    //                 ...selectedCompetition,
                    //                 sportId: data[0].sportId,
                    //                 competitionId: data[0].country[0].competition[0].competitionId,
                    //                 competitionName: data[0].country[0].competition[0].competitionName,
                    //                 countryName: data[0].country[0].countryName
                    //             },
                    //             markets: data[0].marketType
                    //         })
                    //     );
                    //     router.push(
                    //         `/sports/pre-match/event-view/${data[0].sportId}/${data[0].country[0].countryName}/${data[0].country[0].competition[0].competitionId}/${data[0].country[0].competition[0].competitionName}`
                    //     );
                    // } else {
                    //     let currentActive = {
                    //         sportId: param.sport,
                    //         competitionId: param.leagueGroup,
                    //         competitionName: param.league,
                    //         countryName: param.country
                    //     };
                    //     const sportIndex = data.findIndex((sItem: any) => sItem.sportId === Number(currentActive.sportId));
                    //     if (sportIndex !== -1) {
                    //         const sportItem = data[sportIndex];
                    //         const countryIndex = sportItem.country.findIndex((c: any) => c.countryName === param.country);
                    //         if (countryIndex === -1) {
                    //             currentActive.competitionId = sportItem.country[0].competition[0].competitionId;
                    //             currentActive.competitionName = sportItem.country[0].competition[0].competitionName;
                    //             currentActive.countryName = sportItem.country[0].countryName;
                    //         } else {
                    //             const countryItem = sportItem.country[countryIndex];
                    //             const competitionIndex = countryItem.competition.findIndex(
                    //                 (compe: any) => compe.competitionId === Number(param.leagueGroup)
                    //             );
                    //             if (competitionIndex === -1) {
                    //                 currentActive.competitionId = countryItem.competition[0].competitionId;
                    //                 currentActive.competitionName = countryItem.competition[0].competitionName;
                    //                 currentActive.countryName = countryItem.countryName;
                    //             } else {
                    //                 currentActive.competitionId = countryItem.competition[competitionIndex].competitionId;
                    //                 currentActive.competitionName =
                    //                     countryItem.competition[competitionIndex].competitionName;
                    //                 currentActive.countryName = countryItem.countryName;
                    //             }
                    //         }
                    //     } else {
                    //         currentActive = {
                    //             sportId: data[0].sportId,
                    //             competitionId: data[0].country[0].competition[0].competitionId,
                    //             competitionName: data[0].country[0].competition[0].competitionName,
                    //             countryName: data[0].country[0].countryName
                    //         };
                    //     }
                    //     dispatch(
                    //         selectCompetition({
                    //             selectedCompetition: {
                    //                 ...selectedCompetition,
                    //                 ...currentActive
                    //             },
                    //             markets: data[sportIndex === -1 ? 0 : sportIndex].marketType
                    //         })
                    //     );
                    //     router.push(
                    //         `/sports/pre-match/event-view/${currentActive.sportId}/${currentActive.countryName}/${currentActive.competitionId}/${currentActive.competitionName}`
                    //     );
                    // }
                }
                // else {
                //     router.push(
                //         `/sports/pre-match/event-view/${sportId}/${countryId}/${competitionId}`
                //     );
                // }
            } catch (error) {
                console.error(error);
            } finally {
                prematchLoading.onFalse();
            }
        },
        // eslint-disable-next-line
        [prematchLoading, sportId, router]
    );

    const loadCompetitionEvents = useCallback(async () => {
        try {
            if (!sportId || !competitionId) return;
            competitionLoading.onTrue();
            const data = await getCompetitionEvents(sportId, competitionId);
            setCompetitionEvents(data);
        } catch (error) {
            console.error(error);
        } finally {
            competitionLoading.onFalse();
        }
    }, [sportId, competitionId, competitionLoading]);

    const loadEvent = useCallback(async () => {
        try {
            eventLoading.onTrue();
            const data = await getEvent(eventId);
            setEvent(data);
        } catch (error) {
            console.error(error);
        } finally {
            eventLoading.onFalse();
        }
    }, [eventId, eventLoading]);

    const loadPeriods = useCallback(async () => {
        try {
            const data = await getPeriods();
            setPeriods(data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const addBetSlip = (newSlip: IBetSlip) => {
        setBetslip((pre) => {
            const checkedSlip = pre.map((b) => (b.eventId === newSlip.eventId ? { ...b, allowMulti: false } : b));
            const checkMulti = pre.findIndex((b) => b.eventId === newSlip.eventId);
            return [...checkedSlip, { ...newSlip, allowMulti: checkMulti === -1 }];
        });
    };

    const removeBetSlip = (id: string) => {
        setBetslip((pre) => {
            let slips = pre.filter((p) => p.id !== id);
            if (!slips.length) {
                localStorage.setItem('odds', JSON.stringify([]));
            } else {
                const groupedSlip = groupBy(slips, 'eventId');
                const eventIds: number[] = [];
                Object.keys(groupedSlip).forEach((k) => {
                    if (groupedSlip[k].length > 1) {
                        eventIds.push(Number(k));
                    }
                });
                slips = slips.map((slip) => ({ ...slip, allowMulti: !eventIds.includes(slip.eventId) }));
            }
            return slips;
        });
    };

    const removeBetSlips = (ids: string[]) => {
        setBetslip((pre) => {
            let slips = pre.filter((p) => !ids.includes(p.id));
            if (!slips.length) {
                localStorage.setItem('odds', JSON.stringify([]));
            } else {
                const groupedSlip = groupBy(slips, 'eventId');
                const eventIds: number[] = [];
                Object.keys(groupedSlip).forEach((k) => {
                    if (groupedSlip[k].length > 1) {
                        eventIds.push(Number(k));
                    }
                });
                slips = slips.map((slip) => ({ ...slip, allowMulti: !eventIds.includes(slip.eventId) }));
            }
            return slips;
        });
    };

    const removeAllSlip = () => {
        localStorage.setItem('odds', JSON.stringify([]));
        setBetslip([]);
    };

    const updateLiveEvents = useCallback(
        (eventData: { [key: string]: IFullEvent }) => {
            setLiveEvents((pre) => ({ ...pre, ...eventData }));
            if (eventId && eventData[eventId]) {
                setEvent({ ...eventData[eventId] });
            }
        },
        [eventId]
    );

    useEffect(() => {
        if (betslip.length) {
            localStorage.setItem('odds', JSON.stringify(betslip));
        }
    }, [betslip]);

    useEffect(() => {
        if (!isLive.value && competitionId) {
            loadCompetitionEvents();
        }
        // eslint-disable-next-line
    }, [isLive.value, competitionId]);

    useEffect(() => {
        if (eventId) {
            loadEvent();
        }
        // eslint-disable-next-line
    }, [isLive.value, eventId]);

    useEffect(() => {
        if (param.competitionId && param.competitionId !== competitionId) {
            setCompetitionId(param.competitionId);
        }
        if (param.countryId && param.countryId !== countryId) {
            setCountryId(param.countryId);
        }
        if (param.sportId && param.sportId !== sportId) {
            setSportId(param.sportId);
        }
        if (param.eventId && param.eventId !== eventId) {
            setEventId(param.eventId);
        }
        // eslint-disable-next-line
    }, [param]);

    useEffect(() => {
        if (location.pathname.includes('/live/event-view')) {
            isLive.onTrue();
            getMatchCount();
            loadLiveCategory();
        } else if (location.pathname.includes('/pre-match/event-view')) {
            isLive.onFalse();
            if (prematchCategory.length) return;
            getMatchCount();
            loadPrematchCategory();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        loadPeriods();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const stringOdds = localStorage.getItem('odds');
        if (!stringOdds) return;
        const objectOdds = JSON.parse(stringOdds);
        if (objectOdds) {
            setBetslip(objectOdds);
        }
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('events', (socketMatch) => {
                updateLiveEvents(socketMatch);
            });
        }
        return () => {
            if (socket) {
                socket.off('events');
            }
        };
        // eslint-disable-next-line
    }, [socket, updateLiveEvents]);

    useEffect(() => {
        if (socket) {
            socket.emit('room', { name: 'event-update', action: 'join' });
        }
    }, [socket]);

    const updatePeriod = useCallback((newPeriod: IPeriodTime) => {
        setPeriod(newPeriod);
        loadPrematchCategory(newPeriod.value);
        // eslint-disable-next-line
    }, []);

    const memoizedValue = useMemo(
        () => ({
            event,
            liveCategory,
            liveEvents,
            prematchCategory,
            competitionEvents,
            period,
            periods,
            marketType,
            isLive: isLive.value,
            liveLoading: liveLoading.value,
            eventLoading: eventLoading.value,
            premacthLoading: prematchLoading.value,
            competitionLoading: competitionLoading.value,
            eventId,
            sportId,
            countryId,
            competitionId,
            betslip,
            markets,
            ...counts,
            //
            updatePeriod,
            addBetSlip,
            removeBetSlip,
            removeBetSlips,
            removeAllSlip
        }),
        [
            event,
            liveCategory,
            liveEvents,
            prematchCategory,
            competitionEvents,
            period,
            periods,
            marketType,
            betslip,
            counts,
            markets,
            eventId,
            sportId,
            countryId,
            competitionId,
            isLive.value,
            liveLoading.value,
            eventLoading.value,
            prematchLoading.value,
            competitionLoading.value,
            updatePeriod
        ]
    );

    return <SportContext.Provider value={memoizedValue}> {children}</SportContext.Provider>;
};

export default SportProvider;
