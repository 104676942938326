import { Stack, Typography } from '@mui/material';

export const CheckField = ({ checked, text }: { checked: boolean; text: string }) => (
    <Stack direction="row" mt={0.2}>
        <p className="validation-marker">
            <span className={`validation-check-marker ${checked ? 'bc-i-checked' : 'invalid'}`} />
        </p>
        <Typography fontSize={12}>{text}</Typography>
    </Stack>
);
