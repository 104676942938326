import { useEffect, useMemo, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
// import OddButton from 'components/Sports/OddButton';
import { useBoolean } from 'hooks/use-boolean';
// others
import { Icon } from '@iconify/react';
// hooks
import { useSportContext } from 'hooks/use-sport-context';
import { useRouter } from 'routes/hook';
import { paths } from 'routes/paths';
// type
import { ILiveCategoryCountry, ILiveEventInfo, IMarketType, IScoreBoard } from 'types/sports';

type MatchItemTypes = {
    eventInfo: ILiveEventInfo;
    scoreboard: IScoreBoard;
    marketCount: number;
};
const LiveMatchItem = (props: MatchItemTypes) => {
    const { eventInfo, scoreboard, marketCount } = props;
    const router = useRouter();
    const handleClick = () => {
        router.push(
            `${paths.sports.live.event_view}/${eventInfo.sport.id}/${eventInfo.country.id}/${eventInfo.competition.id}/${eventInfo.id}`
        );
    };
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                handleClick();
                e.stopPropagation();
            }}
        >
            <Stack direction="row" minHeight={52}>
                <Stack
                    flex={1}
                    sx={{
                        padding: '5px',
                        bgcolor: 'translucent.01',
                        gap: 0.3
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={12}>
                            {eventInfo.competitors.length > 1 ? eventInfo.competitors[0].name : ''}
                        </Typography>
                        <Typography fontSize={12} color="gold">
                            {scoreboard && scoreboard.results.length > 0 ? scoreboard.results[0].value : 0}
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={12}>
                            {eventInfo.competitors.length > 1 ? eventInfo.competitors[1].name : ''}
                        </Typography>
                        <Typography fontSize={12} color="gold">
                            {scoreboard && scoreboard.results.length > 0 ? scoreboard.results[1].value : 0}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                flex={1}
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01'
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon icon="carbon:circle-filled" style={{ width: 15, height: 15 }} color="#ff3449" />
                    <Typography color="textSecondary" fontSize={11}>
                        2nd Half
                    </Typography>
                    <Typography color="textSecondary" fontSize={11}>
                        1:1,(0:0),(1:1)81`
                    </Typography>
                </Stack>
                <Typography color="textSecondary" fontSize={12}>
                    {marketCount && marketCount > 0 ? `+${marketCount}` : 0}
                </Typography>
            </Stack>
        </Stack>
    );
};

const MobileSportItem = ({ country }: { country: ILiveCategoryCountry }) => {
    const isOpened = useBoolean(true);
    const handleOpenSport = () => {
        isOpened.onToggle();
    };

    return (
        <Box onClick={handleOpenSport} sx={{ bgcolor: 'translucent.01', borderRadius: 1, transition: 'all 0.24s' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 37,
                    pl: 0.3,
                    pr: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 19px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box
                    component="img"
                    src={`https://flagcdn.com/w320/${country.code}.png`}
                    sx={{ width: 18, height: 12 }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        ml: 1,
                        height: 1
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            mr: 1,
                            textTransform: 'capitalize',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {country.countryName}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 13,
                            mr: 1,
                            textTransform: 'capitalize',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {country.events[`${Object.keys(country.events)[0]}`][0].eventInfo.competition.name}
                    </Typography>
                </Box>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack gap={0.5} p={0.5}>
                    {country.events[`${Object.keys(country.events)[0]}`].map((event, i) => (
                        <LiveMatchItem
                            key={i}
                            eventInfo={event.eventInfo}
                            scoreboard={event.scoreboard}
                            marketCount={event.marketCount}
                        />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const MobileLiveList = () => {
    const { liveLoading, marketType, liveCategory, sportId } = useSportContext();
    const [selectedMarket, setSelectedMarket] = useState<IMarketType | null>(null);
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);
    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };
    const closeMarketPopover = (value: IMarketType | null) => {
        if (value) setSelectedMarket(value);
        setMarketAnchor(null);
    };

    const countries = useMemo(() => {
        const sportItem = liveCategory.find((item) => String(item.sportId) === sportId);
        if (sportItem) return sportItem.country;
        return [];
    }, [liveCategory, sportId]);

    useEffect(() => {
        if (marketType.length > 0) setSelectedMarket(marketType[0]);
    }, [marketType]);

    return (
        <Box
            sx={{
                flex: 'auto',
                position: 'relative',
                mt: 0.5
            }}
        >
            <Stack direction="row" px={1} py={0.2} gap={0.5} width={1} bgcolor="translucent.01" alignItems="center">
                <Button
                    onClick={openMarketPopover}
                    size="small"
                    endIcon={<Icon icon="lsicon:down-outline" width="24" height="24" />}
                    sx={{
                        px: 1,
                        justifyContent: 'space-between',
                        flex: 1,
                        bgcolor: 'translucent.05',
                        textTransform: 'uppercase',
                        height: 25
                    }}
                >
                    {selectedMarket?.name}
                </Button>
                <Stack
                    position="relative"
                    direction="row"
                    justifyContent="space-around"
                    flex={1}
                    sx={{
                        boxShadow: '-1px 0 0 0 #000c24'
                    }}
                >
                    <Typography fontSize={11}>W1</Typography>
                    <Typography fontSize={11}>X</Typography>
                    <Typography fontSize={11}>W2</Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    marginTop: 1,
                    width: 1,
                    minHeight: '100%',
                    pr: 0.2
                }}
                spacing={0.2}
            >
                {(() => {
                    if (liveLoading) {
                        return new Array(5).fill(1).map((_, i) => (
                            <Stack sx={{ mb: 0.5 }} key={i}>
                                <Stack flexDirection="row">
                                    <Stack
                                        sx={{
                                            width: 0.5,
                                            gap: 1,
                                            py: 1,
                                            px: 0.5,
                                            bgcolor: 'translucent.01'
                                        }}
                                    >
                                        <Skeleton sx={{ width: 0.6, height: '10px' }} />
                                        <Skeleton sx={{ width: 0.6, height: '10px' }} />
                                    </Stack>
                                    <Stack
                                        flexDirection="row"
                                        sx={{
                                            width: 0.5,
                                            bgcolor: 'translucent.02',
                                            justifyContent: 'space-around',
                                            px: 1,
                                            py: 1
                                        }}
                                    >
                                        <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                        <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                        <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                    </Stack>
                                </Stack>
                                <Stack
                                    sx={{
                                        py: 0.5,
                                        bgcolor: 'translucent.01',
                                        mt: 0.1
                                    }}
                                >
                                    <Skeleton sx={{ width: 0.6, height: '7px' }} />
                                </Stack>
                            </Stack>
                        ));
                    }

                    // Directly return this if loading.value is true
                    return countries.map((country, i) => <MobileSportItem key={i} country={country} />);
                })()}
            </Stack>

            {/* league popover */}
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={() => closeMarketPopover(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: '90%',
                        marginLeft: '-7px',
                        maxHeight: 230,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 0px 0px #0000004d, inset 0 0 0 100px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {marketType.map((market, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeMarketPopover(market)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{market.name}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};

export default MobileLiveList;
