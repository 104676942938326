import { Box, Button, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { useBoolean } from 'hooks/use-boolean';

export default function MultiMatchs({ fullList }: { fullList: boolean }) {
    const isOpened = useBoolean(true);

    return (
        <Box>
            <Box sx={{ mb: 0.2, cursor: 'pointer' }} onClick={isOpened.onToggle}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        mt: 0.4,
                        pl: 1,
                        pr: 0.2,
                        bgcolor: 'translucent.05',
                        borderRadius: 1,
                        height: 36
                    }}
                >
                    <Typography
                        sx={{
                            mr: 0.4,
                            lineHeight: '15px',
                            flex: 'auto',
                            fontSize: 13,
                            color: 'translucent.07',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        15.11.2024
                    </Typography>

                    <IconButton>
                        <Box
                            component="i"
                            className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                            sx={{ fontSize: 11 }}
                        />
                    </IconButton>
                </Stack>
            </Box>

            <Collapse in={isOpened.value}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        height: 54,
                        width: 1,
                        py: 1,
                        pl: 1,
                        pr: 0.5,
                        borderRadius: 1,
                        bgcolor: 'translucent.01',
                        transition: 'background 0.24s',
                        '&:hover': {
                            bgcolor: 'translucent.04'
                        }
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            mr: 0.8,
                            height: 1,
                            flex: 'auto',
                            cursor: 'pointer',
                            overflow: 'hidden'
                        }}
                    >
                        <Stack
                            justifyContent="space-between"
                            sx={{ height: 1, mr: 1, overflow: 'hidden', userSelect: 'text' }}
                        >
                            <Typography className="ellipsis" sx={{ display: 'flex', fontSize: 12, fontWeight: 500 }}>
                                Kazakhstan
                            </Typography>
                            <Typography className="ellipsis" sx={{ display: 'flex', fontSize: 12, fontWeight: 500 }}>
                                Austria
                            </Typography>
                        </Stack>

                        <Stack justifyContent="space-between" sx={{ height: 1 }}>
                            <Typography
                                sx={{
                                    color: '#ffffff80',
                                    fontSize: 11,
                                    whiteSpace: 'nowrap',
                                    textAlign: 'end'
                                }}
                            >
                                00:00
                            </Typography>

                            <Stack direction="row" alignItems="center">
                                <Box component="i" className="bc-i-favorite" sx={{ fontSize: 11, color: '#fffc' }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ml: 0.5
                                    }}
                                >
                                    <Box
                                        component="i"
                                        className="c-info-icon-bc bc-i-bet-builder"
                                        sx={{ fontSize: 11, color: '#eacb0b' }}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: 50,
                                height: 1,
                                minWidth: 0,
                                fontSize: 11,
                                borderRadius: 1,
                                color: '#ffd700',
                                bgcolor: 'translucent.02',
                                '&:hover': {
                                    color: 'common.white'
                                }
                            }}
                        >
                            11.00
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: 50,
                                height: 1,
                                minWidth: 0,
                                fontSize: 11,
                                borderRadius: 1,
                                color: '#ffd700',
                                bgcolor: 'translucent.02',
                                '&:hover': {
                                    color: 'common.white'
                                }
                            }}
                        >
                            11.00
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: 50,
                                height: 1,
                                minWidth: 0,
                                fontSize: 11,
                                borderRadius: 1,
                                color: '#ffd700',
                                bgcolor: 'translucent.02',
                                '&:hover': {
                                    color: 'common.white'
                                }
                            }}
                        >
                            11.00
                        </Button>
                    </Stack>

                    {fullList && (
                        <>
                            <Box
                                sx={{
                                    ml: 0.8,
                                    mr: 0.3,
                                    height: 1,
                                    width: 49,
                                    fontSize: 11,
                                    display: 'flex',
                                    borderRadius: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #ffffff4d'
                                }}
                            >
                                +1.75-
                            </Box>
                            <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        width: 50,
                                        height: 1,
                                        minWidth: 0,
                                        fontSize: 11,
                                        borderRadius: 1,
                                        color: '#ffd700',
                                        bgcolor: 'translucent.02',
                                        '&:hover': {
                                            color: 'common.white'
                                        }
                                    }}
                                >
                                    11.00
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        width: 50,
                                        height: 1,
                                        minWidth: 0,
                                        fontSize: 11,
                                        borderRadius: 1,
                                        color: '#ffd700',
                                        bgcolor: 'translucent.02',
                                        '&:hover': {
                                            color: 'common.white'
                                        }
                                    }}
                                >
                                    11.00
                                </Button>
                            </Stack>
                        </>
                    )}

                    <Box
                        sx={{
                            ml: 0.8,
                            mr: 0.3,
                            height: 1,
                            width: 49,
                            fontSize: 11,
                            display: 'flex',
                            borderRadius: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #ffffff4d'
                        }}
                    >
                        +1.75-
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: 50,
                                height: 1,
                                minWidth: 0,
                                fontSize: 11,
                                borderRadius: 1,
                                color: '#ffd700',
                                bgcolor: 'translucent.02',
                                '&:hover': {
                                    color: 'common.white'
                                }
                            }}
                        >
                            11.00
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: 50,
                                height: 1,
                                minWidth: 0,
                                fontSize: 11,
                                borderRadius: 1,
                                color: '#ffd700',
                                bgcolor: 'translucent.02',
                                '&:hover': {
                                    color: 'common.white'
                                }
                            }}
                        >
                            11.00
                        </Button>
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            ml: 0.5,
                            height: 1,
                            width: 50,
                            fontSize: 12,
                            color: '#ffffff80',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#fffc'
                            }
                        }}
                    >
                        +371
                        <Box
                            component="i"
                            className="bc-i-small-arrow-right"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 24,
                                height: 24,
                                color: '#fffc'
                            }}
                        />
                    </Stack>
                </Stack>
            </Collapse>
        </Box>
    );
}
