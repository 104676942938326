import { createContext } from 'react';

export type RegisterValue = {
    username: string;
    name: string;
    phone: string;
    password: string;
};

export type AuthContextValue = {
    authLoading: boolean;
    isLogined: boolean;
    accessToken: string;
    user: any;
    balance: number;
};

const AuthContext = createContext<AuthContextValue | null>(null);

export { AuthContext };
