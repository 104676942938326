import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { io, Socket } from 'socket.io-client';
// config
import { HOST_API_KEY } from 'config';
// hooks
import { useAuthContext } from 'hooks/use-auth-context';
//
import { SocketContext } from './socket-context';

type SocketProviderProps = {
    children: React.ReactNode;
};

const SocketProvider = ({ children }: SocketProviderProps) => {
    const { isLogined, user, accessToken } = useAuthContext();
    const [socket, setSocket] = useState<Socket | null>(null);

    const registerCallbacks = useCallback(() => {
        if (socket && user) {
            socket.on('destory', (dd) => {
                if (user && user._id && dd.data[user._id]) {
                    // dispatch(logoutAction());
                    localStorage.removeItem('accessToken');
                    window.location.reload();
                }
            });
            // socket.on('logout', (data) => {
            //     if (data[user._id]) {
            //         logout();
            //     }
            // });
            // socket.on('multi-login', (data) => {
            //     if (data.token === accessToken) {
            //         dispatch(logoutAction());
            //         localStorage.removeItem('accessToken');
            //     }
            // });
            // socket.on('balance', (data) => {
            //     if (data[user._id]) {
            //         dispatch(updateBalance(data[user._id]));
            //     }
            // });
            // socket.on('notification', (data) => {
            //     if (data[user._id]) {
            //         const message = data[user._id];
            //         const logo = siteSetting.config?.domain.logo;
            //         checkPageStatus(message, logo);
            //     }
            // });
            // socket.on('sportsBlock', (data) => {
            //     if (data[user._id]) {
            //         dispatch(updateSportsBlock(data[user._id]));
            //     }
            // });
            // socket.on('eventSetting', (data) => {
            //     eventSetting(data);
            // });
        }
    }, [user, socket]);

    const cleanUp = () => {
        setSocket(null);
    };

    const connect = (token: string) => {
        const socketInit: Socket = io(String(HOST_API_KEY), {
            transports: ['websocket'],
            upgrade: false,
            query: { auth: token }
        });
        registerCallbacks();
        setSocket(socketInit);
        return socketInit;
    };

    useEffect(() => {
        connect(accessToken ?? '');
        return () => cleanUp();
        // eslint-disable-next-line
    }, [isLogined, accessToken]);

    const memoizedValue = useMemo(() => ({ socket }), [socket]);

    return <SocketContext.Provider value={memoizedValue}> {children}</SocketContext.Provider>;
};

export default SocketProvider;
