import { createSlice } from '@reduxjs/toolkit';
import { ACCEPT_ODD_TYPE } from 'config';
// types
import { ISportStore } from 'types/sports/store';

const initialState: ISportStore = {
    selectedCompetition: {
        sportId: 0,
        sportName: '',
        competitionId: 0,
        competitionName: '',
        countryId: 0,
        countryName: '',
        eventId: 0
    },
    paramInfo: {
        sportId: 0,
        sportName: '',
        countryId: 0,
        countryName: '',
        competitionId: 0,
        competitionName: '',
        eventId: 0,
        eventName: ''
    },
    prematchCategory: {},
    prematchCount: {},
    competitionEvents: {},
    leagueList: [],
    markets: [],
    odds: [],
    liveEvents: { data: [], count: 0 },
    liveCategoryEvents: {},
    betType: 'single',
    acceptOdd: ACCEPT_ODD_TYPE[0]
};

const sportsSlice = createSlice({
    name: 'sports',
    initialState,
    reducers: {
        selectCompetition(state, action) {
            state.selectedCompetition = action.payload.selectedCompetition;
            state.markets = action.payload.markets;
        },
        setPrematchCategory(state, action) {
            state.prematchCategory[action.payload.key] = action.payload.data;
        },
        setPrematchCount(state, action) {
            state.prematchCount[action.payload.key] = action.payload.count;
        },
        setEventCount(state, action) {
            state.liveEvents.count = action.payload.liveCount;
            state.prematchCount.all = action.payload.upcomingCount;
        },
        setCompetitionEvents(state, action) {
            state.competitionEvents = action.payload;
        },
        setLeagueList(state, action) {
            state.leagueList = action.payload;
        },
        setOdds(state, action) {
            state.odds = action.payload;
            localStorage.setItem('odds', JSON.stringify(action.payload));
        },
        setLiveEvents(state, action) {
            state.liveEvents = action.payload;
        },
        setParamInfo(state, action) {
            state.paramInfo = action.payload;
        },
        setLiveCategoryEvetns(state, action) {
            state.liveCategoryEvents = action.payload;
        },
        setBetType(state, action) {
            state.betType = action.payload;
        },
        setAccetOdd(state, action) {
            state.acceptOdd = action.payload;
        }
    }
});

export const {
    selectCompetition,
    setPrematchCategory,
    setCompetitionEvents,
    setLeagueList,
    setOdds,
    setLiveEvents,
    setParamInfo,
    setLiveCategoryEvetns,
    setEventCount,
    setPrematchCount,
    setBetType,
    setAccetOdd
} = sportsSlice.actions;

export default sportsSlice.reducer;
