import { useContext } from 'react';
//
import { SportContext } from 'context/sport/sport-context';

// ----------------------------------------------------------------------

export const useSportContext = () => {
    const context = useContext(SportContext);

    if (!context) throw new Error('useSportContext context must be use inside AuthProvider');

    return context;
};
