import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Dialog, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
// other
import { Icon } from '@iconify/react';
// assets
import ukFlag from 'assets/image/flags/gb.png';
// config
import { STYLE } from 'config';
// components
import Logo from 'components/Logo/logo';
import Login from 'components/Auth/Login';
import Register from 'components/Auth/Register';

import AccountSettingDialog from 'pages/Account';
// routes
import { paths } from 'routes/paths';
// store
import { useDispatch, useSelector } from 'store/store';
import { closeAuthModal, openAuthModal } from 'store/slices/auth';
// hooks
import { useParams, useRouter } from 'routes/hook';
// types
import { childrenProp } from 'types/sports/profile';
import { ISportParams } from 'types/sports';
// utils
import { getCurrentTime } from 'utils/base';
// components
import SportHeader from './SportHeader';
import HeaderButton from './HeaderButton';
import UserBalance from './UserBalance';
import UserInfo from './UserInfo';

// Navigation

const navigation = [
    {
        title: 'Live',
        icon: <Icon icon="carbon:circle-filled" style={{ width: 22, height: 22 }} />,
        path: paths.sports.live.event_view,
        isMain: true,
        children: [
            {
                title: 'Event View',
                path: paths.sports.live.event_view
            },
            {
                title: 'Live Calendar',
                path: paths.sports.live.live_calendar
            },
            {
                title: 'Results',
                path: paths.sports.live.results
            }
        ]
    },
    {
        title: 'Sports',
        icon: <Icon icon="material-symbols-light:sports-and-outdoors" style={{ width: 22, height: 22 }} />,
        path: paths.sports.pre.event_view,
        isMain: true,
        children: [
            {
                title: 'Event View',
                path: paths.sports.pre.event_view
            },
            {
                title: 'Live Calendar',
                path: paths.sports.pre.live_calendar
            },
            {
                title: 'Results',
                path: paths.sports.pre.results
            }
        ]
    },
    {
        title: 'Casino',
        icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: true,
        children: [
            {
                title: 'Home',
                path: ''
            },
            {
                title: 'Tournaments',
                path: ''
            },
            {
                title: 'Jackpots',
                path: ''
            }
        ]
    },
    {
        title: 'Live Casino',
        icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'Games',
        icon: <Icon icon="fad:random-1dice" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'Virtual sports',
        icon: <Icon icon="mdi:horse-variant" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'E-sports',
        icon: <Icon icon="material-symbols:sports-esports-rounded" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    }
];
const MoreNavData = {
    title: 'More',
    icon: <Icon icon="ci:more-grid-big" style={{ width: 22, height: 22 }} />,
    path: '',
    children: [
        {
            title: 'Live Casino',
            icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'Games',
            icon: <Icon icon="fad:random-1dice" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'Virtual sports',
            icon: <Icon icon="mdi:horse-variant" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'E-sports',
            icon: <Icon icon="material-symbols:sports-esports-rounded" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        }
    ]
};

// Auth Modal

const headerBorderStyle = {
    borderColor: 'translucent.01',
    borderWidth: 1,
    borderStyle: 'none none solid none'
};

// mobile

interface LinkTabProps {
    label?: string;
    href?: string;
    selected?: boolean;
}
const samePageLinkNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
};

const LinkTab = ({ selected, label, ...other }: LinkTabProps) => (
    <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            // Routing libraries handle this, you can remove the onClick handle when using them.
            if (samePageLinkNavigation(event)) {
                event.preventDefault();
            }
        }}
        aria-current={selected && 'page'}
        sx={{
            flex: 1,
            fontSize: '13px',
            minWidth: label === 'Virtual sports' ? '100px !important' : '80px',
            width: '100%',
            marginRight: '5px !important',
            minHeight: '40px !important'
        }}
        label={label}
        {...other}
    />
);

const Header = () => {
    const theme = useTheme();

    const param: ISportParams = useParams();
    const isTableletdown = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const [currentTime, setCurrentTime] = useState<string>('');

    const { pathname } = useLocation();
    const { authModal, accessToken, user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const router = useRouter();

    const [langAanchor, setLangAnchor] = useState<HTMLElement | null>(null);
    const [moreAanchor, setMoreAnchor] = useState<HTMLElement | null>(null);

    const [accountDialogStatus, setAccountDialogStatus] = useState({
        status: false,
        item: {
            text: '',
            value: ''
        }
    });

    const openAccountDialog = (value: childrenProp) => {
        setAccountDialogStatus({ status: true, item: value });
    };

    const openLangPopover = (event: React.MouseEvent<HTMLElement>) => {
        setLangAnchor(event.currentTarget as HTMLElement);
    };

    const openMorePopover = (event: React.MouseEvent<HTMLElement>) => {
        setMoreAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setLangAnchor(null);
    };

    const closeMorePopover = () => {
        setMoreAnchor(null);
    };

    const handleClose = () => {
        dispatch(closeAuthModal(''));
    };
    const handleOpen = (page: string) => {
        dispatch(openAuthModal(page));
    };
    // mobile
    const [selectedRoute, setSelectedRoute] = useState<string>('1');
    const handleChangeRoute = (event: React.SyntheticEvent, newValue: string) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
        ) {
            setSelectedRoute(newValue);
            router.push(navigation[Number(newValue)].path);
        }
    };

    // time function

    useEffect(() => {
        setInterval(() => {
            const time = getCurrentTime();
            setCurrentTime(time);
        }, 1000);
    }, []);

    return (
        <>
            <Box
                sx={{
                    mb: 1,
                    top: 0,
                    width: 1,
                    zIndex: 952,
                    // position: 'fixed',
                    position: 'sticky',
                    bgcolor: 'background.default',
                    gridArea: 'header',
                    ...(!isMobile ? headerBorderStyle : {})
                }}
            >
                <Box component="header" sx={{ px: { md: 1, xs: 0 } }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            height: isTableletdown ? STYLE.m_headerHeight : STYLE.headerHeight,
                            paddingRight: { xs: '10px' }
                        }}
                    >
                        <Stack direction="row" alignItems="center" sx={{ flex: 'auto', height: 1 }}>
                            <Logo />
                        </Stack>

                        {/* Sign In & Register Buttons */}
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            {!accessToken ? (
                                <>
                                    <Button
                                        sx={{
                                            py: 0,
                                            px: 0.5,
                                            height: 28,
                                            minWidth: 0,
                                            maxWidth: '112px',
                                            position: 'relative',
                                            textDecoration: 'underline',
                                            '&::after': {
                                                left: '-5px',
                                                content: `''`,
                                                position: 'absolute',
                                                width: '1px',
                                                height: '30px',
                                                bottom: 0,
                                                top: 0,
                                                margin: 'auto',
                                                zIndex: -1,
                                                bgcolor: 'translucent.01'
                                            }
                                        }}
                                        onClick={() => handleOpen('login')}
                                    >
                                        SING IN
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            height: 28,
                                            minWidth: 0,
                                            maxWidth: '112px',
                                            py: 0,
                                            px: 0.5,
                                            '&::after': {
                                                right: '-10px',
                                                content: `''`,
                                                position: 'absolute',
                                                width: '1px',
                                                height: '30px',
                                                bottom: 0,
                                                top: 0,
                                                margin: 'auto',
                                                zIndex: -1,
                                                bgcolor: 'translucent.01'
                                            }
                                        }}
                                        onClick={() => handleOpen('register')}
                                    >
                                        REGISTER
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {!isMobile && (
                                        <Button
                                            variant="contained"
                                            startIcon={<Icon icon="solar:dollar-bold" />}
                                            sx={{
                                                height: 28,
                                                minWidth: 0,
                                                maxWidth: '112px',
                                                py: 0,
                                                bgcolor: '#109121',
                                                color: 'white',
                                                px: 1.5,
                                                '&::after': {
                                                    right: '-10px',
                                                    content: `''`,
                                                    position: 'absolute',
                                                    width: '1px',
                                                    height: '30px',
                                                    bottom: 0,
                                                    top: 0,
                                                    margin: 'auto',
                                                    zIndex: -1,
                                                    bgcolor: 'translucent.02'
                                                }
                                            }}
                                            onClick={() =>
                                                openAccountDialog({
                                                    value: 'personal-detail',
                                                    text: 'Personal Detail'
                                                })
                                            }
                                        >
                                            Deposit
                                        </Button>
                                    )}
                                    <UserBalance user={user} />
                                    <UserInfo />
                                </>
                            )}
                        </Stack>

                        {/* Language Selection Button */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            px={1}
                            sx={{
                                display: { md: 'flex', xs: 'none' },
                                position: 'relative',
                                '&::after': {
                                    right: 0,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Stack
                                aria-describedby="lang-popover"
                                onClick={openLangPopover}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    pl: 1,
                                    minHeight: 36,
                                    cursor: 'pointer'
                                }}
                            >
                                <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                                <Stack
                                    alignItems="center"
                                    sx={{
                                        flexDirection: 'row',
                                        display: isTableletdown ? 'none' : 'flex'
                                    }}
                                >
                                    <Typography sx={{ fontSize: 12, pl: 1 }}>ENG</Typography>
                                    <Icon icon="lsicon:down-filled" style={{ paddingLeft: 1 }} />
                                </Stack>
                            </Stack>
                        </Stack>

                        {/* Current Time Show */}
                        <Box
                            component="span"
                            sx={{
                                display: { md: 'block', xs: 'none' },
                                height: 28,
                                px: 1,
                                ml: 2,
                                fontSize: 11,
                                borderRadius: 1,
                                lineHeight: '28px',
                                position: 'relative',
                                bgcolor: 'translucent.02',
                                '&::after': {
                                    right: -15,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            {currentTime}
                            {/* 05:01:54 */}
                        </Box>

                        {/* More */}
                        <Box
                            sx={{
                                ml: 2,
                                position: 'relative',
                                '&::after': {
                                    left: -10,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.01'
                                }
                            }}
                        >
                            <IconButton
                                aria-describedby="more-popover"
                                onClick={openMorePopover}
                                sx={{
                                    p: 0,
                                    width: 34,
                                    height: 28,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    '&:hover': { bgcolor: 'translucent.02' }
                                }}
                            >
                                <Icon icon="bx:dots-vertical" />
                                <Typography
                                    sx={{
                                        width: 18,
                                        height: 18,
                                        fontSize: 11,
                                        color: 'common.white',
                                        position: 'absolute',
                                        top: -2,
                                        right: -8,
                                        borderRadius: 50,
                                        bgcolor: 'primary.main'
                                    }}
                                >
                                    3
                                </Typography>
                            </IconButton>
                        </Box>
                    </Stack>

                    {!isMobile && (
                        <Stack
                            component="nav"
                            direction="row"
                            sx={{
                                bgcolor: 'translucent.04',
                                borderRadius: 1,
                                height: STYLE.subHeaderHeight,
                                position: 'relative'
                            }}
                        >
                            {isTableletdown
                                ? navigation
                                      .filter((item) => item.isMain === true)
                                      .map((item, rIndex) => (
                                          <HeaderButton key={rIndex} index={rIndex} navData={item} />
                                      ))
                                : navigation.map((item, rIndex) => (
                                      <HeaderButton key={rIndex} index={rIndex} navData={item} />
                                  ))}
                            {isTableletdown && (
                                <HeaderButton sx={{ position: 'absolute', right: 0 }} index={6} navData={MoreNavData} />
                            )}
                        </Stack>
                    )}
                    {isMobile && pathname.indexOf('sports') < 0 && (
                        <Stack
                            direction="row"
                            sx={{
                                width: 1,
                                position: 'sticky',
                                borderColor: 'translucent.01',
                                borderWidth: 1,
                                borderStyle: 'solid none solid none',
                                px: 1,
                                height: '40px',
                                '& .MuiTabs-scroller': {
                                    height: '40px'
                                }
                            }}
                        >
                            <Tabs
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    minHeight: '40px',
                                    position: 'relative',
                                    '& .MuiTabScrollButton-root:first-child': {
                                        position: 'absolute',
                                        zIndex: 0,
                                        height: '96%',
                                        pointerEvents: 'none',
                                        borderRadius: '0',
                                        background: 'linear-gradient(to right,rgba(0, 12, 36,1) 0%,transparent 100%)',
                                        '& svg': {
                                            display: 'none'
                                        }
                                    },
                                    '& .MuiTabScrollButton-root:last-child': {
                                        position: 'absolute',
                                        zIndex: 0,
                                        right: 0,
                                        pointerEvents: 'none',
                                        height: '96%',
                                        background: 'linear-gradient(to left,rgba(0, 12, 36,1) 0%,transparent 100%)',
                                        borderRadius: '0',
                                        '& svg': {
                                            display: 'none'
                                        }
                                    }
                                }}
                                value={selectedRoute}
                                onChange={handleChangeRoute}
                                aria-label="nav tabs"
                                scrollButtons
                                TabScrollButtonProps={{
                                    sx: {
                                        height: '100%'
                                    }
                                }}
                                TabIndicatorProps={{ style: { background: '#a71f67', bottom: '0.5px' } }}
                                role="navigation"
                            >
                                {navigation.map((option, index) => (
                                    <LinkTab key={index} label={option.title} href={option.path} />
                                ))}
                            </Tabs>
                        </Stack>
                    )}
                    {pathname.indexOf('sports') > 0 && <SportHeader />}
                </Box>

                {/* Lanugage Popover */}
                <Popover
                    id="lang-popover"
                    open={Boolean(langAanchor)}
                    anchorEl={langAanchor}
                    onClose={closeLangPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    sx={{
                        '.MuiPopover-paper': {
                            p: 0,
                            bgcolor: 'background.default',
                            boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                        }
                    }}
                >
                    <Stack sx={{ p: 0.5 }} spacing={0.2}>
                        {new Array(2).fill(1).map((_, i) => (
                            <Stack
                                key={i}
                                onClick={() => closeLangPopover()}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    height: 30,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    bgcolor: 'translucent.01',
                                    '&:hover': {
                                        bgcolor: 'translucent.02'
                                    }
                                }}
                            >
                                <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                                <Typography sx={{ ml: 1, fontSize: 12, userSelect: 'none' }}>ENG</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Popover>

                {/* More Popover */}
                <Popover
                    id="more-popover"
                    open={Boolean(moreAanchor)}
                    anchorEl={moreAanchor}
                    onClose={closeMorePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    sx={{
                        '.MuiPopover-paper': {
                            ml: -0.75,
                            mt: 1,
                            bgcolor: 'background.default',
                            boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff33'
                        }
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Stack spacing={0.2}>
                        {new Array(2).fill(1).map((_, i) => (
                            <IconButton key={i}>
                                <Icon icon="zondicons:notification" style={{ width: 18, height: 18 }} />
                            </IconButton>
                        ))}
                    </Stack>
                </Popover>
                {/* Auth Modal */}

                <Dialog
                    open={authModal.status}
                    fullScreen={isMobile}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            borderRadius: '4px',
                            overflowY: 'unset',
                            width: { xs: 1, sm: 400 },
                            background: '#000c24'
                        }
                    }}
                >
                    <Box sx={{ height: 1 }}>{authModal.page === 'login' ? <Login /> : <Register />}</Box>
                </Dialog>
            </Box>

            <AccountSettingDialog />
        </>
    );
};

export default Header;
