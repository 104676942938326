// @mui
import { Box, Button, FormControl, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// components
import { StyledButton, StyledDay } from 'components/DatePicker';
// others
import dayjs from 'dayjs';

const MessageInbox = () => (
    <Box p={1.5}>
        <Box p={1} borderRadius={0.5} bgcolor="translucent.04">
            <Stack direction="row" sx={{ flex: 2 }} gap={0.5}>
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                    }}
                >
                    <DatePicker
                        defaultValue={dayjs()}
                        label="Range"
                        slots={{
                            day: StyledDay,
                            openPickerButton: StyledButton
                        }}
                        slotProps={{
                            openPickerIcon: { fontSize: 'medium' },
                            openPickerButton: { color: 'default' },
                            textField: {
                                variant: 'filled',
                                focused: true,
                                color: 'secondary'
                            }
                        }}
                    />
                </FormControl>
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                    }}
                >
                    <DatePicker
                        defaultValue={dayjs()}
                        slots={{
                            openPickerButton: StyledButton,
                            day: StyledDay
                        }}
                        slotProps={{
                            openPickerIcon: { fontSize: 'medium' },
                            openPickerButton: { color: 'default' },
                            textField: {
                                variant: 'filled',
                                focused: true,
                                color: 'secondary'
                            }
                        }}
                    />
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="success"
                    sx={{ width: { md: 'auto', xs: 1 }, minWidth: 'calc(25% - 5px)' }}
                >
                    SHOW
                </Button>
            </Stack>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" p={5}>
            <Typography fontSize={13} color="textSecondary">
                You do not have any messages
            </Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
                variant="contained"
                color="success"
                sx={{
                    width: { md: 'auto', xs: 1 },
                    textTransform: 'uppercase'
                }}
            >
                new message
            </Button>
        </Stack>
    </Box>
);
export default MessageInbox;
