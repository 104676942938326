import { useEffect, useState } from 'react';
// @iconify
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
import { LoadingScreen } from 'components/loading-screen';
import RenderMarketGroup from 'components/Sports/RenderMarketGroup';
// hooks
import { useBoolean } from 'hooks/use-boolean';
import { useSportContext } from 'hooks/use-sport-context';
import RenderScoreBoard from './Odds/components/RenderScoreBoard';

const EventDetail = ({ isFull }: { isFull: boolean }) => {
    const { event, eventLoading, markets } = useSportContext();
    const [value, setValue] = useState(0);
    const showMarkets = useBoolean(true);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [fullMode, setFullMode] = useState(true);

    const renderMarketName = (
        <Stack
            direction="row"
            sx={{
                position: 'sticky',
                top: 0,
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.default}`,
                background: '#1a253a',
                zIndex: 1,
                borderRadius: 1,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: 1,
                    height: 36,
                    maxWidth: 36,
                    cursor: 'pointer',
                    zIndex: 3,
                    right: 0,
                    left: 0
                }}
            >
                <Box
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 37,
                        height: 36,
                        bgcolor: 'translucent.02',
                        position: 'absolute',
                        top: 0,
                        borderRadius: 0,
                        left: 0
                    }}
                >
                    <Box
                        component="i"
                        className="ss-icon-bc bc-i-search"
                        sx={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: 16,
                            height: 16,
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: 'auto',
                            fontSize: 16,
                            color: '#fffc'
                        }}
                    />
                </Box>
            </Box>

            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    width: 1,
                    ml: 3.7,
                    position: 'relative',
                    willChange: 'scroll-position',
                    height: 36,
                    bgcolor: 'translucent.01',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    scrollbarWidth: 'none'
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: 'primary.main'
                        }
                    }}
                    sx={{
                        // width: 'calc(100% - 37px - 61px)',
                        width: '100%',
                        minHeight: 0
                    }}
                >
                    <Tab
                        label="All"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    {/* {markets.map((market, i) => (
                        <Tab
                            label={market.name}
                            key={`market-${i}`}
                            sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                        />
                    ))} */}
                </Tabs>
            </Stack>

            {isFull && (
                <Stack direction="row" alignItems="center" sx={{ px: 1, bgcolor: 'translucent.01', zIndex: 1 }}>
                    <Box
                        component="i"
                        className="bc-i-one-block "
                        sx={{
                            color: `${!fullMode ? 'translucent.03' : 'common.white'}`,
                            fontSize: 18,
                            cursor: 'pointer',
                            transition: 'color 0.24s'
                        }}
                        onClick={() => setFullMode(true)}
                    />
                    <Box
                        component="i"
                        className="bc-i-two-block active"
                        sx={{
                            ml: 0.5,
                            color: `${fullMode ? 'translucent.03' : 'common.white'}`,
                            fontSize: 18,
                            cursor: 'pointer',
                            transition: 'color 0.24s'
                        }}
                        onClick={() => setFullMode(false)}
                    />
                </Stack>
            )}
        </Stack>
    );

    if (eventLoading) {
        return <LoadingScreen />;
    }

    return (
        <Box
            sx={{
                pr: 0.2,
                pl: isFull ? 0 : 1,
                height: 1,
                flexShrink: 0,
                width: isFull ? 1 : '43%',
                overflowX: 'hidden',
                overflowY: 'scroll',
                willChange: 'scroll-position'
            }}
        >
            <RenderScoreBoard />
            {renderMarketName}

            <Box sx={{ py: 0.1 }}>
                <Stack
                    component="div"
                    direction="row"
                    alignItems="center"
                    sx={{
                        bgcolor: 'translucent.04',
                        pl: 1,
                        pr: 1.5,
                        borderRadius: 1,
                        height: 32,
                        cursor: 'pointer'
                    }}
                    onClick={showMarkets.onToggle}
                >
                    <Typography
                        sx={{
                            flex: 'auto',
                            fontSize: 12,
                            fontWeight: 500,
                            color: 'common.white',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        Markets
                    </Typography>
                    <Box
                        component="i"
                        className={`sgm-m-h-toggle-icon-bc bc-i-small-arrow-${showMarkets.value ? 'up' : 'down'}`}
                        sx={{ fontSize: 13, mb: 0.4, color: '#ffffffb3' }}
                    />
                </Stack>
                {(() => {
                    const marketIds = Object.keys(markets);
                    if (marketIds.length > 0) {
                        const middleIndex = Math.ceil(marketIds.length / 2);

                        return (
                            <Box
                                sx={{
                                    display: isFull ? 'grid' : 'block',
                                    gridTemplateColumns: `${fullMode ? '' : '1fr 1fr'}`,
                                    columnGap: '5px',
                                    rowGap: '2px'
                                }}
                            >
                                {isFull ? (
                                    <>
                                        <Box sx={{ position: 'relative' }}>
                                            {marketIds.slice(0, middleIndex).map((key: string) => (
                                                <RenderMarketGroup
                                                    key={key}
                                                    markets={markets[key]}
                                                    flag={showMarkets.value}
                                                    eventInfo={event.eventInfo}
                                                    fullMode={fullMode}
                                                />
                                            ))}
                                        </Box>
                                        <Box sx={{ position: 'relative' }}>
                                            {marketIds.slice(middleIndex).map((key: string) => (
                                                <RenderMarketGroup
                                                    key={key}
                                                    markets={markets[key]}
                                                    flag={showMarkets.value}
                                                    eventInfo={event.eventInfo}
                                                    fullMode={fullMode}
                                                />
                                            ))}
                                        </Box>
                                    </>
                                ) : (
                                    <Box sx={{ position: 'relative' }}>
                                        {marketIds.map((key: string) => (
                                            <RenderMarketGroup
                                                key={key}
                                                markets={markets[key]}
                                                flag={showMarkets.value}
                                                eventInfo={event.eventInfo}
                                                fullMode={fullMode}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        );
                    }
                    return (
                        <Typography
                            sx={{
                                fontSize: 13,
                                lineHeight: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 1,
                                padding: '5vh 10px',
                                color: 'translucent.06',
                                textAlign: 'center'
                            }}
                        >
                            The time display shown within live betting serves as an indicator. The company takes no
                            responsibility for the correctness and currentness of the displayed information like score
                            or time.
                        </Typography>
                    );
                })()}
            </Box>
        </Box>
    );
};

export default EventDetail;
