export const fNumber = (number: number | string, disableDecimal = false, decimalPlaces = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: disableDecimal ? 0 : decimalPlaces,
        maximumFractionDigits: disableDecimal ? 0 : decimalPlaces
    });
    return formatter.format(+number);
};

export const formatTime = (isoString: string) => {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
};
