import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import FormProvider from 'components/hook-form';
import { StyledRHFTextField } from 'components/hook-form/rhf-text-field';
import { useForm } from 'react-hook-form';
import { IFirstDefaultValue } from 'types/user';
import * as Yup from 'yup';

interface IFirstStepProps {
    defaultValues: IFirstDefaultValue;
    onStep: (step: number) => void;
    setFirst: (data: IFirstDefaultValue) => void;
}

export const FirstStep = ({ defaultValues, onStep, setFirst }: IFirstStepProps) => {
    const RegisterFirstSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        surname: Yup.string().required('This field is required'),
        username: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required').email('Email must be a valid email address')
    });

    const methods = useForm({
        resolver: yupResolver(RegisterFirstSchema),
        defaultValues
    });

    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            setFirst(data);
            onStep(2);
        } catch (error) {
            console.log(error);
        }
    });

    return (
        <FormProvider methods={methods} onSubmit={onSubmit} style={{ height: 'calc(100% - 46px)' }}>
            <Stack height={1}>
                <Stack direction="column" gap={1} p={0.7} sx={{ flex: 'auto', overflowX: 'hidden', overflowY: 'auto' }}>
                    <Typography fontSize={13} mt={0.6} color="#ffffff80" fontWeight={500}>
                        New Memeber?
                    </Typography>

                    <Typography fontSize="17px" color="white" fontWeight={500} textTransform="uppercase">
                        {`Register Now,it's easy!`}
                    </Typography>
                    <Typography fontSize="15px" color="white" fontWeight={500} textTransform="uppercase">
                        Registration step 1
                    </Typography>

                    <StyledRHFTextField variant="filled" name="name" label="Name" />

                    <StyledRHFTextField variant="filled" name="surname" label="Surname" />
                    <StyledRHFTextField variant="filled" name="username" label="Username" />

                    <StyledRHFTextField variant="filled" name="email" label="E-mail" />
                </Stack>
                <Stack alignItems="center" px={1} py={3}>
                    <Button
                        color="inherit"
                        size="large"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                            color: 'white',
                            background: '#109121',
                            maxWidth: '370px',
                            height: '36px',
                            fontSize: '12px',
                            fontWeight: 500,
                            '&:hover': {
                                background: '#15be2b',
                                color: 'white'
                            }
                        }}
                    >
                        NEXT
                    </Button>
                </Stack>
            </Stack>
        </FormProvider>
    );
};
