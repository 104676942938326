// hook
import { useBoolean } from 'hooks/use-boolean';
//  @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
// types
import { IBetItem } from 'types/sports';
import { formatDate } from 'utils/format-number';

const OpenBetOdd = ({ bet }: { bet: IBetItem }) => {
    const isOpened = useBoolean();

    const handleClick = () => {
        isOpened.onToggle();
    };

    return (
        <Stack sx={{ mt: '10px', pt: '10px', borderTop: '1px solid #ffffff1a' }}>
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    borderRadius: 1,
                    bgcolor: 'translucent.04',
                    px: '7px',
                    height: '34px',
                    transition: 'background 0.24s',
                    cursor: 'pointer'
                }}
                onClick={handleClick}
            >
                <Typography sx={{ color: 'translucent.06', textTransform: 'uppercase', fontSize: 12 }}>
                    bet details
                </Typography>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ color: 'translucent.06', fontSize: 13 }}>{bet.items.length}</Typography>
                    <Box
                        component="i"
                        className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 4,
                            lineHeight: 23,
                            color: 'translucent.06',
                            transition: 'color 0.24s, background 0.24s'
                        }}
                    />
                </Stack>
            </Stack>
            <Collapse in={isOpened.value}>
                {isOpened.value &&
                    bet.items.map((item, j) => (
                        <Stack
                            key={j}
                            spacing={0.2}
                            sx={{
                                p: '10px',
                                borderRadius: 1,
                                bgcolor: 'translucent.01',
                                opacity: 1,
                                mb: 1,
                                maxHeight: '14000px',
                                pointerEvents: 'auto',
                                willChange: 'scroll-position',
                                overflowX: 'hidden',
                                overflowY: 'auto'
                            }}
                        >
                            <Stack>
                                <Stack flexDirection="row" justifyContent="space-between">
                                    <Stack flexDirection="row">
                                        <Typography fontSize="14px" sx={{ color: 'translucent.06' }}>
                                            Pick:
                                        </Typography>
                                        <Typography fontSize="14px" sx={{ color: 'translucent.07' }}>
                                            {item.oddName}
                                        </Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: '14px', color: '#ffd700' }}>{item.price}</Typography>
                                </Stack>
                                <Stack flexDirection="row" justifyContent="space-between">
                                    <Typography fontSize="14px" sx={{ color: 'translucent.06' }}>
                                        {item.marketName}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px', color: '#aa7f00' }}>Not Resulted</Typography>
                                </Stack>
                            </Stack>
                            <Stack sx={{ mt: '10px', pt: '10px', borderTop: '1px solid #ffffff1a' }}>
                                <Typography fontSize="14px" sx={{ color: 'translucent.06' }}>
                                    {item.eventName.split(' v ')[0]}
                                </Typography>
                                <Typography fontSize="14px" sx={{ color: 'translucent.07' }}>
                                    {item.eventName.split(' v ')[1]}
                                </Typography>
                                <Stack
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    sx={{ mt: '8px', fontSize: '12px', color: 'translucent.06' }}
                                >
                                    <Stack flexDirection="row" alignItems="center" gap={0.5}>
                                        <Box component="i" className="bet-history-sport-icon bc-i-Soccer" />
                                        <Typography fontSize="12px">{item.competitionName}</Typography>
                                    </Stack>
                                    <Box component="time" sx={{ color: 'translucent.03' }}>
                                        {formatDate(bet.createdAt)}
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
            </Collapse>
        </Stack>
    );
};
export default OpenBetOdd;
