import { useCallback, useEffect, useMemo, useState } from 'react';
// api
import { placeBet } from 'api/sports';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// types
import { ILavelNumber } from 'types/sports';
// components
import MultiOddBox from 'components/Sports/OddBox/MultiOddBox';
import SingleOddBox from 'components/Sports/OddBox/SingleOddBox';
// hooks
import { useBoolean } from 'hooks/use-boolean';
import { useAuthContext } from 'hooks/use-auth-context';
import { useSportContext } from 'hooks/use-sport-context';
// store
import { RootState, useDispatch, useSelector } from 'store/store';
import { openAuthModal } from 'store/slices/auth';
import { setAccetOdd, setBetType } from 'store/slices/sports';
// config
import { ACCEPT_ODD_TYPE } from 'config';

const BetSlip = ({
    betting,
    bettingOn,
    bettingOff
}: {
    betting: boolean;
    bettingOn: VoidFunction;
    bettingOff: VoidFunction;
}) => {
    const dispatch = useDispatch();
    const { betType, acceptOdd } = useSelector((state: RootState) => state.sports);

    const { user } = useAuthContext();
    const { betslip, removeAllSlip, removeBetSlips } = useSportContext();

    const showNotification = useBoolean();
    const [balances, setBalances] = useState<{ [key: string]: number }>({});
    const [notifications, setNotifications] = useState<{ status: string; message: string }[]>([]);
    const [oddAanchor, setOddAanchor] = useState<HTMLElement | null>(null);
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);
    const [defaultBetAmount, setDefaultBetAmount] = useState(0);
    const [multiBetAmount, setMultiBetAmount] = useState(0);
    const [emptyOdd, setEmptyOdd] = useState<boolean>(false);
    const [favoriteAmounts, setFavoriteAmounts] = useState<number[]>([50, 100, 500]);
    const [editState, setEditState] = useState<boolean>(false);

    const { betDisable, multiBetDisable, insufficient } = useMemo(() => {
        if (betting) return { betDisable: true, multiBetDisable: false, insufficient: false };
        if (!user) return { betDisable: true, multiBetDisable: false, insufficient: false };
        if (betslip.length === 0) return { betDisable: true, multiBetDisable: false, insufficient: false };

        if (betType === 'multiple') {
            let mtBetCheck = false;
            let betCheck = false;
            const sameEventIndex = betslip.findIndex((sl) => sl.allowMulti === false);
            if (sameEventIndex !== -1) {
                mtBetCheck = true;
            }
            if (multiBetAmount <= 0 || mtBetCheck) {
                betCheck = true;
            }
            return { betDisable: betCheck, multiBetDisable: mtBetCheck, insufficient: false };
        }
        let totalBetAmount = 0;
        // eslint-disable-next-line
        for (const slip of betslip) {
            totalBetAmount += balances[slip.id] || 0;
        }
        if (betType === 'single') {
            if (totalBetAmount === 0) {
                return { betDisable: true, multiBetDisable: false, insufficient: false };
            }
            if (user.balance < totalBetAmount) {
                return { betDisable: true, multiBetDisable: false, insufficient: true };
            }
        }
        return { betDisable: false, multiBetDisable: false, insufficient: false };
    }, [user, betslip, betType, multiBetAmount, betting, balances]);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const openOddPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOddAanchor(event.currentTarget as HTMLElement);
    };

    const handleAuthModal = (page: string) => {
        dispatch(openAuthModal(page));
    };

    const closeBetTypePopover = (mt: string) => {
        if (mt) {
            dispatch(setBetType(mt));
        }
        setMarketAnchor(null);
    };

    const closeOddPopover = (ot: ILavelNumber | '') => {
        if (ot) {
            dispatch(setAccetOdd(ot));
        }
        setOddAanchor(null);
    };

    const clearAllOdds = () => {
        removeAllSlip();
        setBalances({});
    };

    const onChangeBetAmount = useCallback((value: number) => {
        setMultiBetAmount(value);
    }, []);

    const handleChangeInput = (value: number, position: number) => {
        setFavoriteAmounts((prevAmounts) => {
            const updatedAmounts = [...prevAmounts];
            updatedAmounts[position] = value;
            return updatedAmounts;
        });
    };

    const betSelect = (amount: number) => {
        setDefaultBetAmount(amount);
    };

    const confirmFavoriteAmounts = () => {
        localStorage.setItem('favorite_amount', JSON.stringify(favoriteAmounts));
        setEditState(!editState);
    };

    const closeNotification = () => {
        setTimeout(() => showNotification.onFalse(), 4000);
    };

    const handlePlaceBet = async () => {
        try {
            bettingOn();
            if (betType === 'single') {
                const betItems = betslip.filter((si) => balances[si.id] > 0);
                const rdata = await placeBet({
                    multiBetAmount,
                    items: betItems.map((oddItem) => ({
                        sportId: oddItem.sportId,
                        countryId: oddItem.countryId,
                        competitionId: oddItem.competitionId,
                        eventId: oddItem.eventId,
                        marketId: oddItem.marketId,
                        selectionId: oddItem.oddItem.id,
                        racingSelection: '',
                        price: oddItem.oddItem.price,
                        sportName: oddItem.sportName,
                        countryName: oddItem.countryName,
                        competitionName: oddItem.competitionName,
                        eventName: oddItem.eventName,
                        marketName: oddItem.marketName,
                        oddName: oddItem.oddName,
                        betAmount: balances[oddItem.id]
                    })),
                    priceChange: acceptOdd.value,
                    type: betType
                });
                const allowedIds: string[] = [];
                const disabledIds: string[] = [];
                const failedNotification: { status: string; message: string }[] = [];
                // eslint-disable-next-line
                for (const betItem of rdata) {
                    if (betItem.status) {
                        allowedIds.push(`${betItem.eventId}-${betItem.marketId}-${betItem.selectionId}`);
                    } else {
                        disabledIds.push(`${betItem.eventId}-${betItem.marketId}-${betItem.selectionId}`);
                        failedNotification.push({ status: 'warning', message: betItem.message });
                    }
                }
                if (allowedIds.length) {
                    failedNotification.push({ status: 'success', message: 'Bet success' });
                    removeBetSlips(allowedIds);
                }
                setNotifications(failedNotification);
            } else {
                const multiBet = await placeBet({
                    multiBetAmount,
                    items: betslip.map((oddItem) => ({
                        sportId: oddItem.sportId,
                        countryId: oddItem.countryId,
                        competitionId: oddItem.competitionId,
                        eventId: oddItem.eventId,
                        marketId: oddItem.marketId,
                        selectionId: oddItem.oddItem.id,
                        racingSelection: '',
                        price: oddItem.oddItem.price,
                        sportName: oddItem.sportName,
                        countryName: oddItem.countryName,
                        competitionName: oddItem.competitionName,
                        eventName: oddItem.eventName,
                        marketName: oddItem.marketName,
                        oddName: oddItem.oddName,
                        betAmount: oddItem.betAmount
                    })),
                    priceChange: acceptOdd.value,
                    type: betType
                });
                if (multiBet.status) {
                    removeAllSlip();
                    setBalances({});
                    setNotifications([{ status: 'success', message: 'Bet success' }]);
                } else {
                    setNotifications([{ status: 'warning', message: multiBet.message }]);
                }
            }
            showNotification.onTrue();
        } catch (error: any) {
            showNotification.onTrue();
            setNotifications([{ status: 'warning', message: typeof error === 'string' ? error : error.message }]);
        } finally {
            bettingOff();
            closeNotification();
        }
    };

    useEffect(() => {
        const _favoriteAmounts = localStorage.getItem('favorite_amount');
        if (!_favoriteAmounts) return;
        setFavoriteAmounts(JSON.parse(_favoriteAmounts));
    }, []);

    useEffect(() => {
        if (betslip.length < 2) {
            dispatch(setBetType('single'));
        }
        if (betslip.length < 1) setEmptyOdd(true);
        else setEmptyOdd(false);
    }, [betslip, dispatch]);

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    borderEndEndRadius: 4,
                    borderEndStartRadius: 4,
                    bgcolor: 'translucent.05'
                }}
            >
                <Button
                    size="small"
                    onClick={openOddPopover}
                    sx={{
                        px: 1,
                        height: 36,
                        color: 'translucent.08'
                    }}
                    startIcon={
                        <Box
                            component="i"
                            className="select-icon-bc bc-i-settings"
                            sx={{
                                fontSize: 16,
                                color: 'translucent.08'
                            }}
                        />
                    }
                    endIcon={
                        <Box
                            component="i"
                            className="form-control-icon-bc bc-i-small-arrow-down"
                            sx={{
                                color: 'translucent.08',
                                fontSize: '10px !important'
                            }}
                        />
                    }
                >
                    {acceptOdd.label}
                </Button>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    my: 0.2,
                    gridArea: 'betslip-type',
                    height: 34,
                    pr: 1,
                    py: 1,
                    bgcolor: 'translucent.04',
                    borderRadius: 1
                }}
            >
                {betslip.length < 2 ? (
                    <Button
                        size="small"
                        sx={{
                            textTransform: 'capitalize',
                            bgcolor: 'transparent',
                            py: 0,
                            px: 1,
                            height: 1,
                            borderStartEndRadius: 0,
                            borderEndEndRadius: 0
                        }}
                    >
                        {betType}
                    </Button>
                ) : (
                    <Button
                        size="small"
                        onClick={openMarketPopover}
                        sx={{
                            bgcolor: 'transparent',
                            textTransform: 'capitalize',
                            py: 0,
                            px: 1,
                            height: 1,
                            borderStartEndRadius: 0,
                            borderEndEndRadius: 0
                        }}
                        endIcon={
                            <Box
                                component="i"
                                className="form-control-icon-bc bc-i-small-arrow-down"
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '10px !important',
                                    width: 20,
                                    height: 20,
                                    textAlign: 'center'
                                }}
                            />
                        }
                    >
                        {betType}
                    </Button>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}
                    onClick={clearAllOdds}
                >
                    <Typography
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontSize: 12,
                            color: 'translucent.03'
                        }}
                    >
                        Remove All
                    </Typography>
                </Box>
            </Stack>

            <Box
                sx={{
                    width: 1,
                    height: 1,
                    overflow: 'auto',
                    position: 'relative',
                    gridArea: 'betslip-items'
                }}
            >
                {betType === 'single' ? (
                    <SingleOddBox defaultBetAmount={defaultBetAmount} balances={balances} setBalances={setBalances} />
                ) : (
                    <MultiOddBox
                        defaultBetAmount={defaultBetAmount}
                        multiBetAmount={multiBetAmount}
                        onChangeBetAmount={onChangeBetAmount}
                    />
                )}
            </Box>

            {emptyOdd && (
                <Stack
                    sx={{
                        padding: '5vh 10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '13px',
                        color: 'translucent.06',
                        textAlign: 'center',
                        width: '100%'
                    }}
                >
                    Your betslip is empty
                </Stack>
            )}
            {!user && (
                <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    sx={{ bgcolor: '#aa7f0033', borderRadius: 1, minHeight: 34, p: 0.5 }}
                >
                    <Box component="i" className="bs-notification-icon-bc bc-i-attention" sx={{ color: '#aa7f00' }} />
                    <Typography component="span" sx={{ fontSize: 11 }}>
                        To place your bet, please{' '}
                        <Typography
                            component="span"
                            sx={{ fontSize: 11, textDecoration: 'underline' }}
                            onClick={() => handleAuthModal('login')}
                        >
                            Sign in
                        </Typography>{' '}
                        or{' '}
                        <Typography
                            component="span"
                            sx={{ fontSize: 11, textDecoration: 'underline' }}
                            onClick={() => handleAuthModal('register')}
                        >
                            Register
                        </Typography>
                    </Typography>
                </Stack>
            )}
            {multiBetDisable && (
                <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    sx={{ bgcolor: '#ff344933', borderRadius: 1, minHeight: 34, p: 0.5 }}
                >
                    <Box component="i" className="bs-notification-icon-bc bc-i-attention" sx={{ color: '#ff3449' }} />
                    <Typography component="span" sx={{ fontSize: 11 }}>
                        Highlighted events cannot be combined
                    </Typography>
                </Stack>
            )}
            {insufficient && (
                <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    sx={{ bgcolor: '#aa7f0033', borderRadius: 1, minHeight: 34, p: 0.5 }}
                >
                    <Box component="i" className="bs-notification-icon-bc bc-i-attention" sx={{ color: '#aa7f00' }} />
                    <Typography component="span" sx={{ fontSize: 11 }}>
                        Insufficient balance
                    </Typography>
                </Stack>
            )}
            {showNotification.value &&
                notifications.map((notification, index) => (
                    <Stack
                        key={index}
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        sx={{
                            bgcolor: notification.status === 'warning' ? '#aa7f0033' : '#10912133',
                            borderRadius: 1,
                            minHeight: 34,
                            p: 0.5
                        }}
                    >
                        <Box
                            component="i"
                            className={
                                notification.status === 'warning'
                                    ? 'bs-notification-icon-bc bc-i-attention'
                                    : 'bs-notification-icon-bc bc-i-checked'
                            }
                            sx={{ color: notification.status === 'warning' ? '#aa7f00' : '#109121' }}
                        />
                        <Typography component="span" sx={{ fontSize: 11 }}>
                            {notification.message}
                        </Typography>
                    </Stack>
                ))}

            <Box sx={{ gridArea: 'betslip-footer', mb: 1.6 }}>
                <Box
                    sx={{
                        p: 1,
                        mt: 0.2,
                        width: 1,
                        gridGap: '2px',
                        borderRadius: 1,
                        bgcolor: 'translucent.05',
                        gridTemplateColumns: '1fr'
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr) 36px',
                            gridColumnGap: '2px',
                            gridRowGap: '2px'
                        }}
                    >
                        {editState ? (
                            <>
                                {favoriteAmounts.map((amount, i) => (
                                    <Box
                                        key={`favorite-input-${i}`}
                                        component="input"
                                        value={amount}
                                        type="number"
                                        onChange={(e) => handleChangeInput(Number(e.target.value), i)}
                                        sx={{
                                            minWidth: 0,
                                            bgcolor: 'translucent.01',
                                            border: 'none',
                                            outline: 'none',
                                            padding: '9.5px 16px',
                                            color: 'white',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                            fontSize: '12px',
                                            '&:focus': {
                                                background: 'none!important',
                                                border: '1px solid white'
                                            }
                                        }}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                {favoriteAmounts.map((amount, i) => (
                                    <Button
                                        key={`favorite-${i}`}
                                        variant="contained"
                                        color="primary"
                                        sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                                        onClick={() => betSelect(amount)}
                                    >
                                        {amount}
                                    </Button>
                                ))}
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                            onClick={confirmFavoriteAmounts}
                        >
                            <Box
                                component="i"
                                className={`bs-number-button-icon-bc ${!editState ? 'bc-i-edit' : 'bc-i-checked'}`}
                                sx={{ color: '#fffc', fontSize: 18 }}
                            />
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mt: 0.2,
                        bgcolor: 'translucent.05',
                        borderRadius: 1,
                        p: 1,
                        width: 1,
                        gridGap: '2px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid'
                        }}
                    >
                        <Button
                            disabled={betDisable}
                            variant="contained"
                            color="success"
                            sx={{ minWidth: 0, textTransform: 'uppercase' }}
                            onClick={handlePlaceBet}
                        >
                            bet now
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={() => closeBetTypePopover('')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 87,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {betslip.length < 2 ? (
                        <Stack
                            onClick={() => closeBetTypePopover('single')}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Single</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Stack
                                onClick={() => closeBetTypePopover('single')}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    height: 30,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    bgcolor: 'translucent.01',
                                    '&:hover': {
                                        bgcolor: 'translucent.02'
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Single</Typography>
                            </Stack>
                            <Stack
                                onClick={() => closeBetTypePopover('multiple')}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    height: 30,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    bgcolor: 'translucent.01',
                                    '&:hover': {
                                        bgcolor: 'translucent.02'
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Multiple</Typography>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Popover>
            <Popover
                id="odd-popover"
                open={Boolean(oddAanchor)}
                anchorEl={oddAanchor}
                onClose={() => closeOddPopover('')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 87,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {ACCEPT_ODD_TYPE.map((item, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeOddPopover(item)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{item.label}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};
export default BetSlip;
