// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// routes
import { useRouter } from 'routes/hook';
// hooks
import { useBoolean } from 'hooks/use-boolean';
// type
import { ICompetitionEvent, IMarketType } from 'types/sports';
// utils
import { getOddName } from 'utils/sport';
import { formatTime } from 'utils/format-number';
// components
import OddButton from './OddButton';

export default function Matchs({
    events,
    marketType,
    date
}: {
    events: ICompetitionEvent[];
    date: string;
    marketType: IMarketType;
}) {
    const isOpened = useBoolean(true);
    const router = useRouter();

    const onChangeEventId = (event: ICompetitionEvent) => {
        router.push(
            `/sports/pre-match/event-view/${event.sportId}/${event.locationId}/${event.leagueId}/${event.eventId}`
        );
    };

    const renderEmptyMarket = (
        <>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
        </>
    );

    return (
        <Box>
            <Box sx={{ mb: 0.2, cursor: 'pointer' }} onClick={isOpened.onToggle}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        pl: 1,
                        pr: 0.2,
                        bgcolor: 'translucent.05',
                        borderRadius: 1,
                        height: 36
                    }}
                >
                    <Typography
                        sx={{
                            mr: 0.4,
                            lineHeight: '15px',
                            flex: 'auto',
                            fontSize: 12,
                            color: 'translucent.07',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            letterSpacing: 0.5,
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {date}
                    </Typography>

                    <IconButton>
                        <Box
                            component="i"
                            className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                            sx={{ fontSize: 11 }}
                        />
                    </IconButton>
                </Stack>
            </Box>
            <Collapse in={isOpened.value}>
                <Stack spacing={0.2}>
                    {(Array.isArray(events) ? events : []).map((event, index) => (
                        <Box key={index}>
                            <Box sx={{ borderRadius: 1, overflow: 'hidden' }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        minHeight: 34,
                                        mb: 0.1,
                                        py: 0.5,
                                        px: 1,
                                        bgcolor: 'translucent.04',
                                        transition: 'background 0.24s',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'translucent.09'
                                        }
                                    }}
                                    onClick={() => onChangeEventId(event)}
                                >
                                    {event.eventInfo.competitors.length > 0 ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    maxWidth: '48%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'wrap',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: 12,
                                                    fontWeight: 500
                                                }}
                                            >
                                                {event.eventInfo.competitors[0].name}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                sx={{ color: '#ffffff80', mx: 0.8, fontSize: 11 }}
                                            >
                                                vs
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    maxWidth: '48%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'wrap',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: 12,
                                                    fontWeight: 500
                                                }}
                                            >
                                                {event.eventInfo.competitors[1].name}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography
                                            sx={{
                                                maxWidth: '48%',
                                                overflow: 'hidden',
                                                whiteSpace: 'wrap',
                                                textOverflow: 'ellipsis',
                                                fontSize: 12,
                                                fontWeight: 500
                                            }}
                                        >
                                            {event.eventInfo.name}
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack direction="row" alignItems="center" spacing={0.1}>
                                    {(() => {
                                        const market = event.markets.find((m) => m.id === marketType.id);
                                        if (!market) return renderEmptyMarket;
                                        return (
                                            <>
                                                {market.selections.map((selection, i) => {
                                                    const oddName = getOddName(
                                                        market,
                                                        selection,
                                                        event.eventInfo.competitors
                                                    );
                                                    return (
                                                        <OddButton
                                                            key={i}
                                                            eventInfo={event.eventInfo}
                                                            oddName={oddName}
                                                            marketName={market.name}
                                                            marketId={market.id}
                                                            tradingStatus={market.tradingStatus}
                                                            selection={selection}
                                                        />
                                                    );
                                                })}
                                            </>
                                        );
                                    })()}
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        height: 24,
                                        mt: 0.1,
                                        px: 0.5,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.01'
                                    }}
                                >
                                    <Box
                                        component="i"
                                        className="bc-i-prematch"
                                        sx={{
                                            fontSize: 11,
                                            color: '#fffc'
                                        }}
                                    />
                                    <Typography
                                        className="ellipsis"
                                        sx={{ flex: 'auto', ml: 1, fontSize: 11, color: '#ffffff80' }}
                                    >
                                        {formatTime(event.creationTime)}
                                    </Typography>
                                    <Box
                                        component="i"
                                        className="bc-i-favorite"
                                        sx={{ fontSize: 11, color: '#fffc' }}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml: 0.5
                                        }}
                                    >
                                        <Box
                                            component="i"
                                            className="c-info-icon-bc bc-i-bet-builder"
                                            sx={{ fontSize: 11, color: '#eacb0b' }}
                                        />
                                    </Box>
                                    <Typography className="ellipsis" sx={{ ml: 1, fontSize: 11, color: '#ffffff80' }}>
                                        {event.marketCount > 0 ? `+${event.marketCount}` : 0}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
}
