import axios from 'axios';
// config
import { HOST_API_KEY } from 'config';
import { store } from 'store/store';
import { logoutAction } from 'store/slices/auth';
// import toast from 'react-hot-toast';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.request.use(
    (config: any) => {
        config.baseURL = HOST_API_KEY;
        const state = store.getState() as any;
        const { accessToken } = state.auth;
        if (accessToken) {
            config.headers.authorization = accessToken;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response && response.status === 400) {
            console.error(response.data);
            // toast.error(response.data?.error || response.data);
        } else if (response && response.status === 401) {
            store.dispatch(logoutAction());
        } else if (response && response.status === 402) {
            // toast.error(response.data?.error || response.data);
        } else if (response && response.status === 500) {
            // toast.error(response.data?.error || response.data);
        } else if (response && response.status === 404) {
            // toast.error('API not found');
        } else {
            // toast.error(response?.data || 'API error');
        }
    }
);

export default axiosInstance;

export const ASSETS = (name: string) => (name ? `${HOST_API_KEY}/${name}` : '');

export const endpoints = {
    auth: {
        login: '/api/auth/login',
        register: '/api/auth/register',
        forgotPassword: '/api/auth/forgot_password',
        existResetToken: '/api/auth/exist_reset_token',
        resetPassword: '/api/auth/reset_password',
        logout: '/api/auth/logout'
    },
    user: {
        getUser: '/api/user/forgot_password'
    },
    sport: '/api/sport',
    casino: {
        getCasinos: '/api/casino/get'
    }
};
