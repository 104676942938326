import { createContext } from 'react';
// types
import {
    IBetSlip,
    ICompetitionEvent,
    IFullEvent,
    ILiveCategoryItem,
    ILiveEventItem,
    IMarket,
    IMarketType,
    IPeriodTime,
    IPrematchCateogryItem
} from 'types/sports';

type SportContextValue = {
    event: IFullEvent;
    competitionId: string;
    countryId: string;
    sportId: string;
    eventId: string;
    isLive: boolean;
    liveLoading: boolean;
    eventLoading: boolean;
    premacthLoading: boolean;
    competitionLoading: boolean;
    liveCount: number;
    upcomingCount: number;
    period: IPeriodTime;
    periods: { [key: string]: string };
    marketType: IMarketType[];
    liveCategory: ILiveCategoryItem[];
    liveEvents: { [key: string]: ILiveEventItem };
    prematchCategory: IPrematchCateogryItem[];
    competitionEvents: { [key: string]: ICompetitionEvent[] };
    betslip: IBetSlip[];
    markets: { [key: string]: IMarket[] };
    //
    updatePeriod: (newPeriod: IPeriodTime) => void;
    addBetSlip: (newSlip: IBetSlip) => void;
    removeBetSlip: (id: string) => void;
    removeBetSlips: (ids: string[]) => void;
    removeAllSlip: VoidFunction;
};

const SportContext = createContext<SportContextValue | null>(null);

export { SportContext };
