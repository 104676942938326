import { useContext } from 'react';
//
import { SocketContext } from 'context/socket/socket-context';

// ----------------------------------------------------------------------

export const useSocketContext = () => {
    const context = useContext(SocketContext);

    if (!context) throw new Error('useSocketContext context must be use inside AuthProvider');

    return context;
};
