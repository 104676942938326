import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';
// redux

import AuthProvider from 'context/auth/auth-provider';
import SocketProvider from 'context/socket/socket-provider';
import { AuthConsumer } from 'context/auth/auth-consumer';
import ThemeProvider from 'theme';

import ScrollToTop from 'components/ScrollToTop';

import Router from 'routes/sections';
// mui datepicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { store } from './store/store';

const App = () => (
    <ReduxProvider store={store}>
        <AuthProvider>
            <SocketProvider>
                <HelmetProvider>
                    <ThemeProvider>
                        <AuthConsumer>
                            <BrowserRouter>
                                <ScrollToTop />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Router />
                                </LocalizationProvider>
                            </BrowserRouter>
                        </AuthConsumer>
                    </ThemeProvider>
                </HelmetProvider>
            </SocketProvider>
        </AuthProvider>
    </ReduxProvider>
);

export default App;
