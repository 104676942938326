import { Box, Typography, Stack } from '@mui/material';

import { validatePassword } from 'utils/password-validator';
import { CheckField } from './checkField';

export const PasswordValidator = ({ password }: { password: string }) => {
    const { countPassed, validations } = validatePassword(password);
    return (
        <Box component="div" bgcolor="translucent.04" p={1.6} mt={0.2} color="#ffffffe6">
            <Stack direction="row" gap={0.4} mb={1.6}>
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 1 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 2 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 3 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 4 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 5 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 6 ? '#109121' : '#ffffff70'}`
                    }}
                />
                <Typography
                    sx={{
                        width: '38px',
                        height: '2px',
                        borderRadius: '4px',
                        bgcolor: `${countPassed >= 7 ? '#109121' : '#ffffff70'}`
                    }}
                />
            </Stack>
            <Typography sx={{ mb: '16px', fontWeight: '600', fontSize: '11px' }}>
                Your password must include:
            </Typography>
            <CheckField checked={validations.fieldRequire} text="This field is required." />
            <CheckField checked={validations.oneDigit} text="Password should contain at least one digit." />
            <CheckField checked={validations.space} text="Password should not contain any spaces." />
            <CheckField checked={validations.english} text="Password should contain English characters." />
            <CheckField checked={validations.uplower} text="Password should contain lower and upper characters." />
            <CheckField checked={validations.minimal} text="Password minimal length 6" />
            <CheckField checked={validations.maximal} text="Password maximal length 50" />
        </Box>
    );
};
