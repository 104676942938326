import { useEffect, useState } from 'react';
// mui
import { Box, Stack, Typography } from '@mui/material';
// hooks
import { useSportContext } from 'hooks/use-sport-context';
// utils
import { formatDate } from 'utils/format-number';

const MultiOddBox = ({
    defaultBetAmount,
    multiBetAmount,
    onChangeBetAmount
}: {
    defaultBetAmount: number;
    multiBetAmount: number;
    onChangeBetAmount: (value: number) => void;
}) => {
    const { betslip, removeBetSlip } = useSportContext();
    const [totalOdd, setTotalOdd] = useState(0);

    const handleChangeInput = (value: number) => {
        onChangeBetAmount(value);
    };

    useEffect(() => {
        const count = betslip.reduce(
            (total: number, slipItem) => (total === 0 ? slipItem.oddItem.price : total * slipItem.oddItem.price),
            0
        );
        setTotalOdd(count);
    }, [betslip]);

    useEffect(() => {
        onChangeBetAmount(defaultBetAmount);
    }, [defaultBetAmount, onChangeBetAmount]);

    return (
        <>
            {betslip.map((slipItem, i) => (
                <Box
                    key={i}
                    sx={{
                        mb: 0.2,
                        cursor: 'pointer'
                    }}
                >
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            position: 'relative',
                            zIndex: 5
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'translucent.01',
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ width: 1, px: 0.8, py: 0.6 }}>
                                <Box
                                    sx={{
                                        mr: 0.6,
                                        width: 10,
                                        ml: 'auto',
                                        height: 16,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                    onClick={() => removeBetSlip(slipItem.id)}
                                >
                                    <Box
                                        component="i"
                                        className="bs-bet-i-h-c-icon-bc remove bc-i-close-remove"
                                        sx={{
                                            color: 'translucent.06',
                                            fontSize: 10,
                                            '&:hover': {
                                                color: 'translucent.07'
                                            }
                                        }}
                                    />
                                </Box>
                                <Box sx={{ mt: 0.3 }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ minHeight: 14, lineHeight: '16px' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: '#fffffff2',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 'auto'
                                            }}
                                        >
                                            {slipItem.oddName}
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{
                                                color: '#ffd700',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                position: 'relative',
                                                fontSize: 12,
                                                ml: 1.2
                                            }}
                                        >
                                            {slipItem.oddItem.price}
                                        </Typography>
                                    </Stack>
                                    {/* <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ minHeight: 14, lineHeight: '16px', mt: 0.3 }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: 'translucent.06',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 'auto',
                                                opacity: 0.5
                                            }}
                                        >
                                            {slipItem.marketName}
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{
                                                color: '#ff3449',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                position: 'relative',
                                                fontSize: 12,
                                                ml: 1.2,
                                                textDecoration: 'line-through'
                                            }}
                                        >
                                            1.44
                                        </Typography>
                                    </Stack> */}
                                </Box>
                                <Stack direction="row" alignItems="center" sx={{ minHeight: 14, lineHeight: '16px' }}>
                                    <Stack sx={{ flex: 'auto' }}>
                                        <Typography
                                            sx={{
                                                fontSize: 11,
                                                color: '#ffffffb3'
                                            }}
                                        >
                                            {slipItem.eventName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: '#fff6'
                                            }}
                                        >
                                            {formatDate(slipItem.startTime)}
                                        </Typography>
                                    </Stack>
                                    {!slipItem.allowMulti && (
                                        <Box sx={{ width: 16, height: '100%' }}>
                                            <Box
                                                sx={{
                                                    ml: 'auto',
                                                    mb: 0.6,
                                                    display: 'flex',
                                                    width: 10,
                                                    height: 16,
                                                    position: 'relative',
                                                    lineHeight: 1,
                                                    fontSize: 10,
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Box
                                                    component="i"
                                                    className="bs-bet-i-h-c-icon-bc attention bc-i-attention"
                                                    sx={{
                                                        color: '#ff3449',
                                                        fontSize: 10
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box sx={{ width: 1 }}>
                <Box sx={{ py: 1, px: 0.7 }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 0.6, maxHeight: 150 }}
                    >
                        <Typography sx={{ fontSize: 12, color: '#fffffff2' }}>Odds</Typography>
                        <Typography sx={{ fontSize: 12, color: '#ffd700' }}>{totalOdd.toFixed(3)}</Typography>
                    </Stack>

                    <Stack sx={{ mt: 1 }}>
                        <Box
                            sx={{
                                width: 1,
                                height: 36,
                                cursor: 'pointer',
                                position: 'relative',
                                transition: 'max-width 0.24s',
                                bgcolor: 'translucent.01',
                                borderRadius: 1,
                                border: '1px solid',
                                borderColor: 'translucent.02',
                                px: 1
                            }}
                        >
                            <Box
                                component="input"
                                placeholder="Enter stake"
                                type="number"
                                onChange={(e) => handleChangeInput(Number(e.target.value))}
                                value={multiBetAmount || 0}
                                sx={{
                                    px: 0.8,
                                    width: 1,
                                    height: 1,
                                    fontSize: 14,
                                    outline: 'none',
                                    border: 0,
                                    bgcolor: 'transparent',
                                    color: 'common.white'
                                }}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 0.6, maxHeight: 150 }}
                    >
                        <Typography sx={{ fontSize: 12, color: '#fffffff2' }}>Possible win:</Typography>
                        <Typography sx={{ fontSize: 12, color: '#109121' }}>
                            {((multiBetAmount ?? 0) * totalOdd).toFixed(2)} MAD
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};
export default MultiOddBox;
