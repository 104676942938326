import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
// hook
import { useBoolean } from 'hooks/use-boolean';
// @mui
import { Stack, Divider, Grid2, Button, useTheme, useMediaQuery, InputAdornment } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider from 'components/hook-form';
import { StyledRHFTextField } from 'components/hook-form/rhf-text-field';
import { useSelector } from 'store/store';
// components
import { findLabelByCode } from 'utils/auth';
import SelectModal from 'components/Modal/SelectModal';
import { countries } from 'assets/data/countries';

const PersonalDetailView = () => {
    // const [errorMsg, setErrorMsg] = useState('');

    const { user } = useSelector((state) => state.auth);
    const countryModal = useBoolean();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const UserDataSchema = Yup.object().shape({
        passport: Yup.string().required('This field is required!'),
        country: Yup.object()
            .shape({
                code: Yup.string().required('Country code is required'),
                label: Yup.string().required('Country code is required')
            })
            .required('This field is required'),
        city: Yup.string().required('This field is required!'),
        address: Yup.string().required('This field is required!'),
        password: Yup.string().required('This field is required')
    });

    const defaultValues = {
        passport: '',
        country: {
            code: user?.country || '',
            label: findLabelByCode(user?.countryCode) || ''
        },
        city: '',
        address: '',
        password: ''
    };

    const methods = useForm({
        resolver: yupResolver(UserDataSchema),
        defaultValues
    });

    const {
        watch,
        handleSubmit,
        setValue
        // formState: { isSubmitting },
    } = methods;

    const { country } = watch();

    const onSubmit = handleSubmit(async (data) => {
        // try {
        //   console.log(data);
        //   // const response = await register(data);
        //   // console.log(response, "response")
        //   // if (response?.data) enqueueSnackbar("Registration successful", { variant: 'success' });
        // } catch (error) {
        //   console.error(error);
        //   reset();
        //   setErrorMsg(typeof error === 'string' ? error : error.message);
        // }
    });

    return (
        <Stack
            direction="column"
            gap={0.5}
            sx={{
                py: 2,
                px: 1,
                width: 1,
                borderRadius: 2
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid2 container spacing={0.5}>
                    <Grid2 size={12}>
                        <StyledRHFTextField
                            value={user?.username}
                            variant="filled"
                            name="username"
                            label="Username"
                            disabled
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField
                            value={user?.name}
                            variant="filled"
                            name="firstName"
                            label="First name"
                            disabled
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField
                            value={user?.username}
                            variant="filled"
                            name="lastName"
                            label="Last name"
                            disabled
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <DatePicker
                            name="birthDate"
                            disabled
                            slotProps={{
                                openPickerIcon: { fontSize: 'medium' },
                                openPickerButton: { color: 'default' },
                                textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary'
                                }
                            }}
                            sx={{
                                width: 1,
                                '& .MuiFilledInput-root.Mui-disabled': {
                                    bgcolor: 'translucent.05'
                                }
                            }}
                            value={dayjs(user?.birthDate) || null}
                            label="Birth date"
                        />
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField
                            value={user?.gender}
                            variant="filled"
                            name="gender"
                            label="Gender"
                            disabled
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField value={user?.email} variant="filled" name="email" label="E-mail" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="passport" label="Passport / ID" />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField
                            variant="filled"
                            name="country"
                            value={country.label || ''}
                            label="Country"
                            onClick={() => countryModal.onTrue()}
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                loading="lazy"
                                                width="24"
                                                srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                        </InputAdornment>
                                    )
                                }
                            }}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="city" label="City" />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="address" label="Address" />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField
                            variant="filled"
                            name="phoneNumber"
                            label="Phone number"
                            value={user?.phoneNumber}
                        />
                    </Grid2>
                    <Divider sx={{ width: 1, py: 1, mb: 2 }} />
                    <Grid2 size={12}>
                        <StyledRHFTextField variant="filled" name="password" label="Current password" />
                    </Grid2>
                </Grid2>
                <Grid2 container mt={2} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ minWidth: 'calc(25% - 5px)', width: isMobile ? '100%' : 'auto' }}
                        type="submit"
                    >
                        SAVE CHANGES
                    </Button>
                </Grid2>
            </FormProvider>
            <SelectModal
                flag
                setValue={({ code, label }: { code: string; label: string }) => setValue('country', { code, label })}
                selectModal={countryModal}
                placeFolder=""
                data={countries}
            />
        </Stack>
    );
};

export default PersonalDetailView;
