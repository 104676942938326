import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Skeleton } from '@mui/material';
// components
import AntSwitch from 'components/AntSwitch ';
import MultiColumnEvent from 'components/Sports/EventListComponent/MultiColumnEvent';
import SingleColumnEvent from 'components/Sports/EventListComponent/SingleColumnEvent';
// type
import { ICompetitionEvent } from 'types/sports';
//
import { useRouter } from 'routes/hook';
import { useSportContext } from 'hooks/use-sport-context';

type Props = {
    fullList: boolean;
    multiColumn: boolean;
    changeFullList: () => void;
    changeMultiColumn: () => void;
};

const EventList = ({ fullList, multiColumn, changeFullList, changeMultiColumn }: Props) => {
    const router = useRouter();
    const { competitionEvents, competitionLoading, eventId } = useSportContext();

    const [competition, setCompetition] = useState<ICompetitionEvent | null>(null);

    useEffect(() => {
        if (Object.keys(competitionEvents).length) {
            const eventItem = competitionEvents[Object.keys(competitionEvents)[0]][0];
            setCompetition(eventItem);
            if (!eventId) {
                router.push(
                    `/sports/pre-match/event-view/${eventItem.sportId}/${eventItem.locationId}/${eventItem.leagueId}/${eventItem.eventId}`
                );
            }
        }
    }, [competitionEvents, eventId]);

    return (
        <Box
            sx={{
                width: fullList ? '50%' : '57%',
                flexShrink: 0,
                height: 1,
                pr: 0.2,
                willChange: 'scroll-position',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}
        >
            <Box
                sx={
                    fullList
                        ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: 1,
                              pr: 0.2,
                              height: 1,
                              bgcolor: 'background.default',
                              zIndex: 5,
                              willChange: 'scroll-position',
                              overflowX: 'hidden',
                              overflowY: 'scroll'
                          }
                        : {}
                }
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        width: 1,
                        height: 36,
                        mb: 0.1,
                        position: 'relative',
                        pl: 1,
                        pr: 0.5,
                        bgcolor: 'translucent.01',
                        borderRadius: 1
                    }}
                >
                    <Box
                        component="i"
                        className="comp-fav-icon-bc bc-i-favorite "
                        sx={{
                            fontSize: 17,
                            color: 'translucent.08',
                            cursor: 'pointer'
                        }}
                    />
                    {competition ? (
                        <Typography
                            sx={{
                                ml: 1,
                                flex: 'auto',
                                fontSize: 13,
                                lineHeight: '15px',
                                color: 'translucent.07',
                                userSelect: 'text',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {competition.eventInfo.competition.name}
                        </Typography>
                    ) : (
                        <Skeleton sx={{ width: 0.5, ml: 1, flex: 'auto', height: 1 }} variant="text" />
                    )}

                    <Typography
                        component="span"
                        sx={{
                            mx: 0.5,
                            fontSize: 12,
                            lineHeight: '14px',
                            color: '#fffc'
                        }}
                    >
                        Multi-column
                    </Typography>

                    <AntSwitch checked={multiColumn} onChange={() => changeMultiColumn()} />

                    <IconButton onClick={changeFullList}>
                        <Box
                            component="i"
                            className={
                                fullList
                                    ? 'multi-column-to-icon bc-i-block-to-left'
                                    : 'multi-column-to-icon bc-i-block-to-right'
                            }
                            sx={{
                                fontSize: 16,
                                color: 'translucent.08',
                                cursor: 'pointer'
                            }}
                        />
                    </IconButton>
                </Stack>

                {multiColumn ? (
                    <MultiColumnEvent loading={competitionLoading} eventList={competitionEvents} fullList={fullList} />
                ) : (
                    <SingleColumnEvent loading={competitionLoading} eventList={competitionEvents} />
                )}
            </Box>
        </Box>
    );
};

export default EventList;
