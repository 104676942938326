// @mui
import { Box, Typography } from '@mui/material';
// components
import FilterBetHistory from 'components/Profile/bet-history/Filter';

const OpenBet = () => (
    <Box p={1.5}>
        <Box p={1} borderRadius={0.5} bgcolor="translucent.04">
            <FilterBetHistory />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" p={5}>
            <Typography fontSize={13} color="textSecondary">
                No bets to show
            </Typography>
        </Box>
    </Box>
);
export default OpenBet;
