import React, { useEffect, useState } from 'react';
// mui
import { Box, Button, Dialog, Slide, Stack, Tab, Tabs, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTheme } from '@mui/material/styles';
// store
import { useDispatch, useSelector } from 'store/store';
import { setOdds } from 'store/slices/sports';
// components
import SingleOddBox from 'components/Sports/OddBox/SingleOddBox';
import MultiOddBox from 'components/Sports/OddBox/MultiOddBox';
import OpenBets from 'components/Sports/SlipBoardComponent/OpenBets';
import BetSlip from 'components/Sports/SlipBoardComponent/BetSlip';
// hooks
import { useBoolean } from 'hooks/use-boolean';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BetSlipDialog = ({ flag, setFlag }: { flag: boolean; setFlag: (value: boolean) => void }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    // const { odds } = useSelector((state) => state.sports);
    // const [oddList, setOddList] = React.useState<IOdd[]>([]);
    const betting = useBoolean();

    const [tabActive, setTabActive] = React.useState('betslip');
    const [marketAanchor, setMarketAnchor] = React.useState<HTMLElement | null>(null);
    const [defaultBetAmount, setDefaultBetAmount] = React.useState(0);
    const [multiBetAmount, setMultiBetAmount] = React.useState(0);
    const [emptyOdd, setEmptyOdd] = React.useState<boolean>(false);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const onChangeBetAmount = React.useCallback((value: number) => {
        setMultiBetAmount(value);
    }, []);

    const clearAllOdds = () => {
        dispatch(setOdds([]));
    };

    // useEffect(() => {
    //     setOddList([...odds]);
    // }, [odds]);

    const handlePlaceBet = async () => {
        // const response = await placeBet({
        //     amount:22
        // });
    };

    const handleClose = () => {
        setFlag(false);
    };

    return (
        <Dialog fullScreen open={flag} onClose={handleClose} TransitionComponent={Transition}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '2px',
                    px: 0.5,
                    pt: 0.5
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        width: 'calc(100% - 14px)',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        background: '#000c24',
                        opacity: 1,
                        pt: '10px',
                        zIndex: 9,
                        top: '-1px'
                    }}
                >
                    <Stack flexDirection="row" justifyContent="space-between" sx={{ py: 1, px: 0.2 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>220 MAD</Typography>
                        <Box
                            component="i"
                            className="bs-f-h-t-close-icon-bc bc-i-close-remove"
                            sx={{
                                color: 'translucent.06',
                                fontSize: '14px'
                            }}
                            onClick={handleClose}
                        ></Box>
                    </Stack>
                    <Box
                        sx={{
                            width: 1,
                            gridArea: 'betslip-header'
                        }}
                    >
                        <Stack direction="row" sx={{ mt: 0.2, height: 34, borderBottom: '2px solid #263045', mb: 0.2 }}>
                            <Button
                                onClick={() => setTabActive('betslip')}
                                variant="contained"
                                color={tabActive === 'betslip' ? 'primary' : 'inherit'}
                                sx={{
                                    width: 1,
                                    borderRadius: 0,
                                    borderEndStartRadius: 4,
                                    borderStartStartRadius: 4,
                                    transition: 'all 0.24s',
                                    color: tabActive === 'betslip' ? 'common.white' : 'translucent.03',
                                    bgcolor: tabActive === 'betslip' ? 'primary.main' : 'translucent.05',
                                    '&:hover': {
                                        color: 'common.white',
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                Betslip
                            </Button>
                            <Button
                                onClick={() => setTabActive('openBets')}
                                variant="contained"
                                color={tabActive !== 'betslip' ? 'primary' : 'inherit'}
                                sx={{
                                    ml: 0.1,
                                    width: 1,
                                    borderRadius: 0,
                                    borderEndEndRadius: 4,
                                    borderStartEndRadius: 4,
                                    bgcolor: tabActive !== 'betslip' ? 'primary.main' : 'translucent.05',
                                    transition: 'all 0.24s',
                                    color: tabActive !== 'betslip' ? 'common.white' : 'translucent.03',
                                    '&:hover': {
                                        color: 'common.white',
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                Open Bets
                            </Button>
                        </Stack>
                    </Box>
                    {tabActive === 'betslip' ? (
                        <BetSlip betting={betting.value} bettingOn={betting.onTrue} bettingOff={betting.onFalse} />
                    ) : (
                        <OpenBets />
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};

export default BetSlipDialog;
