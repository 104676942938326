// mui
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
//  types
import { ICompetitionEvent } from 'types/sports';
import MultiMatchs from '../Multi-Matchs';

export default function MultiColumnEvent({
    loading,
    eventList,
    fullList
}: {
    loading: boolean;
    eventList: { [key: string]: ICompetitionEvent[] };
    fullList: boolean;
}) {
    return (
        <>
            {!loading ? (
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            mt: 0.1,
                            py: 0.8,
                            pl: 1,
                            pr: 0.5,
                            height: 52,
                            bgcolor: 'translucent.04',
                            borderRadius: 1
                        }}
                    >
                        <Box sx={{ display: 'flex', flex: 'auto' }} />
                        <Stack justifyContent="space-between" sx={{ height: 1, width: 152, textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                WINNER
                            </Typography>
                            <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    W1
                                </Typography>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    X
                                </Typography>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    W2
                                </Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{ display: 'flex', width: 49, ml: 0.8, mr: 0.3 }} />

                        <Stack justifyContent="space-between" sx={{ height: 1, width: 102, textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                HANDICAP
                            </Typography>
                            <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    HOME
                                </Typography>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    AWAY
                                </Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{ display: 'flex', width: 49, ml: 0.8, mr: 0.3 }} />

                        <Stack justifyContent="space-between" sx={{ height: 1, width: 102, textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                TOTALS
                            </Typography>
                            <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    OVER
                                </Typography>
                                <Typography
                                    component="b"
                                    sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                >
                                    UNDER
                                </Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{ display: 'flex', width: 50, ml: 0.5, height: 1 }} />
                    </Stack>
                    <MultiMatchs fullList={fullList} />
                </>
            ) : (
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            mt: 0.1,
                            height: 52,
                            bgcolor: 'translucent.04',
                            borderRadius: 1
                        }}
                    >
                        <Skeleton sx={{ width: '100%', height: '100%', borderRadius: 0 }} />
                    </Stack>
                    {new Array(4).fill(1).map((_, i) => (
                        <Box sx={{ mb: 0.2, cursor: 'pointer' }} key={i}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    mt: 0.4,
                                    pl: 1,
                                    pr: 0.2,
                                    bgcolor: 'translucent.05',
                                    borderRadius: 1,
                                    height: 36
                                }}
                            >
                                <IconButton>
                                    <Box
                                        component="i"
                                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-up"
                                        sx={{ fontSize: 11 }}
                                    />
                                </IconButton>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    height: 54,
                                    mt: 0.1,
                                    width: 1,
                                    py: 1,
                                    pl: 1,
                                    pr: 0.5,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    transition: 'background 0.24s',
                                    '&:hover': {
                                        bgcolor: 'translucent.04'
                                    }
                                }}
                            >
                                <Skeleton sx={{ width: '100%', height: '100%', borderRadius: '4px' }} />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    height: 54,
                                    mt: 0.1,
                                    width: 1,
                                    py: 1,
                                    pl: 1,
                                    pr: 0.5,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    transition: 'background 0.24s',
                                    '&:hover': {
                                        bgcolor: 'translucent.04'
                                    }
                                }}
                            >
                                <Skeleton sx={{ width: '100%', height: '100%', borderRadius: '4px' }} />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    height: 54,
                                    mt: 0.1,
                                    width: 1,
                                    py: 1,
                                    pl: 1,
                                    pr: 0.5,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    transition: 'background 0.24s',
                                    '&:hover': {
                                        bgcolor: 'translucent.04'
                                    }
                                }}
                            >
                                <Skeleton sx={{ width: '100%', height: '100%', borderRadius: '4px' }} />
                            </Stack>
                        </Box>
                    ))}
                </>
            )}
        </>
    );
}
