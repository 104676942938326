import { useState } from 'react';
// mui
import { Box, Dialog, IconButton, InputAdornment, OutlinedInput, Stack, styled } from '@mui/material';
// icon
import { Icon } from '@iconify/react';

const StyledOutlinedInput = styled(OutlinedInput)(() => ({
    border: 'none',
    outline: 'none',
    '& hover': {
        border: 'none'
    },
    '& focus-visible': {
        outline: 'none',
        border: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        outline: 'none',
        border: 'none'
    }
}));

const StyledStack = styled(Stack)(() => ({
    maxHeight: '63vh',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 4px 4px #0000004d',
    overscrollBehaviorY: 'contain',
    overflow: 'auto'
}));

interface DataProps {
    code: string;
    label: string;
    phone: string;
}

interface ISelectModal {
    placeFolder: string;
    flag?: boolean;
    phone?: boolean;
    setValue: ({ code, label }: { code: string; label: string }) => void;
    data: DataProps[];
    selectModal: any;
}

const SelectModal = ({ placeFolder, data, selectModal, setValue, flag, phone }: ISelectModal) => {
    const [filteredData, setFilteredData] = useState(data);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;

        // Filter countries based on input value
        const filtered = data.filter((item) =>
            phone
                ? item.phone.toLowerCase().includes(value.toLowerCase())
                : item.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    return (
        <Dialog
            open={selectModal.value}
            onClose={selectModal.onFalse}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    borderRadius: '4px',
                    overflowY: 'unset',
                    width: 1,
                    maxWidth: '400px',
                    background: '#000c24',
                    position: 'absolute',
                    top: '10%'
                }
            }}
        >
            <Box sx={{ bgcolor: 'translucent.00', p: '44px 15px 15px', maxWidth: '400px' }}>
                <Stack
                    sx={{
                        right: 1,
                        top: 1,
                        position: 'absolute'
                    }}
                >
                    <IconButton onClick={selectModal.onFalse}>
                        <Icon icon="ic:sharp-close" width="24" height="24" />
                    </IconButton>
                </Stack>
                <Stack>
                    <StyledOutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={
                            <InputAdornment position="end">
                                <Icon icon="ic:baseline-search" />
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        placeholder={placeFolder}
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                        sx={{ bgcolor: 'translucent.04' }}
                        onChange={handleInputChange}
                    />
                    <StyledStack bgcolor="translucent.00">
                        {filteredData.length > 0 ? (
                            filteredData.map((item, index: number) => (
                                <Stack
                                    onClick={() => {
                                        setValue({
                                            code: item.code,
                                            label: phone ? item.phone : item.label
                                        });
                                        selectModal.onFalse();
                                    }}
                                    alignItems="center"
                                    key={index}
                                    direction="row"
                                    sx={{
                                        cursor: 'pointer',
                                        mt: '1px',
                                        height: '44px',
                                        minHeight: '44px',
                                        paddingInlineStart: '15px',
                                        paddingInlineEnd: '20px'
                                    }}
                                    bgcolor="translucent.04"
                                >
                                    {flag && (
                                        <Icon
                                            key={item.label}
                                            icon={`circle-flags:${item.code.toLowerCase()}`}
                                            width={28}
                                            style={{ marginRight: '10px' }}
                                        />
                                    )}
                                    {phone ? item.phone : item.label}
                                </Stack>
                            ))
                        ) : (
                            <Stack
                                alignItems="center"
                                direction="row"
                                sx={{
                                    cursor: 'pointer',
                                    mt: '1px',
                                    height: '44px',
                                    minHeight: '44px',
                                    paddingInlineStart: '15px',
                                    paddingInlineEnd: '20px'
                                }}
                                bgcolor="translucent.04"
                            >
                                Not Found
                            </Stack>
                        )}
                    </StyledStack>
                </Stack>
            </Box>
        </Dialog>
    );
};
export default SelectModal;
