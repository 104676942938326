import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
import BetSlip from 'components/Sports/SlipBoardComponent/BetSlip';
import OpenBets from 'components/Sports/SlipBoardComponent/OpenBets';
import { LoadingScreen } from 'components/loading-screen';
// hooks
import { useBoolean } from 'hooks/use-boolean';
// api
import { getOpenBetCount } from 'api/sports';
import { useAuthContext } from 'hooks/use-auth-context';

const SlipBoard = () => {
    const { user, isLogined } = useAuthContext();
    const betting = useBoolean();
    const [tabActive, setTabActive] = useState('betslip');
    const [betCount, setBetCount] = useState(0);

    const loadOpenBetCount = async () => {
        try {
            const resdata = await getOpenBetCount();
            setBetCount(resdata.count);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user) {
            loadOpenBetCount();
        }
    }, [user]);

    return (
        <Box
            sx={{
                pr: 1,
                gridArea: 'betslip'
            }}
        >
            <Box
                sx={{
                    pb: 1,
                    height: 1,
                    opacity: 1,
                    position: 'relative',
                    transform: 'translateY(0px)'
                }}
            >
                <Stack
                    sx={{
                        top: 0,
                        pr: 0.2,
                        left: 0,
                        width: 1,
                        maxHeight: 1,
                        minHeight: 1,
                        display: 'flex',
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        position: 'absolute',
                        flexDirection: 'column',
                        willChange: 'scroll-position'
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                mb: 0.2,
                                minHeight: 34,
                                overflow: 'hidden',
                                transition: 'min-height 0.24s'
                            }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    mb: 0.2,
                                    height: 34,
                                    bgcolor: 'translucent.05',
                                    borderRadius: 1
                                }}
                            >
                                <Box
                                    component="i"
                                    className="game-details-c-live-i-item-bc bc-i-field-soccer active"
                                    sx={{
                                        color: 'common.white',
                                        width: 'auto',
                                        height: 20,
                                        fontSize: 14,
                                        px: 0.4,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: '#0006',
                                        cursor: 'pointer',
                                        borderRadius: 1
                                    }}
                                />
                            </Stack>
                            <Box>
                                <Box
                                    sx={{
                                        width: 332,
                                        height: 198,
                                        mb: '-10px',
                                        transform: 'scale(.946)',
                                        transformOrigin: '0 0'
                                    }}
                                >
                                    <iframe
                                        title="event"
                                        src="https://widget-iframe.wadua.io"
                                        style={{ width: '100%', height: '100%', border: 0 }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '2px'
                        }}
                    >
                        <Box
                            sx={{
                                width: 1,
                                gridArea: 'betslip-header'
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{
                                    mt: 0.2,
                                    height: 34,
                                    borderBottom: '2px solid #263045',
                                    mb: 0.2,
                                    borderStartStartRadius: 4,
                                    borderStartEndRadius: 4,
                                    overflow: 'hidden'
                                }}
                            >
                                <Button
                                    onClick={() => setTabActive('betslip')}
                                    variant="contained"
                                    color={tabActive === 'betslip' ? 'primary' : 'inherit'}
                                    sx={{
                                        width: 1,
                                        height: 34,
                                        borderRadius: 0,
                                        transition: 'all 0.24s',
                                        color: tabActive === 'betslip' ? 'common.white' : 'translucent.03',
                                        bgcolor: tabActive === 'betslip' ? 'primary.main' : 'translucent.05',
                                        '&:hover': {
                                            color: 'common.white',
                                            bgcolor: 'primary.main'
                                        }
                                    }}
                                >
                                    Betslip
                                </Button>
                                {isLogined && (
                                    <Button
                                        onClick={() => setTabActive('openBets')}
                                        variant="contained"
                                        color={tabActive !== 'betslip' ? 'primary' : 'inherit'}
                                        sx={{
                                            ml: 0.1,
                                            width: 1,
                                            height: 34,
                                            borderRadius: 0,
                                            position: 'relative',
                                            bgcolor: tabActive !== 'betslip' ? 'primary.main' : 'translucent.05',
                                            transition: 'all 0.24s',
                                            color: tabActive !== 'betslip' ? 'common.white' : 'translucent.03',
                                            '&:hover': {
                                                color: 'common.white',
                                                bgcolor: 'primary.main'
                                            }
                                        }}
                                    >
                                        Open Bets
                                        {betCount > 0 && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 10,
                                                    position: 'absolute',
                                                    color: tabActive === 'betslip' ? 'white' : 'primary.main',
                                                    bgcolor: tabActive === 'betslip' ? 'primary.main' : 'white',
                                                    height: 15,
                                                    minWidth: 15,
                                                    fontSize: 8,
                                                    top: 2,
                                                    right: 32,
                                                    lineHeight: 1
                                                }}
                                            >
                                                {betCount}
                                            </Typography>
                                        )}
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                        {tabActive === 'betslip' ? (
                            <BetSlip betting={betting.value} bettingOn={betting.onTrue} bettingOff={betting.onFalse} />
                        ) : (
                            <OpenBets />
                        )}

                        {betting.value && (
                            <Stack
                                sx={{
                                    height: 1,
                                    width: 1,
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    zIndex: 5,
                                    bgcolor: '#000c2469'
                                }}
                            >
                                <LoadingScreen />
                            </Stack>
                        )}
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default SlipBoard;
