import SportProvider from 'context/sport/sport-provider';
// layouts
import SportLayout from 'layouts/SportLayout';
// ----------------------------------------------------------------------
// OVERVIEW
import EventView from 'pages/sports/Event';
import LiveCalendar from 'pages/sports/LiveCalendar';
import Result from 'pages/sports/Result';
// ----------------------------------------------------------------------

export const desktopSportsRoutes = [
    {
        path: '/',
        element: (
            <SportProvider>
                <SportLayout />
            </SportProvider>
        ),
        children: [
            {
                path: 'sports/live',
                children: [
                    {
                        path: 'event-view',
                        element: <EventView isLive />
                    },
                    {
                        path: 'event-view/:sportId',
                        element: <EventView isLive />
                    },
                    {
                        path: 'event-view/:sportId/:countryId',
                        element: <EventView isLive />
                    },
                    {
                        path: 'event-view/:sportId/:countryId/:competitionId',
                        element: <EventView isLive />
                    },
                    {
                        path: 'event-view/:sportId/:countryId/:competitionId',
                        element: <EventView isLive />
                    },
                    {
                        path: 'event-view/:sportId/:countryId/:competitionId/:eventId',
                        element: <EventView isLive />
                    },
                    {
                        path: 'live-calendar',
                        element: <LiveCalendar />
                    },
                    {
                        path: 'results',
                        element: <Result />
                    }
                ]
            },
            {
                path: '/sports/pre-match',
                children: [
                    {
                        path: 'event-view',
                        element: <EventView isLive={false} />
                    },
                    {
                        path: 'event-view/:sportId',
                        element: <EventView isLive={false} />
                    },
                    {
                        path: 'event-view/:sportId/:countryId',
                        element: <EventView isLive={false} />
                    },
                    {
                        path: 'event-view/:sportId/:countryId/:competitionId',
                        element: <EventView isLive={false} />
                    },
                    {
                        path: 'event-view/:sportId/:countryId/:competitionId/:eventId',
                        element: <EventView isLive={false} />
                    },
                    {
                        path: 'live-calendar',
                        element: <LiveCalendar />
                    },
                    {
                        path: 'results',
                        element: <Result />
                    }
                ]
            }
        ]
    }
];
