export const currencies = [
    { code: 'SYP', label: 'SYP', phone: 'SYP' },
    { code: 'IQD', label: 'IQD', phone: 'IQD' },
    { code: 'LBP', label: 'LBP', phone: 'LBP' },
    { code: 'SEK', label: 'SEK', phone: 'SEK' },
    { code: 'NSP', label: 'NSP', phone: 'NSP' },
    { code: 'TND', label: 'TND', phone: 'TND' },
    { code: 'TRY', label: 'TRY', phone: 'TRY' },
    { code: 'USD', label: 'USD', phone: 'USD' },
    { code: 'MAD', label: 'MAD', phone: 'MAD' }
];
