import { Box, Stack, Typography } from '@mui/material';
import OddButton from 'components/Sports/OddButton';
import { useBoolean } from 'hooks/use-boolean';
import { useEffect } from 'react';
import { IEventInfo, IMarket } from 'types/sports';
import { getMarketName, getOddName } from 'utils/sport';

const RenderMarketGroup = ({
    flag,
    markets,
    eventInfo,
    fullMode
}: {
    markets: IMarket[];
    flag: boolean;
    eventInfo: IEventInfo;
    fullMode: boolean;
}) => {
    const isOpened = useBoolean(true);
    const marketName = getMarketName(markets[0], eventInfo.competitors);

    useEffect(() => {
        isOpened.setValue(flag);
        // eslint-disable-next-line
    }, [flag]);

    return (
        <Box>
            <Box sx={{ borderRadius: 1, position: 'relative' }} onClick={isOpened.onToggle}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        borderTopWidth: 2,
                        px: 1,
                        borderStartStartRadius: 4,
                        borderStartEndRadius: 4,
                        py: 0.5,
                        bgcolor: 'translucent.05',
                        backgroundClip: 'padding-box',
                        cursor: 'pointer',
                        borderTop: '3px solid transparent'
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <Box
                            component="i"
                            className="sgm-market-g-h-fav-icon-bc bc-i-favorite "
                            sx={{ fontSize: 14, color: 'translucent.08' }}
                        />
                        <Typography sx={{ ml: 1, fontSize: 12, color: 'translucent.08' }}>{marketName}</Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                        <Box
                            component="i"
                            className="sgm-market-g-h-link-icon-bc bc-i-link"
                            sx={{
                                fontSize: 14,
                                lineHeight: '14px',
                                color: 'translucent.08',
                                '&::after': {
                                    content: `'1'`,
                                    ml: 0.4
                                }
                            }}
                        />
                        <Box
                            component="i"
                            className="sgm-market-g-h-cashed-out-icon-bc bc-i-cashed-out"
                            sx={{
                                ml: 0.7,
                                pl: 0.8,
                                position: 'relative',
                                display: 'flex',
                                flexShrink: 0,
                                fontSize: 14,
                                lineHeight: 1,
                                color: '#109121',
                                '&::after': {
                                    left: 0,
                                    content: `""`,
                                    position: 'absolute',
                                    top: 0,
                                    width: '1px',
                                    height: 14,
                                    bgcolor: 'background.default'
                                }
                            }}
                        />
                        <Box
                            component="i"
                            className={`sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                            sx={{
                                ml: 0.7,
                                pl: 0.8,
                                position: 'relative',
                                display: 'flex',
                                flexShrink: 0,
                                fontSize: 11,
                                lineHeight: '14px',
                                color: 'translucent.08',
                                '&::after': {
                                    left: 0,
                                    content: `""`,
                                    position: 'absolute',
                                    top: 0,
                                    width: '1px',
                                    height: 14,
                                    bgcolor: 'background.default'
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>

            {(() => {
                if (!isOpened.value) return null;
                const oddRow: any = [];
                for (let i = 0; i < markets.length; i += 1) {
                    const oddList: any = [];
                    markets[i].selections.forEach((selection, j) => {
                        const oddName = getOddName(markets[i], selection, eventInfo.competitors);
                        oddList.push(
                            <OddButton
                                key={`${i}-${j}`}
                                eventInfo={eventInfo}
                                oddName={oddName}
                                marketName={getMarketName(markets[i], eventInfo.competitors)}
                                marketId={markets[i].id}
                                tradingStatus={markets[i].tradingStatus}
                                selection={selection}
                            />
                        );
                    });
                    oddRow.push(
                        <Stack
                            direction="row"
                            spacing={0.1}
                            sx={
                                markets[i].selections.length > 3
                                    ? {
                                          display: 'grid',
                                          gridTemplateColumns: 'repeat(auto-fit,minmax(49%,1fr))'
                                      }
                                    : { display: 'flex', flex: 1 }
                            }
                        >
                            {oddList}
                        </Stack>
                    );
                }
                return <Stack spacing={0.1}>{oddRow}</Stack>;
            })()}
        </Box>
    );
};

export default RenderMarketGroup;
