import { useEffect } from 'react';
// @mui
import { Box, Button, Stack, Typography } from '@mui/material';
// utils
import { formatDate } from 'utils/format-number';
// hooks
import { useSportContext } from 'hooks/use-sport-context';
import { useAuthContext } from 'hooks/use-auth-context';

const SingleOddBox = ({
    defaultBetAmount,
    balances,
    setBalances
}: {
    defaultBetAmount: number;
    balances: { [key: string]: number };
    setBalances: React.Dispatch<
        React.SetStateAction<{
            [key: string]: number;
        }>
    >;
}) => {
    const { balance } = useAuthContext();
    const { betslip, removeBetSlip } = useSportContext();

    const onChangeBetAmount = (id: string, value: number) => {
        setBalances((pre) => ({ ...pre, [id]: value }));
    };

    const handleMaxAmount = (id: string) => {
        setBalances((pre) => ({ ...pre, [id]: balance }));
    };

    useEffect(() => {
        const obj: { [key: string]: number } = {};
        for (const slip of betslip) {
            obj[slip.id] = defaultBetAmount;
        }
        setBalances(obj);
    }, [defaultBetAmount]);

    return (
        <>
            {betslip.map((slipItem, i) => (
                <Box
                    key={i}
                    sx={{
                        mb: 0.2,
                        cursor: 'pointer'
                    }}
                >
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            position: 'relative',
                            zIndex: 5
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'translucent.01',
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ width: 1, px: 0.8, py: 0.6 }}>
                                <Box
                                    sx={{
                                        mr: 0.6,
                                        width: 10,
                                        ml: 'auto',
                                        height: 16,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                    onClick={() => removeBetSlip(slipItem.id)}
                                >
                                    <Box
                                        component="i"
                                        className="bs-bet-i-h-c-icon-bc remove bc-i-close-remove"
                                        sx={{
                                            color: 'translucent.06',
                                            fontSize: 10,
                                            '&:hover': {
                                                color: 'translucent.07'
                                            }
                                        }}
                                    />
                                </Box>
                                <Box sx={{ mt: 0.3 }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ minHeight: 14, lineHeight: '16px' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: '#fffffff2',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 'auto'
                                            }}
                                        >
                                            {slipItem.oddName}
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{
                                                color: '#ffd700',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                position: 'relative',
                                                fontSize: 12,
                                                ml: 1.2
                                            }}
                                        >
                                            {slipItem.oddItem.price}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ minHeight: 14, lineHeight: '16px', mt: 0.3 }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: 'translucent.06',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 'auto',
                                                opacity: 0.5
                                            }}
                                        >
                                            {slipItem.marketName}
                                        </Typography>
                                        {/* <Typography
                                            component="span"
                                            sx={{
                                                color: '#ff3449',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                position: 'relative',
                                                fontSize: 12,
                                                ml: 1.2,
                                                textDecoration: 'line-through'
                                            }}
                                        >
                                            1.44
                                        </Typography> */}
                                    </Stack>
                                </Box>
                                <Stack direction="row" alignItems="center" sx={{ minHeight: 14, lineHeight: '16px' }}>
                                    <Stack sx={{ flex: 'auto' }}>
                                        <Typography
                                            sx={{
                                                fontSize: 11,
                                                color: '#ffffffb3'
                                            }}
                                        >
                                            {slipItem.eventName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: '#fff6'
                                            }}
                                        >
                                            {formatDate(slipItem.startTime)}
                                        </Typography>
                                    </Stack>
                                    {/* <Box sx={{ width: 16, height: '100%' }}>
                                        <Box
                                            sx={{
                                                ml: 'auto',
                                                mb: 0.6,
                                                display: 'flex',
                                                width: 10,
                                                height: 16,
                                                position: 'relative',
                                                lineHeight: 1,
                                                fontSize: 10,
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box
                                                component="i"
                                                className="bs-bet-i-h-c-icon-bc attention bc-i-attention"
                                                sx={{
                                                    color: '#ff3449',
                                                    fontSize: 10
                                                }}
                                            />
                                        </Box>
                                    </Box> */}
                                </Stack>
                                <Box sx={{ py: 1 }}>
                                    <Stack sx={{ mt: 1 }}>
                                        <Box
                                            sx={{
                                                width: 1,
                                                height: 36,
                                                cursor: 'pointer',
                                                position: 'relative',
                                                transition: 'max-width 0.24s',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box
                                                component="input"
                                                placeholder="Enter stake"
                                                onChange={(e) => onChangeBetAmount(slipItem.id, Number(e.target.value))}
                                                value={balances[slipItem.id] || 0}
                                                type="number"
                                                sx={{
                                                    px: 0.8,
                                                    bgcolor: 'translucent.01',
                                                    borderRadius: 1,
                                                    border: '1px solid',
                                                    borderColor: 'translucent.02',
                                                    width: 1,
                                                    height: 1,
                                                    fontSize: 14,
                                                    outline: 'none',
                                                    color: 'common.white'
                                                }}
                                            />
                                            <Button
                                                onClick={() => handleMaxAmount(slipItem.id)}
                                                sx={{
                                                    bgcolor: 'translucent.02',
                                                    borderRadius: '4px',
                                                    padding: '0 10px',
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    height: '100%',
                                                    marginInlineStart: '5px'
                                                }}
                                            >
                                                MAX
                                            </Button>
                                        </Box>
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ mt: 0.6, maxHeight: 150 }}
                                    >
                                        <Typography sx={{ fontSize: 12, color: '#fffffff2' }}>Possible win:</Typography>
                                        <Typography sx={{ fontSize: 12, color: '#109121' }}>
                                            {((balances[slipItem.id] || 0) * slipItem.oddItem.price).toFixed(2)} TND
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ))}
        </>
    );
};
export default SingleOddBox;
