import { useState } from 'react';
import { Box, Container } from '@mui/material';
import { Icon } from '@iconify/react';

import { STYLE } from 'config';
import Footer from '.';
import './style.css';

const SportsFooter = () => {
    const [open, setOpen] = useState<Boolean>(false);
    const toggleOpen = () => setOpen(!open);
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                background: '#000c24',
                gridArea: 'footer',
                width: '100%',
                paddingInlineStart: '10px',
                paddingInlineEnd: '5px',
                willChange: 'transform',
                position: 'fixed',
                bottom: 0,
                opacity: open ? 1 : 0,
                transform: open ? 'translateZ(0)' : 'translate3d(0, 100%, 0)',
                transition: 'transform 0.24s',
                animation: 'footer-hidden 0.24s 0.24s forwards',
                zIndex: 952
            }}
        >
            <Icon
                className="pointer"
                icon={open ? 'icon-park-solid:down-one' : 'icon-park-solid:up-one'}
                onClick={toggleOpen}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    opacity: 1,
                    width: '60px',
                    height: '18px',
                    fontSize: '10px',
                    color: '#ffffffb3',
                    background: '#000c24',
                    borderRadius: '4px 4px 0 0',
                    border: '1px solid #ffffff0d',
                    borderBottom: 'none',
                    transform: 'translate(-50%)',
                    cursor: 'pointer',
                    transition: '0.24s'
                }}
            />
            <Box
                sx={{
                    maxHeight: '60vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    transition: 'max-height 0.24s',
                    // maxWidth: "none",
                    boxShadow: '0 1px 0 0 translucent.10 inset',
                    paddingTop: '0px',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        margin: 0,
                        padding: 0,
                        border: 0,
                        outline: 0,
                        boxSizing: 'border-box',
                        fontSize: '100%',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                        width: '100%'
                    }}
                >
                    <Box component="main">
                        <Container sx={{ maxWidth: `${STYLE.layoutWidth}px !important`, px: 0 }}>
                            <Footer />
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default SportsFooter;
