import { useMemo, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'hooks/use-boolean';
// config
import { Divider, IconButton, Skeleton } from '@mui/material';
import { Icon } from '@iconify/react';
// hooks
import { useRouter } from 'routes/hook';
import { useSportContext } from 'hooks/use-sport-context';
// type
import { ILeagueList, ILeagueParams } from 'types/sports';
import { paths } from 'routes/paths';

const LeagueItem = ({ league, countryId }: { league: ILeagueList; countryId: number }) => {
    const router = useRouter();
    const { sportId } = useSportContext();
    const handleClick = () => {
        router.push(`${paths.sports.pre.event_view}/${sportId}/${countryId}/${league.competitionId}`);
    };
    return (
        <Box
            sx={{
                px: 0.5,
                borderRadius: 1,
                transition: 'all 0.24s',
                bgcolor: 'translucent.01'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 35,
                    cursor: 'pointer',
                    borderRadius: 1,
                    transition: 'background 0.24s'
                }}
            >
                <IconButton sx={{ padding: '2px' }} onClick={(e) => e.stopPropagation()}>
                    <Icon icon="mingcute:star-line" width="20" height="20" />
                </IconButton>
                <Divider
                    orientation="vertical"
                    sx={{
                        height: '80%',
                        background: '#000c24',
                        borderRightWidth: '1.5px',
                        ml: 0.5
                    }}
                />
                <Stack direction="row" alignItems="center" width={1} onClick={handleClick}>
                    <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden', ml: 1 }}>
                        <Typography
                            sx={{
                                mr: 1,
                                lineHeight: 1,
                                color: '#fffc',
                                fontSize: 13,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                textTransform: 'capitalize'
                            }}
                        >
                            {league.competitionName}
                        </Typography>
                    </Stack>
                    <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                        {league.count}
                    </Typography>
                    <Box
                        component="i"
                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 4,
                            lineHeight: 23,
                            color: 'translucent.06',
                            transition: 'color 0.24s, background 0.24s'
                        }}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

const CountryItem = ({ country }: { country: ILeagueParams }) => {
    const { countryId } = useSportContext();

    const isOpened = useBoolean(countryId === String(country.countryId));

    const handleClick = () => {
        isOpened.onToggle();
    };

    return (
        <Box
            sx={{
                bgcolor: 'translucent.01',
                borderRadius: 1,
                transition: 'all 0.24s'
            }}
            onClick={handleClick}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 36,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box
                    component="img"
                    src={`https://flagcdn.com/w320/${country.code}.png`}
                    sx={{ width: 18, height: 12, mr: 1 }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {country?.countryName}
                    </Typography>
                </Box>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 13 }}>
                    {country.count}
                </Typography>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            // bgcolor: COLOR_LIST[index],
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    px={0.5}
                    sx={{
                        gap: 0.2,
                        pb: 0.5
                    }}
                >
                    {country.competition.map((league, i) => (
                        <LeagueItem key={i} league={league} countryId={country.countryId} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const MobileUpcomingList = ({
    period,
    isLoading
}: {
    period: { label: string; value: string };
    isLoading: boolean;
}) => {
    const { premacthLoading, prematchCategory, sportId } = useSportContext();
    const countries = useMemo(() => {
        if (sportId) {
            const sport = prematchCategory.find((sport) => String(sport.sportId) === sportId);
            if (sport) return sport.country;
            return [];
        }
        return [];
    }, [prematchCategory, sportId]);
    return (
        <Box
            sx={{
                flex: 'auto',
                position: 'relative',
                py: 0.5
            }}
        >
            {premacthLoading ? (
                <Stack sx={{ width: 1, minHeight: '100%', pr: 0.2, mb: 0 }} spacing={0.2}>
                    {new Array(15).fill(1).map((_, i) => (
                        <Skeleton key={i} height={36} variant="rounded" sx={{ borderRadius: 0.5 }} />
                    ))}
                </Stack>
            ) : (
                <Stack sx={{ width: 1, minHeight: '100%', pr: 0.2, mb: 0 }} spacing={0.2}>
                    {countries.map((country, i) => (
                        <CountryItem key={i} country={country} />
                    ))}
                </Stack>
            )}
        </Box>
    );
};

export default MobileUpcomingList;
