// Icon
import { Icon } from '@iconify/react';
import { Box, Button, IconButton, Skeleton, Stack } from '@mui/material';
// hook
import { useSportContext } from 'hooks/use-sport-context';
import { useRouter } from 'routes/hook';
// component
import SportBtn from 'components/Sports/SportBtn';
import MobileLiveList from '../LiveList';

const Live = () => {
    const { liveLoading, liveCategory } = useSportContext();

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    p: 0.5,
                    mt: 0.2,
                    height: 40,
                    borderRadius: 1
                }}
            >
                <Stack direction="row">
                    <Button
                        variant="outlined"
                        sx={{ px: 1.5, py: 0, minWidth: 0, height: 28, borderColor: 'translucent.06' }}
                    >
                        <Icon icon="gridicons:video-camera" style={{ width: 18, height: 18 }} />
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ px: 1.5, py: 0, minWidth: 0, height: 28, ml: 1, borderColor: 'translucent.06' }}
                    >
                        <Icon icon="mdi:world-wide-web" style={{ width: 18, height: 18 }} />
                    </Button>
                </Stack>
                <IconButton
                    sx={{
                        borderRadius: 1,
                        width: 36,
                        height: 36,
                        border: 'solid 1px',
                        borderColor: 'translucent.02'
                    }}
                >
                    <Icon
                        icon="heroicons-solid:search"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: 'auto',
                            width: 22,
                            height: 22,
                            color: '#ffffff80'
                        }}
                    />
                </IconButton>
            </Stack>
            <Box
                width={1}
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '& ::-moz-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        mt: 0.2,
                        gap: 0.5,
                        mb: 0.5,
                        width: '100%',
                        overflow: 'auto',
                        position: 'relative'
                    }}
                >
                    {(() => {
                        if (liveLoading) {
                            return new Array(6).fill(1).map((_, i) => (
                                <Skeleton
                                    key={`skeleton-${i}`}
                                    variant="rounded"
                                    sx={{
                                        width: '80px',
                                        minWidth: '80px',
                                        borderRadius: '4px',
                                        position: 'initial'
                                    }}
                                    height={60}
                                />
                            ));
                        }

                        // Directly return this if loading.value is true
                        return liveCategory.map((item, i) => <SportBtn sport={item} key={i} />);
                    })()}
                </Stack>
            </Box>
            <MobileLiveList />
        </>
    );
};

export default Live;
