import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
// mui
import {
    Box,
    Button,
    Collapse,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
// icon
import { Icon } from '@iconify/react';
// utils
import { parseQueryString } from 'utils/utils';
// hook
import { useRouter } from 'routes/hook';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    minHeight: '34px',
    height: '34px',
    background: 'linear-gradient(to right,rgba(255, 255, 255,.15) 0%,rgba(255, 255, 255,.05) 100%)',
    minWidth: 220,
    borderLeft: '2.5px solid',
    borderLeftColor: 'transparent',
    borderRadius: 3,
    marginBottom: '2px',
    '&:hover': {
        borderLeftColor: theme.palette.primary.main
    },
    '&:not(:last-of-type)': {
        marginBottom: '2px !important'
    }
}));

const navData = [
    {
        title: 'My Profile',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-user" />,
        path: 'profile=open&account=profile&page=details'
    },
    {
        title: 'Bet Builder History',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-bet-builder-history" />,
        path: 'profile=open&account=bet-builder-history&page=bets'
    },
    {
        title: 'Balance Management',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-balance-management" />,
        path: 'profile=open&account=balance&page=deposit&selectedGroup=all'
    },
    {
        title: 'Bet History',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-history" />,
        path: 'profile=open&account=history&page=bets'
    },
    {
        title: 'Bonuses',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-promotion" />,
        path: 'profile=open&account=bonuses&page=sport'
    },
    {
        title: 'Messages',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-message" />,
        path: 'profile=open&account=messages&page=inbox'
    },
    {
        title: 'Rewards',
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-rewards" />,
        path: 'profile=open&account=rewards&page=rewards&selectedRewardPage=rewards'
    }
];

const UserInfo = () => {
    const router = useRouter();
    const navRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (path: string) => {
        const params = parseQueryString(path);
        router.addQueryParams(params);
    };

    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                onClick={() => handleClick('profile=open&account=profile&page=details')}
                ref={navRef}
                sx={{
                    overflow: 'hidden',
                    fontSize: 15,
                    borderRadius: 0,
                    borderTop: '2px solid',
                    letterSpacing: '0.1px',
                    borderTopColor: open ? 'primary.main' : 'transparent',
                    height: 28,
                    minWidth: 0,
                    maxWidth: '112px',
                    py: 0,
                    bgcolor: 'transparent',
                    color: 'white',
                    px: 0,
                    ml: 1.5,
                    '&:hover': {
                        bgcolor: 'background.default',
                        boxShadow: 'none',
                        borderTopColor: 'primary.main'
                    },
                    '&::after': {
                        right: '-10px',
                        content: `''`,
                        position: 'absolute',
                        width: '1px',
                        height: '30px',
                        bottom: 0,
                        top: 0,
                        margin: 'auto',
                        zIndex: -1,
                        bgcolor: 'translucent.02'
                    }
                }}
            >
                <Icon icon="solar:user-circle-bold" width={30} />
            </Button>
            {!isMobile &&
                <Popover
                    open={open}
                    anchorEl={navRef.current}
                    aria-hidden={false}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    slotProps={{
                        paper: {
                            onMouseEnter: handleOpen,
                            onMouseLeave: handleClose,
                            elevation: 0,
                            sx: {
                                boxShadow: 'none',
                                bgcolor: 'background.default',
                                marginLeft: -0.5,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                padding: '8px',
                                ...(open && {
                                    pointerEvents: 'auto'
                                })
                            }
                        }
                    }}
                    sx={{
                        pointerEvents: 'none'
                    }}
                >
                    <Collapse in={open} orientation="vertical">
                        <MenuList sx={{ gap: 0 }}>
                            {navData.map((item, key) => (
                                <Stack aria-atomic key={key} onClick={() => handleClick(item.path)}>
                                    <StyledMenuItem
                                        onClick={handleClose}
                                        sx={{ color: 'common.white' }}
                                        LinkComponent={Link}
                                    >
                                        {item.icon && (
                                            <ListItemIcon sx={{ minWidth: '15px !important', marginRight: '10px' }}>
                                                {item.icon}
                                            </ListItemIcon>
                                        )}
                                        <Typography sx={{ color: 'translucent.08', fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    </StyledMenuItem>
                                </Stack>
                            ))}
                            <div style={{ marginTop: '10px' }} />
                            <Link aria-atomic to="/" replace>
                                <StyledMenuItem onClick={handleClose} sx={{ color: 'common.white' }} LinkComponent={Link}>
                                    <ListItemIcon sx={{ minWidth: '15px !important', marginRight: '10px' }}>
                                        <Box component="i" className="bc-i-logout" sx={{ color: 'translucent.08' }} />
                                    </ListItemIcon>
                                    <Typography sx={{ color: 'translucent.08', fontSize: '14px' }}>Log out</Typography>
                                </StyledMenuItem>
                            </Link>
                        </MenuList>
                    </Collapse>
                </Popover>
            }
        </Box>
    );
};
export default UserInfo;
