// mui
import { Box, Stack, Typography } from '@mui/material';
// hook
import { useBoolean } from 'hooks/use-boolean';
import { useSportContext } from 'hooks/use-sport-context';
// component
import RenderMarketGroup from 'components/Sports/RenderMarketGroup';
import RenderScoreBoard from './components/RenderScoreBoard';
import RenderMarketName from './components/RenderMarketName';

const MobileEventDetail = () => {
    const showMarkets = useBoolean(true);
    const { event, marketType, markets } = useSportContext();

    return (
        <Box
            sx={{
                height: 1,
                px: 0.1,
                flexShrink: 0,
                width: 1,
                overflowX: 'hidden'
            }}
        >
            <RenderScoreBoard />
            <RenderMarketName markets={marketType} />

            <Box sx={{ py: 0.1 }}>
                <Stack
                    onClick={() => showMarkets.onToggle()}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        px: 1,
                        borderRadius: 1,
                        height: 32,
                        cursor: 'pointer'
                    }}
                >
                    <Typography
                        sx={{
                            flex: 'auto',
                            fontSize: 12,
                            fontWeight: 500,
                            color: 'common.white',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        Markets
                    </Typography>
                    <Box
                        component="i"
                        className="sgm-m-h-toggle-icon-bc bc-i-small-arrow-up"
                        sx={{
                            fontSize: 13,
                            mb: 0.4,
                            color: '#ffffffb3',
                            transform: showMarkets.value ? 'rotate(0deg)' : 'rotate(180deg)',
                            transition: '0.24s all'
                        }}
                    />
                </Stack>

                <Stack sx={{ position: 'relative' }}>
                    {Object.keys(markets).map((key, i) => (
                        <RenderMarketGroup
                            key={i}
                            markets={markets[key]}
                            eventInfo={event.eventInfo}
                            flag={showMarkets.value}
                            fullMode={false}
                        />
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

export default MobileEventDetail;
