import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'utils/axios';

import { loginAction, logoutAction } from 'store/slices/auth';
import { AuthContext } from './auth-context';

type AuthProviderProps = {
    children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const dispatch = useDispatch();
    const store = useSelector((state: any) => state.auth);
    const initRef = useRef(false);

    const initialize = async () => {
        try {
            if (store.accessToken) {
                const response = await axios.get('/api/auth/me');
                if (!response?.data) return;
                dispatch(loginAction({ user: response.data, accessToken: store.accessToken }));
            } else {
                dispatch(logoutAction());
            }
        } catch (error) {
            dispatch(logoutAction());
        }
    };

    useEffect(() => {
        if (initRef.current === false) {
            initRef.current = true;
            initialize();
        }
        // eslint-disable-next-line
    }, []);

    const memoizedValue = useMemo(
        () => ({
            authLoading: store.authLoading,
            accessToken: store.accessToken,
            user: store.user,
            balance: store.user?.balance || 0,
            blockList: store.blockList,
            disabledMatch: store.disabledMatch,
            stakes: store.stakes,
            isLogined: store.isLogined
        }),
        [store]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
