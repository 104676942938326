import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// routes
import { useRouter } from 'routes/hook';
// hooks
import { useSportContext } from 'hooks/use-sport-context';
// components
import LiveList from './LiveList';
import UpcomingList from './UpcomingList';

const ListBar = () => {
    const router = useRouter();
    const { liveCount, upcomingCount, isLive } = useSportContext();

    return (
        <Stack sx={{ width: 300, transition: 'width 0.24s' }}>
            <Stack sx={{ p: 0.5, borderRadius: 1, bgcolor: 'translucent.05' }}>
                <Box
                    sx={{
                        pr: 3.7,
                        width: 1,
                        height: 36,
                        cursor: 'pointer',
                        maxWidth: '100%',
                        position: 'relative',
                        transition: 'max-width 0.24s',
                        bgcolor: 'background.default',
                        border: '1px solid',
                        borderColor: 'translucent.02',
                        borderRadius: 1
                    }}
                >
                    <Box
                        component="input"
                        placeholder="Search a competition or a team"
                        sx={{
                            px: 0.8,
                            width: 1,
                            height: 1,
                            fontSize: 12,
                            outline: 'none',
                            color: 'common.white',
                            border: 'none',
                            bgcolor: 'transparent',
                            '&::placeholder': {
                                color: 'translucent.03',
                                opacity: 0.7
                            }
                        }}
                    />
                    <Box
                        sx={{
                            borderStartEndRadius: 4,
                            borderEndEndRadius: 4,
                            display: 'inline-block',
                            width: 37,
                            height: 36,
                            position: 'absolute',
                            top: 0,
                            bgcolor: 'transparent'
                        }}
                    >
                        <Icon
                            icon="heroicons-solid:search"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                position: 'absolute',
                                top: 0,
                                right: 1,
                                bottom: 0,
                                left: 0,
                                margin: 'auto',
                                width: 18,
                                height: 18,
                                color: '#fff6'
                            }}
                        />
                    </Box>
                </Box>
            </Stack>

            <Stack direction="row" sx={{ mt: 0.2, height: 34 }}>
                <Button
                    onClick={() => router.push('/sports/live/event-view')}
                    variant="contained"
                    color={isLive ? 'primary' : 'inherit'}
                    sx={{
                        width: 1,
                        borderRadius: 0,
                        borderEndStartRadius: 4,
                        borderStartStartRadius: 4,
                        transition: 'all 0.24s',
                        color: isLive ? 'common.white' : 'translucent.03',
                        bgcolor: isLive ? 'primary.main' : 'translucent.01',
                        '&:hover': {
                            color: 'common.white',
                            bgcolor: 'primary.main'
                        }
                    }}
                >
                    Live ({liveCount})
                </Button>
                <Button
                    onClick={() => router.push('/sports/pre-match/event-view')}
                    variant="contained"
                    color={!isLive ? 'primary' : 'inherit'}
                    sx={{
                        ml: 0.1,
                        width: 1,
                        borderRadius: 0,
                        borderEndEndRadius: 4,
                        borderStartEndRadius: 4,
                        bgcolor: !isLive ? 'primary.main' : 'translucent.01',
                        transition: 'all 0.24s',
                        color: !isLive ? 'common.white' : 'translucent.03',
                        '&:hover': {
                            color: 'common.white',
                            bgcolor: 'primary.main'
                        }
                    }}
                >
                    Prematch ({upcomingCount})
                </Button>
            </Stack>

            {isLive ? <LiveList /> : <UpcomingList />}
        </Stack>
    );
};

export default ListBar;
