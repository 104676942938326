import { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
//
import { useMediaQuery, useTheme } from '@mui/material';
import { mainRoutes } from './main';
import { desktopSportsRoutes, mobileSportsRoutes } from './sports';
// @mui

// ----------------------------------------------------------------------

export default function Router() {
    const theme = useTheme();
    // const isMobile = theme.breakpoints.down('md');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const sportsRoutes = useMemo(() => {
        if (isMobile) {
            return mobileSportsRoutes;
        }
        return desktopSportsRoutes;
    }, [isMobile]);

    return useRoutes([
        // Main routes
        ...mainRoutes,
        // Sports routes
        ...sportsRoutes,
        // No match 404
        { path: '*', element: <Navigate to="/home" replace /> }
    ]);
}
