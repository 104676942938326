import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import FormProvider from 'components/hook-form';
import RHFSelect from 'components/hook-form/rhf-select';
import { StyledRHFTextField } from 'components/hook-form/rhf-text-field';
import { useBoolean } from 'hooks/use-boolean';
import SelectModal from 'components/Modal/SelectModal';
import { countries } from 'assets/data/countries';
import { currencies } from 'assets/data/currencies';
import { PasswordValidator } from 'components/PasswordValidator/PasswordValidator';
import { IFirstDefaultValue, ISecondDefaultValue } from 'types/user';
import { DatePicker } from '@mui/x-date-pickers';
import { RegisterAPI } from 'api/auth';
import { useDispatch } from 'store/store';
import { closeAuthModal, loginAction } from 'store/slices/auth';

interface ISecondStepProps {
    defaultValues: ISecondDefaultValue;
    firstValues: IFirstDefaultValue;
    onStep: (step: number) => void;
    setSecond: (data: ISecondDefaultValue) => void;
    currencyModal: any;
    countryModal: any;
    phoneCodeModal: any;
}

export const SecondStep = ({
    defaultValues,
    firstValues,
    currencyModal,
    countryModal,
    phoneCodeModal,
    onStep,
    setSecond
}: ISecondStepProps) => {
    const psd = useBoolean();
    const confirmPsd = useBoolean();
    const dispatch = useDispatch();

    const RegisterSecondSchema = Yup.object().shape({
        currency: Yup.string().required('This field is required'),
        country: Yup.object()
            .shape({
                code: Yup.string().required('Country code is required'),
                label: Yup.string().required('Country code is required')
            })
            .required('This field is required'),
        gender: Yup.string().required('This field is required'),
        birthDate: Yup.date().required('This field is required'),
        phone: Yup.object()
            .shape({
                code: Yup.string().required('Phone code is required'),
                label: Yup.string().required('Country code is required')
            })
            .required('This field is required'),
        phone_num: Yup.string().required('This field is required'),
        password: Yup.string().required('This field is required'),
        repeat_password: Yup.string()
            .required('This field is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
        promo_code: Yup.string()
    });

    const methods = useForm({
        resolver: yupResolver(RegisterSecondSchema),
        defaultValues
    });

    const { handleSubmit, watch, setValue, getValues } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            setSecond(data);
            const formData = {
                name: firstValues.name,
                surname: firstValues.surname,
                username: firstValues.username,
                email: firstValues.email,
                phoneNumber: data.phone.label + data.phone_num,
                password: data.password,
                currency: data.currency,
                gender: data.gender,
                birthDate: data.birthDate,
                countryCode: data.phone.code,
                country: data.country.code
            };
            const response = await RegisterAPI(formData);
            if (!response?.data) return;
            const { user, accessToken } = response.data;
            dispatch(loginAction({ user, accessToken }));
            dispatch(closeAuthModal(''));
        } catch (error) {
            console.log(error);
        }
    });

    const { country, phone, currency } = watch();

    return (
        <FormProvider methods={methods} onSubmit={onSubmit} style={{ height: 'calc(100% - 46px)' }}>
            <Stack height={1}>
                <Stack direction="column" gap={1.4} p={1} sx={{ flex: 'auto', overflowX: 'hidden', overflowY: 'auto' }}>
                    <Typography fontSize={14} color="#ffffff80" fontWeight={500}>
                        New Memeber?
                    </Typography>

                    <Typography fontSize="18px" color="white" fontWeight={500} textTransform="uppercase">
                        {`Register Now,it's easy!`}
                    </Typography>
                    <Typography fontSize="16px" color="white" fontWeight={500} textTransform="uppercase">
                        Registration step 2
                    </Typography>

                    <StyledRHFTextField
                        variant="filled"
                        name="currency"
                        value={currency}
                        label="Currency"
                        onClick={() => currencyModal.onTrue()}
                    />
                    <StyledRHFTextField
                        variant="filled"
                        name="country"
                        value={country.label || ''}
                        label="Country"
                        onClick={() => countryModal.onTrue()}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            loading="lazy"
                                            width="24"
                                            srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                            alt=""
                                        />
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                    <RHFSelect
                        variant="filled"
                        name="gender"
                        label="Gender"
                        options={[
                            { value: 'male', label: 'Male' },
                            { value: 'female', label: 'Female' }
                        ]}
                    />
                    <DatePicker
                        name="birthDate"
                        slotProps={{
                            openPickerIcon: { fontSize: 'medium' },
                            openPickerButton: { color: 'default' },
                            textField: {
                                variant: 'filled',
                                focused: true,
                                color: 'secondary'
                            }
                        }}
                        label="Birth date"
                    />
                    <Stack direction="row" gap={1}>
                        <StyledRHFTextField
                            variant="filled"
                            name="code"
                            value={phone.label || ''}
                            label="Code"
                            onClick={() => phoneCodeModal.onTrue()}
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                loading="lazy"
                                                width="24"
                                                srcSet={`https://flagcdn.com/w40/${phone.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${phone.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                        </InputAdornment>
                                    )
                                }
                            }}
                            sx={{ maxWidth: '100px' }}
                        />
                        <StyledRHFTextField variant="filled" name="phone_num" type="number" label="Phone number" />
                    </Stack>
                    <Stack>
                        <StyledRHFTextField
                            variant="filled"
                            name="password"
                            label="Password"
                            type={psd.value ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={psd.onToggle} edge="end">
                                            <Icon
                                                icon={psd.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                                width="24"
                                                height="24"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <PasswordValidator password={getValues('password')} />
                    </Stack>
                    <StyledRHFTextField
                        variant="filled"
                        name="repeat_password"
                        label="Repeat Password"
                        type={confirmPsd.value ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={confirmPsd.onToggle} edge="end">
                                        <Icon
                                            icon={confirmPsd.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                                            width="24"
                                            height="24"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <StyledRHFTextField variant="filled" name="promo_code" label="Promo code" />
                    <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="I'm over 18 years of age and have accepted"
                    />
                </Stack>
                <Stack direction="row" gap={1} bgcolor="translucent.05" px={1} py={3}>
                    <Button variant="outlined" fullWidth onClick={() => onStep(1)}>
                        BACK
                    </Button>
                    <Button
                        color="inherit"
                        size="large"
                        type="submit"
                        fullWidth
                        sx={{
                            color: 'white',
                            background: '#109121',
                            maxWidth: '370px',
                            height: '36px',
                            fontSize: '12px',
                            fontWeight: 500,
                            '&:hover': {
                                background: '#15be2b',
                                color: 'white'
                            }
                        }}
                    >
                        REGISTER
                    </Button>
                    {/* <LoadingButton loading variant="outlined">Submit</LoadingButton> */}
                </Stack>
            </Stack>

            <SelectModal
                flag
                setValue={({ code, label }: { code: string; label: string }) => setValue('country', { code, label })}
                selectModal={countryModal}
                placeFolder=""
                data={countries}
            />
            <SelectModal
                flag
                phone
                setValue={({ code, label }: { code: string; label: string }) => setValue('phone', { code, label })}
                selectModal={phoneCodeModal}
                placeFolder=""
                data={countries}
            />
            <SelectModal
                setValue={({ code, label }: { code: string; label: string }) => setValue('currency', label)}
                selectModal={currencyModal}
                placeFolder=""
                data={currencies}
            />
        </FormProvider>
    );
};
