import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
    const navigate = useNavigate();

    const router = useMemo(
        () => ({
            back: () => navigate(-1),
            forward: () => navigate(1),
            reload: () => window.location.reload(),
            push: (href: string) => navigate(href),
            replace: (href: string) => navigate(href, { replace: true }),
            addQueryParams: (params: Record<string, string>) => {
                const url = new URL(window.location.href);
                const searchParams = new URLSearchParams(url.search);
                searchParams.forEach((_, key) => {
                    if (!(key in params)) {
                        searchParams.delete(key);
                    }
                });

                Object.entries(params).forEach(([key, value]) => {
                    searchParams.set(key, value);
                });

                navigate(`${url.pathname}?${searchParams.toString()}`, { replace: true });
            },
            removeQueryParams: () => {
                const url = new URL(window.location.href);
                const searchParams = new URLSearchParams();
                navigate(`${url.pathname}?${searchParams.toString()}`, { replace: true });
            }
        }),
        [navigate]
    );

    return router;
}
