// @mui
import { Box, Button, Typography, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

// others
import { Icon } from '@iconify/react';
import { useDispatch } from 'store/store';
// types
import { childrenProp, IMenu, IMenuType } from 'types/sports/profile';
import { logoutAction } from 'store/slices/auth';
import { LogoutAPI } from 'api/auth';
// ----------------------------------------------------------------------

const menuList: IMenuType[] = [
    {
        icon: <Box component="i" className="user-nav-icon bc-i-user" />,
        // icon: <Icon icon='solar:user-circle-bold' width={24} height={24} />,
        text: 'My Profile',
        value: 'profile',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-user" />,
                text: 'Personal Detail',
                value: 'personal-detail'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-change-password" />,
                text: 'Change Password',
                value: 'change-password'
            }
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-bet-builder-history" />,
        text: 'Bet Builder History',
        value: 'balance-management',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-bet-history" />,
                text: 'All',
                value: 'all'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-open-bets" />,
                text: 'Open bets',
                value: 'open'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-won" />,
                text: 'Won',
                value: 'won'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-lost" />,
                text: 'Lost',
                value: 'lost'
            }
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-balance-management" />,
        text: 'Balance Management',
        value: 'balance-management',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-circle-dollar" />,
                text: 'Deposit',
                value: 'deposit'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-withdraw" />,
                text: 'Withdraw',
                value: 'withdraw'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-history" />,
                text: 'Transaction History',
                value: 'transaction-history'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-player-info" />,
                text: 'Info',
                value: 'info'
            }
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-history" />,
        text: 'Bet History',
        value: 'bet-history',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-bet-history" />,
                text: 'All',
                value: 'all_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-open-bets" />,
                text: 'Open bets',
                value: 'open_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-cashed-out" />,
                text: 'Cashed out',
                value: 'all_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-won" />,
                text: 'Won',
                value: 'won_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-lost" />,
                text: 'Lost',
                value: 'lost_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-returned" />,
                text: 'Returned',
                value: 'all_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-won-return" />,
                text: 'Won Return',
                value: 'all_bets'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-lost-return" />,
                text: 'Lost Return',
                value: 'all_bets'
            },
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-promotion" />,
        text: 'Bonuses',
        value: 'bonus',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-bonus-sportsbook" />,
                text: 'Sport Bonus',
                value: 'sport-bonus'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-bonus-casino" />,
                text: 'Casino Bonus',
                value: 'casino-bonus'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-history" />,
                text: 'Bonus History',
                value: 'bonus-history'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-promo-code" />,
                text: 'Promo code',
                value: 'promo'
            }
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-message" />,
        text: 'Messages',
        value: 'message',
        children: [
            {
                icon: <Box component="i" className="user-nav-icon bc-i-inbox" />,
                text: 'Inbox',
                value: 'message_inbox'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-sent" />,
                text: 'Sent',
                value: 'message_sent'
            },
            {
                icon: <Box component="i" className="user-nav-icon bc-i-add-circle" />,
                text: 'New',
                value: 'message_new'
            }
        ]
    },
    {
        icon: <Box component="i" className="user-nav-icon bc-i-rewards" />,
        text: 'Rewards',
        value: 'reward',
        children: []
    }
];

interface MenuProps {
    menu: IMenu;
    onChange?: (panel: childrenProp) => void;
}

const MenuNavigation = ({ menu, onChange }: MenuProps) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
            height: 42,
            pl: 1,
            pr: 1.5,
            cursor: 'pointer',
            borderRadius: 1,
            bgcolor: alpha('#FFF', 0.15)
        }}
        onClick={() => (onChange ? onChange(menu) : '')}
    >
        <Stack direction="row" width={1}>
            {menu.icon}
            <Typography
                sx={{
                    fontSize: 15,
                    mx: 1,
                    width: 1,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}
            >
                {menu.text}
            </Typography>
        </Stack>
        <Box
            component="i"
            className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
            sx={{
                fontSize: 12,
                color: alpha('#FFF', 0.5)
            }}
        />
    </Stack>
);

interface DetailProps {
    data: IMenuType;
    onClose: () => void;
    onChange?: (panel: childrenProp) => void;
}

const DetailNavigation = ({ data, onClose, onChange }: DetailProps) => (
    <Stack gap={0.1}>
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                bgcolor: 'translucent.05',
                height: 42,
                pl: 1,
                pr: 1.5,
                borderRadius: 0.4,
                cursor: 'pointer'
            }}
            onClick={onClose}
        >
            <Box
                component="i"
                className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
                sx={{
                    fontSize: 12,
                    transform: 'rotate(180deg)',
                    color: alpha('#FFF', 0.5)
                }}
            />
            <Typography fontSize={15} mx={1} color={alpha('#FFF', 0.9)} width={1}>
                {data.text}
            </Typography>
        </Stack>
        {/* <Collapse in={true} orientation='horizontal' timeout={4000}> */}
        <Stack gap={0.1}>
            {data.children.length &&
                data.children.map((menu, index: number) => (
                    <MenuNavigation key={index} menu={menu} onChange={onChange} />
                ))}
        </Stack>
        {/* </Collapse> */}
    </Stack>
);

const MobileMenu = ({
    selected,
    setActiveItem,
    queryParams,
    setSelected
}: {
    selected: IMenuType | null;
    setActiveItem: (value: childrenProp) => void;
    queryParams: any;
    setSelected: (value: IMenuType | null) => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const handleChange = (panel: childrenProp) => {
        setActiveItem(panel);
    };

    const onLogout = async () => {
        const response = await LogoutAPI();
        if (!response?.data) return;
        dispatch(logoutAction());
    };

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="column" gap={isMobile ? 0.1 : 1}>
                {selected ? (
                    <DetailNavigation data={selected} onClose={() => setSelected(null)} onChange={handleChange} />
                ) : (
                    menuList.map((menu, i) => (
                        <Box
                            key={`menu-${i}`}
                            onClick={() => setSelected(menu)}
                            sx={{ borderRadius: 1, transition: 'all 0.24s' }}
                        >
                            <MenuNavigation menu={menu} />
                        </Box>
                    ))
                )}

                <Stack direction="row" py={1.5} justifyContent="center">
                    {/* <Box
                        display="flex"
                        gap={1}
                        sx={{
                            bgcolor: 'translucent.01',
                            py: 1,
                            borderRadius: 1,
                            width: 1
                        }}
                    > */}
                    <TextField
                        variant="filled"
                        label="Promo Code"
                        sx={{
                            width: '100%',
                            marginInlineEnd: '10px',
                            bgcolor: 'translucent.04',
                            borderRadius: 1
                        }}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        disabled
                        sx={{
                            width: 'auto',
                            px: '40px'
                        }}
                    >
                        APPLY
                    </Button>
                    {/* </Box> */}
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 50,
                        px: 1,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: 'translucent.05',
                        '&:hover': {
                            bgcolor: 'translucent.01'
                        }
                    }}
                    onClick={onLogout}
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Icon icon="ic:twotone-logout" width="24" height="24" />
                        <Typography
                            sx={{
                                fontSize: 13,
                                mr: 1,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Log out
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default MobileMenu;
