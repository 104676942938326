export const PERIOD_LIST = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Today',
        value: 'today'
    },
    {
        label: '3 hours',
        value: '3'
    },
    {
        label: '6 hours',
        value: '6'
    },
    {
        label: '12 hours',
        value: '12'
    },
    {
        label: '24 hours',
        value: '24'
    },
    {
        label: '48 hours',
        value: '48'
    },
    {
        label: '72 hours',
        value: '72'
    }
];

export const MatchResult = [
    {
        label: 'Match Result'
    },
    {
        label: 'Double Chance'
    },
    {
        label: 'Draw No Bet'
    },
    {
        label: 'Golds Handicap'
    },
    {
        label: 'Total Goals'
    },
    {
        label: 'Both Team To Score'
    },
    {
        label: 'Team 1 To Score'
    },
    {
        label: 'Team 2 To Score'
    },
    {
        label: '1st Half Result'
    },
    {
        label: '1st Half Total Goals'
    }
];
