import { Box, Button, Typography, MenuList, MenuItem, Collapse, TextField, Stack } from '@mui/material';
// api
import { LogoutAPI } from 'api/auth';
// others
import { Icon } from '@iconify/react';
// hook
import { useRouter } from 'routes/hook';
import { useBoolean } from 'hooks/use-boolean';
// store
import { useDispatch } from 'store/store';
import { logoutAction } from 'store/slices/auth';
// types
import { IActiveItem, menuNapProps } from 'types/sports/profile';
// utils
import { parseQueryString } from 'utils/utils';
// import { LogoutAPI } from 'api/auth';
// reducer
// import { logoutAction } from 'store/slices/auth';
// import { useDispatch } from 'store/store';

const menuList = [
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-user" />,
        text: 'My Profile',
        value: 'profile',
        children: [
            { text: 'Personal Detail', value: 'details' },
            { text: 'Change Password', value: 'change-password' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-bet-builder-history" />,
        text: 'Bet Builder History',
        value: 'bet-builder-history',
        children: [
            { text: 'All', value: 'bets' },
            { text: 'Open bets', value: 'open-bets' },
            { text: 'Won', value: 'won' },
            { text: 'Lost', value: 'lost' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-balance-management" />,
        text: 'Balance Management',
        value: 'balance',
        children: [
            { text: 'Deposit', value: 'deposit' },
            { text: 'Withdraw', value: 'withdraw' },
            { text: 'Transaction History', value: 'history' },
            { text: 'Info', value: 'info' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-history" />,
        text: 'Bet History',
        value: 'history',
        children: [
            { text: 'All', value: 'bets' },
            { text: 'Open bets', value: 'open-bets' },
            { text: 'Cashed out', value: 'cashed-out' },
            { text: 'Won', value: 'won' },
            { text: 'Lost', value: 'lost' },
            { text: 'Returned', value: 'returned' },
            { text: 'Won Return', value: 'won-return' },
            { text: 'Lost Return', value: 'lost-return' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-promotion" />,
        text: 'Bonuses',
        value: 'bonuses',
        children: [
            { text: 'Sport Bonus', value: 'sport' },
            { text: 'Casino Bonus', value: 'casino' },
            { text: 'Bonus history', value: 'bonus-history' },
            { text: 'Promo code', value: 'promo-code' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-message" />,
        text: 'Messages',
        value: 'messages',
        children: [
            { text: 'Inbox', value: 'inbox' },
            { text: 'Sent', value: 'sent' },
            { text: 'New', value: 'new' }
        ]
    },
    {
        icon: <Box component="i" sx={{ color: 'translucent.08' }} className="bc-i-rewards" />,
        text: 'Rewards',
        value: 'rewards'
    }
];
const MenuNavigation = (props: menuNapProps) => {
    const { menu, activeItem } = props;
    const router = useRouter();
    const isOpened = useBoolean(
        !menu.children?.findIndex((item) => menu.value === activeItem.account && item.value === activeItem.page)
    );

    const handleClick = (account: string, page: string) => {
        let path = `profile=open&account=${account}&page=${page}`;
        if (account === 'balance' && (page === 'deposit' || page === 'withdraw')) {
            path += '&selectedGroup=all';
        }
        const params = parseQueryString(path);
        router.addQueryParams(params);
    };

    return (
        <Box onClick={isOpened.onToggle} sx={{ borderRadius: 1, transition: 'all 0.24s' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 50,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'translucent.05',
                    transition: 'background  0.24s'
                }}
            >
                <Stack direction="row" gap={1}>
                    {menu.icon}
                    <Typography
                        sx={{
                            fontSize: 13,
                            mr: 1,
                            textTransform: 'uppercase',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {menu.text}
                    </Typography>
                </Stack>
                {menu.children ? (
                    <Box
                        component="i"
                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 1,
                            lineHeight: 23,
                            transition: 'color 0.24s, background 0.24s',
                            color: isOpened.value ? 'common.white' : 'translucent.06',
                            ...(isOpened.value && {
                                transform: 'rotate(180deg)'
                            })
                        }}
                    />
                ) : (
                    <Box
                        component="i"
                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 1,
                            lineHeight: 23,
                            transition: 'color 0.24s, background 0.24s',
                            color: isOpened.value ? 'common.white' : 'translucent.06'
                        }}
                    />
                )}
            </Stack>
            {menu.children && (
                <Collapse in={isOpened.value}>
                    <Stack
                        gap={0.5}
                        p={1}
                        sx={{
                            bgcolor: 'translucent.01',
                            borderTop: 'solid 1px',
                            borderTopColor: 'translucent.01'
                        }}
                    >
                        {menu.children && (
                            <MenuList>
                                {menu?.children?.map((child, i) => (
                                    <MenuItem
                                        key={i}
                                        sx={{
                                            fontSize: 12,
                                            lineHeight: '30px',
                                            borderLeft: 'solid 2px',
                                            padding: 0,
                                            pl: '10px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            mb: '0 !important',
                                            borderColor:
                                                activeItem.page === child.value ? 'primary.main' : 'translucent.01',
                                            ...(activeItem.page === child.value && {
                                                backgroundImage:
                                                    'linear-gradient(to right, #000c244d 0%, #000c241a 100%)'
                                            }),
                                            '&:active': {
                                                backgroundImage:
                                                    'linear-gradient(to right, #000c244d 0%, #000c241a 100%)'
                                            }
                                        }}
                                        onClick={(e) => {
                                            // onChange(child);
                                            handleClick(menu.value, child.value);
                                            e.stopPropagation();
                                        }}
                                    >
                                        {child.text}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        )}
                    </Stack>
                </Collapse>
            )}
        </Box>
    );
};

const DeskMenu = ({ queryParams }: { queryParams: any }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const handleChange = (panel: IActiveItem) => {
        // setActiveItem(panel);
    };

    const onLogout = async () => {
        await LogoutAPI();
        dispatch(logoutAction());
        router.removeQueryParams();
    };

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="column" gap={1}>
                {menuList.map((menu, i) => (
                    <MenuNavigation
                        key={i}
                        menu={menu}
                        onChange={handleChange}
                        activeItem={{ text: menu.text, account: queryParams.account, page: queryParams.page }}
                    />
                ))}

                <Stack direction="row" p={1.5} justifyContent="center">
                    <Box
                        display="flex"
                        gap={1}
                        sx={{
                            bgcolor: 'translucent.01',
                            p: 1,
                            borderRadius: 1
                        }}
                    >
                        <TextField
                            variant="filled"
                            label="Promo Code"
                            sx={{
                                width: '60%',
                                marginInlineEnd: '10px',
                                bgcolor: 'translucent.04',
                                borderRadius: 1,
                                'input::placeholder': {
                                    color: 'red!important',
                                    opacity: 1
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            disabled
                            sx={{
                                width: 'auto'
                            }}
                        >
                            APPLY
                        </Button>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 50,
                        px: 1,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: 'translucent.05',
                        '&:hover': {
                            bgcolor: 'translucent.01'
                        }
                    }}
                    onClick={onLogout}
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Icon icon="ic:twotone-logout" width="24" height="24" />
                        <Typography
                            sx={{
                                fontSize: 13,
                                mr: 1,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Log out
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default DeskMenu;
