import { useState } from 'react';
import { useParams, useRouter } from 'routes/hook';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'hooks/use-boolean';
import { Skeleton } from '@mui/material';
// type
import { ILeagueList, ILeagueParams, IMarketType, IPeriodTime, ISportCategory, ISportParams } from 'types/sports';
// assets
import { Icons } from 'assets/data/icons';
import { PERIOD_LIST } from 'assets/data/matchList';
// store
import { RootState, useDispatch, useSelector } from 'store/store';
import { setPrematchCategory, selectCompetition, setPrematchCount } from 'store/slices/sports';
// api
import { getPrematchCategory } from 'api/sports';
// config
import { paths } from 'routes/paths';
import { useSportContext } from 'hooks/use-sport-context';

const CompetitionItem = ({
    competition,
    competitionClick
}: {
    competition: ILeagueList;
    competitionClick: (competitionId: number) => void;
}) => {
    const { competitionId } = useSportContext();

    const handleClick = () => {
        competitionClick(competition.competitionId);
    };
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                px: 1,
                height: 34,
                cursor: 'pointer',
                borderRadius: 1,
                bgcolor: `${competitionId === String(competition.competitionId) ? 'translucent.02' : 'translucent.04'}`,
                transition: 'background 0.24s',
                '&:hover': {
                    bgcolor: 'translucent.02'
                }
            }}
            onClick={handleClick}
        >
            <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden' }}>
                <Typography
                    sx={{
                        mr: 1,
                        lineHeight: 1,
                        color: '#fffc',
                        fontSize: 12,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textTransform: 'capitalize'
                    }}
                >
                    {competition.competitionName}
                </Typography>
            </Stack>
            <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                {competition.count}
            </Typography>
        </Stack>
    );
};

const CountryItem = ({
    country,
    sportId,
    marketType
}: {
    country: ILeagueParams;
    marketType: IMarketType[];
    sportId: number;
}) => {
    const router = useRouter();
    const { sportId: currentSportId, countryId } = useSportContext();

    const isOpened = useBoolean(countryId === String(country.countryId) && currentSportId === String(sportId));

    const handleClick = () => {
        isOpened.onToggle();
    };

    const competitionClick = (competitionId: number) => {
        router.push(`${paths.sports.pre.event_view}/${sportId}/${country.countryId}/${competitionId}`);
    };

    return (
        <Box
            sx={{
                px: 0.5,
                pb: isOpened.value ? 0.5 : 0,
                transition: 'all 0.24s',
                bgcolor: isOpened.value ? 'translucent.05' : 'translucent.05',
                boxShadow: (theme) => `inset 0 1px 0 0 ${theme.palette.translucent['01']}`
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    px: 0.5,
                    height: 34,
                    cursor: 'pointer',
                    borderRadius: 1,
                    transition: 'background 0.24s'
                }}
                onClick={handleClick}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ flex: 'auto', height: 1, overflow: 'hidden' }}
                >
                    <Box
                        component="img"
                        src={`https://flagcdn.com/w320/${country.code}.png`}
                        sx={{ width: 18, height: 12 }}
                    />
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 12,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        {country.countryName}
                    </Typography>
                </Stack>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    {country.count}
                </Typography>
                <Box
                    component="i"
                    className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 4,
                        lineHeight: 23,
                        color: 'translucent.06',
                        transition: 'color 0.24s, background 0.24s'
                    }}
                />
            </Stack>

            <Collapse in={isOpened.value}>
                <Stack
                    spacing={0.2}
                    sx={{
                        p: 0.2,
                        borderRadius: 1,
                        bgcolor: 'background.default',
                        opacity: 1,
                        maxHeight: '14000px',
                        pointerEvents: 'auto',
                        willChange: 'scroll-position',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                >
                    {country.competition.map((competition, i) => (
                        <CompetitionItem key={i} competition={competition} competitionClick={competitionClick} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const SportItem = ({ category, activeSportId }: { activeSportId: string; category: ISportCategory }) => {
    const { selectedCompetition, markets } = useSelector((state) => state.sports);
    const dispatch = useDispatch();
    const isOpened = useBoolean(activeSportId ? Number(activeSportId) === category.sportId : false);

    const handleClick = () => {
        dispatch(
            selectCompetition({ selectedCompetition: { ...selectedCompetition, sportId: category.sportId }, markets })
        );
        isOpened.onToggle();
    };

    return (
        <Box sx={{ bgcolor: 'translucent.01', borderRadius: 1, transition: 'all 0.24s' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 34,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: isOpened.value ? 'background.default' : 'translucent.10',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
                onClick={handleClick}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        justifyContent: 'start',
                        alignItems: 'center',
                        height: 1,
                        gap: 1
                    }}
                >
                    <Box
                        component="i"
                        className={`${Icons[`${category.sportId}`]?.icon}`}
                        sx={{ fontSize: 18, color: `${Icons[`${category.sportId}`]?.color}` }}
                    />
                    <Typography
                        sx={{
                            fontSize: 12,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {category.sportName}
                    </Typography>
                </Box>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    {category.count}
                </Typography>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            bgcolor: `${Icons[`${category.sportId}`] ? Icons[`${category.sportId}`].color : ''}`,
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                {isOpened.value &&
                    category.country.map((country, index) => (
                        <CountryItem
                            key={index}
                            country={country}
                            sportId={category.sportId}
                            marketType={category.marketType}
                        />
                    ))}
            </Collapse>
        </Box>
    );
};

const UpcomingList = () => {
    const { prematchCategory, premacthLoading, sportId, period, updatePeriod } = useSportContext();

    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const selectPeriod = (newPeriod: IPeriodTime) => {
        updatePeriod(newPeriod);
        setMarketAnchor(null);
    };

    const closePeriodPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Stack direction="row" sx={{ p: 0.5, my: 0.5, height: 46, borderRadius: 1, bgcolor: 'translucent.05' }}>
                <Button
                    size="small"
                    onClick={openMarketPopover}
                    sx={{ bgcolor: 'translucent.04', py: 0, height: 28, color: '#ffffffe6' }}
                    startIcon={
                        <Box
                            component="i"
                            className="select-icon-bc bc-i-time"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '14px !important',
                                width: 26,
                                height: 26,
                                color: '#ffffffe6',
                                textAlign: 'center'
                            }}
                        />
                    }
                    endIcon={
                        <Box
                            component="i"
                            className="form-control-icon-bc bc-i-small-arrow-down"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '10px !important',
                                width: 20,
                                height: 20,
                                textAlign: 'center'
                            }}
                        />
                    }
                >
                    {period.label}
                </Button>
            </Stack>

            <Box
                sx={{
                    mt: 0.2,
                    flex: 'auto',
                    position: 'relative',
                    height: 1,
                    willChange: 'scroll-position',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                }}
            >
                <Stack sx={{ position: 'absolute', top: 0, left: 0, width: 1, minHeight: '100%' }} spacing={0.2}>
                    {(() => {
                        if (premacthLoading) {
                            return new Array(20)
                                .fill(1)
                                .map((_, i) => (
                                    <Skeleton
                                        key={`skeleton-${i}`}
                                        variant="rounded"
                                        sx={{ width: 1, borderRadius: '4px' }}
                                        height={34}
                                    />
                                ));
                        }

                        if (!prematchCategory || prematchCategory.length === 0) {
                            return (
                                <Stack sx={{ borderRadius: 1, py: 2 }} bgcolor="translucent.05" justifyContent="center">
                                    <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary' }}>
                                        No Result
                                    </Typography>
                                </Stack>
                            );
                        }
                        if (!sportId) {
                            return new Array(20)
                                .fill(1)
                                .map((_, i) => (
                                    <Skeleton
                                        key={`skeleton-${i}`}
                                        variant="rounded"
                                        sx={{ width: 1, borderRadius: '4px' }}
                                        height={34}
                                    />
                                ));
                        }
                        return prematchCategory.map((category, index: number) => (
                            <SportItem key={index} category={category} activeSportId={sportId} />
                        ));
                    })()}
                </Stack>
            </Box>

            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closePeriodPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        // minWidth: 288,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {PERIOD_LIST.map((periodItem: IPeriodTime, i) => (
                        <Stack
                            key={i}
                            onClick={() => selectPeriod(periodItem)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{periodItem.label}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default UpcomingList;
