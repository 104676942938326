import { useState } from 'react';
import { useRouter } from 'routes/hook';
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
// components
import OddButton from 'components/Sports/OddButton';
// hooks
import { useBoolean } from 'hooks/use-boolean';
import { useSportContext } from 'hooks/use-sport-context';
// type
import { ILiveEventInfo, IScoreBoard, IMarketType, ILiveCategoryItem, ILiveCategoryCountry } from 'types/sports';
// assets
import { Icons } from 'assets/data/icons';
// route
import { paths } from 'routes/paths';
// utils
import { getOddName } from 'utils/sport';
import { formatTime } from 'utils/format-number';

const EventItem = ({
    eventInfo,
    scoreboard,
    marketCount,
    marketType
}: {
    eventInfo: ILiveEventInfo;
    scoreboard: IScoreBoard;
    marketCount: number;
    marketType: IMarketType;
}) => {
    const router = useRouter();
    const { liveEvents, eventId, periods } = useSportContext();

    const handleClick = () => {
        router.push(
            `${paths.sports.live.event_view}/${eventInfo.sport.id}/${eventInfo.country.id}/${eventInfo.competition.id}/${eventInfo.id}`
        );
    };

    const renderEmptyMarket = (
        <>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s'
                }}
            >
                -
            </Button>
        </>
    );

    return (
        <Box
            sx={{
                cursor: 'pointer',
                px: 0.5,
                pt: 0.6,
                pb: 1,
                borderRadius: 1,
                position: 'relative',
                overflow: 'hidden',
                bgcolor: `${eventId === String(eventInfo.id) ? 'translucent.09' : 'translucent.02'}`,
                transition: 'background 0.24s, box-shadow 0.24s',
                '&::before': {
                    bgcolor: `${eventId === String(eventInfo.id) ? '#109121' : ''}`,
                    content: `""`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 2,
                    height: 1
                }
            }}
            onClick={handleClick}
        >
            {(() => {
                if (eventInfo.isOutright) {
                    return (
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography component="span" sx={{ flex: 'auto', color: 'translucent.07', fontSize: 12 }}>
                                {eventInfo.name}
                            </Typography>
                        </Typography>
                    );
                }
                return eventInfo.competitors.map((competitor, i) => (
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} key={i}>
                        <Typography component="span" sx={{ flex: 'auto', color: 'translucent.07', fontSize: 12 }}>
                            {competitor.name}
                        </Typography>
                        <Typography component="b" sx={{ flexShrink: 0, ml: 0.4, color: 'warning.main', fontSize: 12 }}>
                            {scoreboard && scoreboard.results.length > 0 ? scoreboard.results[i].value : 0}
                        </Typography>
                    </Typography>
                ));
            })()}
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{
                    height: 22,
                    b: {
                        ml: 0.4,
                        height: 15,
                        minWidth: 23,
                        px: 0.2,
                        color: '#fff9',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        textAlign: 'center',
                        fontSize: 11,
                        lineHeight: '15px',
                        fontWeight: 400
                    }
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        flex: 'auto',
                        color: 'translucent.06',
                        fontSize: 11,
                        lineHeight: '22px'
                    }}
                >
                    {(() => {
                        if (scoreboard && scoreboard.currentPeriodId) {
                            if (periods[scoreboard.currentPeriodId]) {
                                return periods[scoreboard.currentPeriodId];
                            }
                            return '';
                        }
                        return '';
                    })()}
                </Typography>
                {marketCount && marketCount > 0 && <b>+{marketCount}</b>}
                <Box
                    component="i"
                    className="bc-i-favorite"
                    sx={{
                        ml: 0.4,
                        fontSize: 10
                    }}
                />
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{
                    height: 22,
                    b: {
                        ml: 0.4,
                        minWidth: 23,
                        px: 0.2,
                        color: '#fff9',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        textAlign: 'center',
                        fontSize: 11,
                        lineHeight: 1,
                        fontWeight: 400
                    }
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        flex: 'auto',
                        color: 'translucent.06',
                        fontSize: 11,
                        lineHeight: 1
                    }}
                >
                    {(() => {
                        if (eventInfo.isOutright) return null;
                        if (scoreboard && scoreboard.results.length > 0) {
                            const homeScore = scoreboard.results[0].value;
                            const awayScore = scoreboard.results[1].value;

                            if (scoreboard.periods) {
                                const period = scoreboard.periods.find((p) => p.id === scoreboard.currentPeriodId);
                                if (period && !period.isFinished && period.results && period.results.length) {
                                    const homePeriodScore = period.results[0].value;
                                    const awayPeriodScore = period.results[1].value;
                                    return `${homeScore} : ${awayScore}, (${homePeriodScore}:${awayPeriodScore})`;
                                }
                            }
                            return `${homeScore} : ${awayScore}`;
                        }
                        return '0 : 0';
                    })()}
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        ml: 0.4,
                        color: 'translucent.06',
                        fontSize: 11,
                        lineHeight: 1
                    }}
                >
                    {formatTime(eventInfo.startTime)}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                spacing={0.1}
                sx={{ mt: 1, borderRadius: 1, overflow: 'hidden' }}
            >
                {(() => {
                    if (liveEvents[eventInfo.id]) {
                        const market = liveEvents[eventInfo.id].markets.find((m) => m.type === marketType.id);
                        if (market) {
                            return market.selections.map((selection, i) => {
                                const oddName = getOddName(market, selection, eventInfo.competitors);
                                return (
                                    <OddButton
                                        key={i}
                                        eventInfo={eventInfo}
                                        oddName={oddName}
                                        marketName={market.name}
                                        marketId={market.id}
                                        tradingStatus={market.tradingStatus}
                                        selection={selection}
                                    />
                                );
                            });
                        }
                        return renderEmptyMarket;
                    }
                    return renderEmptyMarket;
                })()}
            </Stack>
        </Box>
    );
};

const CountryItem = ({ country, marketType }: { country: ILiveCategoryCountry; marketType: IMarketType }) => {
    const isOpened = useBoolean(true);

    return (
        <Box
            sx={{
                px: 0.5,
                pb: isOpened.value ? 0.5 : 0,
                transition: 'all 0.24s',
                bgcolor: isOpened.value ? 'translucent.02' : 'translucent.04',
                boxShadow: (theme) => `inset 0 1px 0 0 ${theme.palette.translucent['01']}`
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    px: 0.5,
                    height: 34,
                    cursor: 'pointer',
                    borderRadius: 1,
                    transition: 'background 0.24s'
                }}
                onClick={isOpened.onToggle}
            >
                <Box
                    component="img"
                    src={`https://flagcdn.com/w320/${country.code}.png`}
                    sx={{ width: 18, height: 12 }}
                />
                <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden', ml: 0.5 }}>
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 12,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        {country.countryName}
                    </Typography>
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 12,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        {country.events[`${Object.keys(country.events)[0]}`][0].eventInfo.competition.name}
                    </Typography>
                </Stack>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    {country.events[`${Object.keys(country.events)[0]}`].length}
                </Typography>
                <Box
                    component="i"
                    className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 4,
                        lineHeight: 23,
                        color: 'translucent.06',
                        transition: 'color 0.24s, background 0.24s'
                    }}
                />
            </Stack>

            <Collapse in={isOpened.value}>
                {isOpened.value && (
                    <Stack
                        spacing={0.2}
                        sx={{
                            p: 0.2,
                            borderRadius: 1,
                            bgcolor: 'background.default',
                            opacity: 1,
                            maxHeight: '14000px',
                            pointerEvents: 'auto',
                            willChange: 'scroll-position',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        {country.events[`${Object.keys(country.events)[0]}`].map((event, i) => (
                            <EventItem
                                key={i}
                                eventInfo={event.eventInfo}
                                scoreboard={event.scoreboard}
                                marketCount={event.marketCount}
                                marketType={marketType}
                            />
                        ))}
                    </Stack>
                )}
            </Collapse>
        </Box>
    );
};

const SportItem = ({ category }: { category: ILiveCategoryItem }) => {
    const { sportId } = useSportContext();

    const isOpened = useBoolean(sportId ? sportId === String(category.sportId) : false);

    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };
    const [selectedMarket, setSelectedMarket] = useState<IMarketType>(
        category.marketType.length > 0
            ? category.marketType[0]
            : {
                  id: 0,
                  name: ''
              }
    );

    const closeLangPopover = (sMarketType: IMarketType | null) => {
        setMarketAnchor(null);
        if (sMarketType) {
            setSelectedMarket(sMarketType);
        }
    };

    return (
        <>
            <Box
                sx={{
                    bgcolor: `${sportId === String(category.sportId) && isOpened.value ? 'translucent.01' : 'translucent.00'}`,
                    borderRadius: 1,
                    transition: 'all 0.24s'
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 34,
                        px: 0.5,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: isOpened.value ? 'background.default' : 'translucent.10',
                        boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                        transition: 'background  0.24s',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                    onClick={isOpened.onToggle}
                >
                    <Box
                        component="i"
                        className={`${Icons[`${category.sportId}`]?.icon}`}
                        sx={{ fontSize: 18, color: `${Icons[`${category.sportId}`]?.color}`, mr: 1 }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 1
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                mr: 1,
                                textTransform: 'capitalize',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {category.sportName}
                        </Typography>
                    </Box>
                    <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                        {category.count}
                    </Typography>
                    <Box
                        component="i"
                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 1,
                            lineHeight: 23,
                            transition: 'color 0.24s, background 0.24s',
                            color: isOpened.value ? 'common.white' : 'translucent.06',
                            ...(isOpened.value && {
                                bgcolor: `${Icons[`${category.sportId}`] && Icons[`${category.sportId}`].color}`,
                                transform: 'rotate(180deg)'
                            })
                        }}
                    />
                </Stack>
                <Collapse in={isOpened.value}>
                    <Box
                        sx={{
                            opacity: 1,
                            maxHeight: '14000px',
                            pointerEvents: 'auto',
                            willChange: 'scroll-position',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                width: 1,
                                px: 0.5,
                                py: 0.5,
                                justifyContent: 'flex-start',
                                borderTop: '1px solid',
                                borderColor: 'translucent.02',
                                bgcolor: '#1a253a'
                            }}
                        >
                            <Button
                                size="small"
                                onClick={openMarketPopover}
                                sx={{ bgcolor: 'translucent.04', py: 0, px: 1, height: 22 }}
                                endIcon={
                                    <Box
                                        component="i"
                                        className="form-control-icon-bc bc-i-small-arrow-down"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '10px !important',
                                            width: 20,
                                            height: 20,
                                            textAlign: 'center'
                                        }}
                                    />
                                }
                            >
                                {selectedMarket.name}
                            </Button>
                        </Stack>
                    </Box>
                    {isOpened.value &&
                        category.country.map((country, i) => (
                            <CountryItem key={i} country={country} marketType={selectedMarket} />
                        ))}
                </Collapse>
            </Box>
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={() => closeLangPopover(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 288,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {category.marketType.map((marketType, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLangPopover(marketType)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{marketType.name}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

const LiveList = () => {
    const { liveCategory, liveLoading, eventId } = useSportContext();

    return (
        <>
            <Stack direction="row" sx={{ p: 0.5, mt: 0.5, height: 46, borderRadius: 1, bgcolor: 'translucent.05' }}>
                <Button
                    variant="outlined"
                    sx={{ px: 1.3, py: 0, minWidth: 0, height: 28, borderColor: 'translucent.06' }}
                >
                    <Icon icon="gridicons:video-camera" style={{ width: 18, height: 18 }} />
                </Button>
                <Button
                    variant="outlined"
                    sx={{ px: 1.3, py: 0, minWidth: 0, height: 28, ml: 1, borderColor: 'translucent.06' }}
                >
                    <Icon icon="mdi:world-wide-web" style={{ width: 18, height: 18 }} />
                </Button>
            </Stack>

            <Box
                sx={{
                    mt: 0.65,
                    flex: 'auto',
                    position: 'relative',
                    height: 1,
                    willChange: 'scroll-position',
                    overflowX: 'hidden',
                    overflowY: 'scroll'
                }}
            >
                <Stack sx={{ position: 'absolute', top: 0, left: 0, width: 1, minHeight: '100%' }} spacing={0.2}>
                    {(() => {
                        if (liveLoading && liveCategory.length === 0) {
                            return new Array(20)
                                .fill(1)
                                .map((_, i) => (
                                    <Skeleton
                                        key={`skeleton-${i}`}
                                        variant="rounded"
                                        sx={{ width: 1, borderRadius: '4px' }}
                                        height={34}
                                    />
                                ));
                        }

                        if (liveCategory.length === 0) {
                            return (
                                <Stack sx={{ borderRadius: 1, py: 2 }} bgcolor="translucent.05" justifyContent="center">
                                    <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary' }}>
                                        No Result
                                    </Typography>
                                </Stack>
                            );
                        }

                        if (!eventId) {
                            return new Array(20)
                                .fill(1)
                                .map((_, i) => (
                                    <Skeleton
                                        key={`skeleton-${i}`}
                                        variant="rounded"
                                        sx={{ width: 1, borderRadius: '4px' }}
                                        height={34}
                                    />
                                ));
                        }
                        return liveCategory.map((category, index: number) => (
                            <SportItem key={index} category={category} />
                        ));
                    })()}
                </Stack>
            </Box>
        </>
    );
};

export default LiveList;
