// mui
import { Box, Button, Divider, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
// logo
import Logo from 'components/Logo/logo';
// css
import './login.css';
// store
import { useDispatch } from 'store/store';
import { closeAuthModal, openAuthModal } from 'store/slices/auth';
import { useState } from 'react';
import { useBoolean } from 'hooks/use-boolean';
import { IFirstDefaultValue, ISecondDefaultValue } from 'types/user';
import { FirstStep } from './registerComponent/FirstStep';
import { SecondStep } from './registerComponent/SecondStep';

const Register = () => {
    const theme = useTheme();
    const countryModal = useBoolean();
    const phoneCodeModal = useBoolean();
    const currencyModal = useBoolean();

    const [step, setStep] = useState<number>(1);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const [firstDefaultValues, setFirst] = useState<IFirstDefaultValue>({
        name: '',
        surname: '',
        username: '',
        email: ''
    });

    const [secondDefaultValues, setSecond] = useState<ISecondDefaultValue>({
        currency: '',
        country: {
            code: '',
            label: ''
        },
        gender: '',
        birthDate: new Date(),
        phone: {
            code: '',
            label: ''
        },
        phone_num: '',
        password: '',
        promo_code: '',
        repeat_password: ''
    });

    const login = () => {
        dispatch(openAuthModal('login'));
    };
    const closeModal = () => {
        dispatch(closeAuthModal(''));
    };

    return (
        <Box
            sx={{
                minWidth: isMobile ? 'auto' : 400,
                width: 1,
                height: isMobile ? 1 : '77vh'
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" px={1} sx={{ height: '46px' }}>
                <Stack direction="row" alignItems="center" sx={{ flex: 'auto', height: '100%' }}>
                    <Logo />
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Button
                        sx={{
                            py: 0,
                            px: 0.5,
                            height: 28,
                            minWidth: 0,
                            maxWidth: '112px',
                            position: 'relative',
                            textDecoration: 'underline'
                        }}
                        onClick={login}
                    >
                        SING IN
                    </Button>
                    <IconButton onClick={closeModal}>
                        <Icon icon="ic:sharp-close" width="24" height="24" />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider />
            {step === 1 ? (
                <FirstStep onStep={setStep} defaultValues={firstDefaultValues} setFirst={setFirst} />
            ) : (
                <SecondStep
                    defaultValues={secondDefaultValues}
                    firstValues={firstDefaultValues}
                    onStep={setStep}
                    setSecond={setSecond}
                    currencyModal={currencyModal}
                    countryModal={countryModal}
                    phoneCodeModal={phoneCodeModal}
                />
            )}
        </Box>
    );
};
export default Register;
