import { memo, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
// types
import { IEventInfo, ISelection } from 'types/sports';
// hooks
import { useSportContext } from 'hooks/use-sport-context';

type Props = {
    eventInfo: IEventInfo;
    oddName: string;
    marketName: string;
    marketId: number;
    tradingStatus: number;
    selection: ISelection;
};

const OddButton = memo(({ eventInfo, oddName, marketName, marketId, tradingStatus, selection }: Props) => {
    const { betslip, addBetSlip, removeBetSlip } = useSportContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { isAdded, id } = useMemo(() => {
        const oddId = `${eventInfo.id}-${marketId}-${selection.id}`;
        const slipIndex = betslip.findIndex((b) => b.id === oddId);
        return { id: oddId, isAdded: slipIndex !== -1 };
    }, [eventInfo.id, marketId, selection.id, betslip]);

    const addOrRemoveOdd = () => {
        if (isAdded) {
            removeBetSlip(id);
        } else {
            addBetSlip({
                id,
                sportId: eventInfo.sport.id,
                countryId: eventInfo.country.id,
                competitionId: eventInfo.competition.id,
                eventId: eventInfo.id,
                marketId,
                oddId: selection.id,
                sportName: eventInfo.sport.name,
                countryName: eventInfo.country.name,
                competitionName: eventInfo.competition.name,
                eventName: eventInfo.name,
                marketName,
                oddName,
                startTime: eventInfo.startTime,
                allowMulti: true,
                betAmount: 0,
                oddItem: selection
            });
        }
    };

    if (tradingStatus !== 0) {
        return (
            <Button
                disabled
                sx={{
                    width: 1,
                    minWidth: 0,
                    minHeight: 34,
                    py: 0.4,
                    px: 1,
                    borderRadius: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    textAlign: 'center',
                    height: 1,
                    bgcolor: 'translucent.02',
                    position: 'relative',
                    transition: 'all 0.24s',
                    '&:hover': {
                        bgcolor: 'primary.main',
                        span: { color: 'common.white' },
                        i: { borderColor: 'transparent transparent #fff transparent' }
                    }
                }}
            >
                <LockIcon />
            </Button>
        );
    }

    return (
        <Button
            onClick={addOrRemoveOdd}
            sx={{
                width: 1,
                minWidth: 0,
                minHeight: 34,
                py: 0.4,
                px: 1,
                borderRadius: 0,
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',
                textAlign: 'center',
                height: 1,
                bgcolor: `${isAdded ? 'primary.main' : 'translucent.02'}`,
                position: 'relative',
                transition: 'all 0.24s',
                '&:hover': {
                    bgcolor: 'primary.main',
                    span: { color: 'common.white' },
                    i: { borderColor: 'transparent transparent #fff transparent' }
                }
            }}
        >
            {!isMobile && (
                <Typography
                    component="span"
                    className="ellipsis"
                    sx={{
                        flex: 'auto',
                        textAlign: 'start',
                        mb: 0,
                        mr: 0.4,
                        fontSize: 12,
                        color: '#ffffffb3',
                        wordBreak: 'break-word',
                        transition: 'all 0.24s'
                    }}
                >
                    {oddName}
                </Typography>
            )}
            <Stack direction="row" alignItems="center">
                <Typography
                    component="span"
                    sx={{
                        flex: 'auto',
                        textAlign: 'start',
                        mr: 0.4,
                        fontSize: 12,
                        lineHeight: '14px',
                        color: 'gold',
                        transition: 'color 0.24s'
                    }}
                >
                    {selection.price.toFixed(2)}
                </Typography>
                {(() => {
                    if (selection.changed !== 0 && selection.updateTime > new Date().valueOf() - 3000) {
                        return (
                            <Box
                                component="i"
                                className={
                                    selection.changed < 0
                                        ? 'market-arrow-up-down-bc is-down'
                                        : 'market-arrow-up-down-bc is-up'
                                }
                                sx={{
                                    right: 2,
                                    width: 0,
                                    height: 0,
                                    display: 'block',
                                    position: 'absolute',
                                    borderStyle: 'solid',
                                    transition: 'all 0.24s',
                                    borderWidth: '0 0 8px 8px',
                                    animation: 'odds-arrow-animation-change 7s forwards',

                                    ...(selection.changed < 0
                                        ? {
                                              bottom: 3,
                                              borderColor: 'transparent transparent red transparent'
                                          }
                                        : {
                                              top: 3,
                                              transform: 'rotate(270deg)',
                                              borderColor: 'transparent transparent #16c72e transparent'
                                          })
                                }}
                            />
                        );
                    }
                    return null;
                })()}
            </Stack>
        </Button>
    );
});

export default OddButton;
