import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
// components
import CalendarOdd from 'components/Sports/CalendarOdd';
// others
import { Icon } from '@iconify/react';
// hooks
import { useRouter } from 'routes/hook';
// import OddButton from 'components/Sports/OddButton';
import { US } from 'country-flag-icons/react/3x2';
import SlipBoard from '../Event/SlipBoard';
import FilterPart from './FilterPart';

type MatchItemTypes = {};
const MatchItem = (props: MatchItemTypes) => {
    const router = useRouter();
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                router.push('usa/5555/premerleague/123123');
                e.stopPropagation();
            }}
        >
            <Stack direction="row" minHeight={52}>
                <Stack
                    flex={1}
                    sx={{
                        padding: '5px',
                        bgcolor: 'translucent.01',
                        gap: 0.3
                    }}
                >
                    <Typography fontSize={12}>Girona FC</Typography>
                    <Typography fontSize={12}>Liverpool</Typography>
                </Stack>
            </Stack>
            <Stack
                flex={1}
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01',
                    gap: 0.1
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon icon="ion:football-sharp" style={{ width: 15, height: 15 }} />
                    <Icon icon="material-symbols:timer-outline" style={{ width: 15, height: 15 }} />
                    <Typography color="textSecondary" fontSize={11}>
                        17:00
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <US title="United States" style={{ width: 20, height: 15, marginRight: 10 }} />
                    <Typography color="textSecondary" fontSize={12}>
                        +25
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

const LiveCalendar = () => {
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Box sx={{ px: isMobile ? 0 : 1, width: 1, gridArea: 'content' }}>
                <Stack sx={{ height: 1, pb: 1 }}>
                    <FilterPart />
                    {isMobile ? (
                        <>
                            <Stack
                                direction="row"
                                px={1}
                                py={0.2}
                                gap={0.5}
                                width={1}
                                bgcolor="translucent.01"
                                alignItems="center"
                            >
                                <Button
                                    onClick={openMarketPopover}
                                    size="small"
                                    endIcon={<Icon icon="lsicon:down-outline" width="24" height="24" />}
                                    sx={{
                                        px: 1,
                                        justifyContent: 'space-between',
                                        flex: 1,
                                        bgcolor: 'translucent.05',
                                        textTransform: 'uppercase',
                                        height: 25
                                    }}
                                >
                                    winner
                                </Button>
                                <Stack
                                    position="relative"
                                    direction="row"
                                    justifyContent="space-around"
                                    flex={1}
                                    sx={{
                                        boxShadow: '-1px 0 0 0 #000c24'
                                    }}
                                >
                                    <Typography fontSize={11}>W1</Typography>
                                    <Typography fontSize={11}>X</Typography>
                                    <Typography fontSize={11}>W2</Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                sx={{
                                    marginTop: 1,
                                    width: 1,
                                    minHeight: '100%',
                                    px: 0.5
                                }}
                                spacing={0.5}
                            >
                                {new Array(5).fill(1).map((_, i) => (
                                    <MatchItem key={i} />
                                ))}
                            </Stack>
                        </>
                    ) : (
                        <Box sx={{ height: 1, position: 'relative' }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: 1,
                                    height: 1,
                                    pr: 0.2,
                                    willChange: 'scroll-position',
                                    overflowX: 'hidden',
                                    overflowY: 'scroll'
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        borderRadius: 1,
                                        bgcolor: 'translucent.04',
                                        height: 28,
                                        overflow: 'hidden',
                                        mb: 0.7
                                    }}
                                >
                                    <Box sx={{ flex: 'auto', height: 1 }}>
                                        <Button
                                            onClick={openMarketPopover}
                                            sx={{
                                                height: 1,
                                                py: 0,
                                                px: 1.5,
                                                borderStartEndRadius: 0,
                                                borderEndEndRadius: 0,
                                                borderRight: '1px solid',
                                                borderColor: 'background.default'
                                            }}
                                            endIcon={
                                                <Box
                                                    component="i"
                                                    className="form-control-icon-bc bc-i-small-arrow-down"
                                                    sx={{
                                                        fontSize: '12px !important',
                                                        color: '#fffc'
                                                    }}
                                                />
                                            }
                                        >
                                            Match Result
                                        </Button>
                                    </Box>
                                    <Stack
                                        spacing={0.1}
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            width: 315,
                                            height: 28,
                                            bgcolor: 'background.default'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ml: 0.1,
                                                textAlign: 'center',
                                                lineHeight: '28px',
                                                bgcolor: 'translucent.02',
                                                width: 1,
                                                color: 'common.white',
                                                fontSize: 11
                                            }}
                                        >
                                            W1
                                        </Typography>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                lineHeight: '28px',
                                                bgcolor: 'translucent.02',
                                                width: 1,
                                                color: 'common.white',
                                                fontSize: 11
                                            }}
                                        >
                                            W1
                                        </Typography>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                lineHeight: '28px',
                                                bgcolor: 'translucent.02',
                                                width: 1,
                                                color: 'common.white',
                                                fontSize: 11
                                            }}
                                        >
                                            W1
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Box>
                                    {new Array(19).fill(1).map((_, i) => (
                                        <Stack
                                            key={i}
                                            direction="row"
                                            sx={{
                                                cursor: 'pointer',
                                                mb: 0.2,
                                                minHeight: 55,
                                                borderRadius: 1,
                                                overflow: 'hidden',
                                                '&:hover': {
                                                    bgcolor: 'translucent.01'
                                                }
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                sx={{
                                                    width: 165,
                                                    pl: 1,
                                                    pr: 1.5,
                                                    position: 'relative',
                                                    bgcolor: 'translucent.01'
                                                }}
                                            >
                                                <Box
                                                    component="i"
                                                    className="bc-i-TableTennis game-d-c-h-c-r-sport-icon"
                                                    sx={{ mr: 0.5, fontSize: 13, color: '#839d24' }}
                                                />
                                                <Box
                                                    component="i"
                                                    className="info-live-prematch-icon-bc bc-i-prematch"
                                                    sx={{ mr: 0.5, fontSize: 13, color: '#ffffffa6' }}
                                                />
                                                <Typography
                                                    sx={{ fontSize: 12, lineHeight: '18px', color: '#ffffff80' }}
                                                >
                                                    04:05
                                                </Typography>
                                                <Stack
                                                    sx={{
                                                        '&::after': {
                                                            right: 0,
                                                            content: `""`,
                                                            position: 'absolute',
                                                            top: 10,
                                                            bottom: 10,
                                                            width: '1px',
                                                            background: 'translucent.10'
                                                        }
                                                    }}
                                                />
                                            </Stack>

                                            <Stack
                                                direction="row"
                                                sx={{
                                                    pl: 1.5,
                                                    flex: 'auto',
                                                    flexWrap: 'wrap',
                                                    overflow: 'hidden',
                                                    alignContent: 'center',
                                                    bgcolor: 'translucent.01'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        minHeight: 19,
                                                        display: 'flex',
                                                        width: 1,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            fontSize: 13,
                                                            lineHeight: '19px',
                                                            flex: 'auto',
                                                            color: 'common.white'
                                                        }}
                                                    >
                                                        Miloslav Ullman
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        minHeight: 19,
                                                        display: 'flex',
                                                        width: 1,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            fontSize: 13,
                                                            lineHeight: '19px',
                                                            flex: 'auto',
                                                            color: 'common.white'
                                                        }}
                                                    >
                                                        Miloslav Ullman
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            <Stack
                                                sx={{
                                                    px: 1,
                                                    alignItems: 'flex-end',
                                                    justifyContent: 'center',
                                                    bgcolor: 'translucent.01'
                                                }}
                                            >
                                                <Stack direction="row">
                                                    <Box
                                                        component="i"
                                                        className="info-game-flag-bc flag-bc world"
                                                        sx={{ fontSize: 22, height: 14 }}
                                                    />
                                                    <Typography component="span" sx={{ fontSize: 11, ml: 1 }}>
                                                        +22
                                                    </Typography>
                                                </Stack>

                                                <Stack direction="row" sx={{ mt: 1 }}>
                                                    <Box
                                                        component="i"
                                                        className="c-info-icon-bc bc-i-bet-builder"
                                                        sx={{ fontSize: 13, color: '#eacb0b', ml: 1 }}
                                                    />

                                                    <Box
                                                        component="i"
                                                        className="bc-i-favorite"
                                                        sx={{ fontSize: 13, color: '#fffc', ml: 1 }}
                                                    />
                                                </Stack>
                                            </Stack>

                                            <Stack direction="row" spacing={0.1} sx={{ bcolor: 'background.default' }}>
                                                <CalendarOdd odd={33} change={1} />
                                                <CalendarOdd odd={33} change={1} />
                                                <CalendarOdd odd={33} change={1} />
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Stack>
            </Box>

            <SlipBoard />

            {/* Market Select Popover */}
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closeLangPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 118,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(15).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLangPopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Match Result</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default LiveCalendar;
