import { Outlet } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// config
import { STYLE } from 'config';

const Wrapper = () => (
    <Box component="main">
        <Container
            sx={{
                // mt: isTableletdown ?
                //     `${STYLE.m_headerHeight + STYLE.subHeaderHeight + 10}px` :
                //     `${STYLE.headerHeight + STYLE.subHeaderHeight + 10}px`,
                maxWidth: `${STYLE.layoutWidth}px !important`,
                px: 0
            }}
        >
            <Outlet />
        </Container>
    </Box>
);

export default Wrapper;
