import { useMemo } from 'react';
// @mui
import { Box, Button, Typography } from '@mui/material';
// types
import { ILiveCategoryItem, IPrematchCateogryItem } from 'types/sports';
// hook
import { useRouter } from 'routes/hook';
import { useSportContext } from 'hooks/use-sport-context';
// routes
import { paths } from 'routes/paths';
// assets
import { Icons } from 'assets/data/icons';

type propsType = {
    sport: ILiveCategoryItem | IPrematchCateogryItem;
};
const SportBtn = (props: propsType) => {
    const { sportId, isLive } = useSportContext();
    const { sport } = props;
    const router = useRouter();
    const isActive = useMemo(() => sportId === String(sport.sportId), [sport, sportId]);

    const handleClick = () => {
        if (isLive) {
            router.push(`${paths.sports.live.event_view}/${sport.sportId}`);
        } else {
            router.push(`${paths.sports.pre.event_view}/${sport.sportId}`);
        }
    };
    return (
        <Box position={isActive ? 'sticky' : 'initial'} sx={{ left: '0px', right: '0px', zIndex: isActive ? 2 : 1 }}>
            <Button
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '80px',
                    height: '60px',
                    gap: 0.5,
                    bgcolor: isActive ? Icons[sport.sportId]?.color : 'translucent.01',

                    '&:hover': {
                        bgcolor: isActive ? Icons[sport.sportId]?.color : 'translucent.01'
                    }
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="caption"
                    fontSize={9}
                    lineHeight="11px"
                    sx={{
                        position: 'absolute',
                        top: 5,
                        right: 10
                    }}
                >
                    {sport?.count || 0}
                </Typography>
                {/* <Icon icon={} width="30" height="30" color={isActive ? 'white' : Icons[sport.sportId]?.color} /> */}
                <Box
                    component="i"
                    className={`${Icons[`${sport.sportId}`]?.icon}`}
                    sx={{ fontSize: 27, color: `${isActive ? 'white' : `${Icons[`${sport.sportId}`]?.color}`}` }}
                />
                <Typography fontSize={10} lineHeight="12px" color={isActive ? 'white' : 'translucent.03'}>
                    {sport?.sportName}
                </Typography>
            </Button>
        </Box>
    );
};
export default SportBtn;
