export const Icons: any = {
    1: {
        icon: 'bc-i-Soccer',
        color: '#109121'
    },
    2: {
        icon: 'bc-i-TableTennis',
        color: '#839d24'
    },
    4: {
        icon: 'bc-i-Tennis',
        color: '#a19e3e'
    },
    5: {
        icon: 'bc-i-Handball',
        color: '#42777f'
    },
    6: {
        icon: 'bc-i-IceHockey',
        color: '#50a2c6'
    },
    7: {
        icon: 'bc-i-Baseball',
        color: '#5caaa7'
    },
    8: {
        icon: 'bc-i-Basketball',
        color: '#fca601'
    },
    9: {
        icon: 'bc-i-Formula1',
        color: '#b8292b'
    },
    11: {
        icon: 'bc-i-Volleyball',
        color: '#dac179'
    },
    14: {
        icon: 'bc-i-AmericanFootball',
        color: '#e7685c'
    },
    15: {
        icon: 'bc-i-Cycling',
        color: '#d9c68f'
    },
    16: {
        icon: 'bc-i-Golf',
        color: '#189946'
    },
    17: {
        icon: 'bc-i-Mma',
        color: '#868686'
    },
    18: {
        icon: 'bc-i-Darts',
        color: '#d32939'
    },
    20: {
        icon: 'bc-i-Cricket',
        color: '#1bab7d'
    },
    21: {
        icon: 'bc-i-Futsal',
        color: '#65b5c2'
    },
    22: {
        icon: 'bc-i-Speedway',
        color: '#aca696'
    },
    23: {
        icon: 'bc-i-Badminton',
        color: '#70c2a7'
    },
    25: {
        icon: 'bc-i-Boxing',
        color: '#0e7acc'
    },
    27: {
        icon: 'bc-i-Snooker',
        color: '#37b764'
    },
    28: {
        icon: 'bc-i-GaelicFootball',
        color: '#358014'
    },
    30: {
        icon: 'bc-i-LeagueOfLegends',
        color: '#034653'
    },
    31: {
        icon: 'bc-i-Biathlon',
        color: '#bedbed'
    },
    32: {
        icon: 'bc-i-CrossCountrySkiing',
        color: '#bedbed'
    },
    33: {
        icon: 'bc-i-Bowls',
        color: '#2a8c4c'
    },
    36: {
        icon: 'bc-i-SkiJumping',
        color: '#2a8c4c'
    },
    37: {
        icon: 'bc-i-CounterStrike',
        color: '#cc581f'
    },
    39: {
        icon: 'bc-i-Floorball',
        color: '#72cddb'
    },
    44: {
        icon: 'bc-i-Athletics',
        color: '#72cddb'
    },
    45: {
        icon: 'bc-i-Dota2',
        color: '#9b1013'
    },
    46: {
        icon: 'bc-i-AlpineSkiing',
        color: '#93c6e5'
    },
    47: {
        icon: 'bc-i-CallOfDuty',
        color: '#826b4a'
    },
    48: {
        icon: 'bc-i-BallHockey',
        color: '#168763'
    },
    49: {
        icon: 'bc-i-WaterPolo',
        color: '#338fd6'
    },
    54: {
        icon: 'bc-i-Rally',
        color: '#c28540'
    },

    299: {
        icon: 'bc-i-Triathlon',
        color: '#c28540'
    },
    300: {
        icon: 'bc-i-Weightlifting',
        color: '#a19e3e'
    },
    458: {
        icon: 'bc-i-Greyhounds',
        color: '#119a18'
    },
    490: {
        icon: 'bc-i-Chess',
        color: '#0f67a7'
    },
    589: {
        icon: 'bc-i-Lacross',
        color: '#168763'
    },
    1051: {
        icon: 'bc-i-Esports',
        color: '#799755'
    }
    // 48: {
    //     icon: 'bc-i-Tennis',
    //     color: '#a19e3e'
    // },
    // 2: {
    //     icon: 'bc-i-TableTennis',
    //     color: '#839d24'
    // },
    // 58: {
    //     icon: 'bc-i-AustralianFootball',
    //     color: '#119a18'
    // },

    // 45: {
    //     icon: 'bc-i-Pelota',
    //     color: '#0f67a7'
    // },
    // 14: {
    //     icon: 'bc-i-SpecialBets',
    //     color: '#ef8a00'
    // },
    // 32: {
    //     icon: 'bc-i-Politics',
    //     color: '#641f6e'
    // },
    // 22: {
    //     icon: 'bc-i-RugbyLeague',
    //     color: '#799755'
    // },

    // 300: {
    //     icon: 'bc-i-RugbyUnion',
    //     color: '#799755'
    // },
    // 19: {
    //     icon: 'bc-i-Surfing',
    //     color: '#3598e4'
    // },
    // 7: {
    //     icon: 'bc-i-TVShowsAndMovies',
    //     color: '#3b6e2b'
    // },
    // 30: {
    //     icon: 'bc-i-Kabaddi',
    //     color: '#ee6c21'
    // },
    // 23: {
    //     icon: 'bc-i-RinkHockey',
    //     color: '#e3bb6d'
    // },

    // 21: {
    //     icon: 'bc-i-LeagueofLegendsWildRift',
    //     color: '#3280ff'
    // },
    // 47: {
    //     icon: 'bc-i-Valorant',
    //     color: '#c82833'
    // },
    // 18: {
    //     icon: 'bc-i-Overwatch',
    //     color: '#f68720'
    // },
    // 589: {
    //     icon: 'bc-i-RainbowSix',
    //     color: '#666d72'
    // },
    // 25: {
    //     icon: 'bc-i-StarCraft',
    //     color: '#326d5d'
    // },
    // 26: {
    //     icon: 'bc-i-StarCraft2',
    //     color: '#005895'
    // },
    // 458: {
    //     icon: 'bc-i-CrossFire',
    //     color: '#926b25'
    // },
    // 9: {
    //     icon: 'bc-i-KingOfGlory',
    //     color: '#73588e'
    // },
    // 37: {
    //     icon: 'bc-i-WarcraftIII',
    //     color: '#bb7013'
    // },
    // 399: {
    //     icon: 'bc-i-MobileLegends',
    //     color: '#a98157'
    // },
    // 371: {
    //     icon: 'bc-i-Deadlock',
    //     color: '#bb7013'
    // },
    // 372: {
    //     icon: 'bc-i-ApexLegends',
    //     color: '#a81b24'
    // },
    // 373: {
    //     icon: 'bc-i-GeoGuessr',
    //     color: '#bb7013'
    // },
    // 374: {
    //     icon: 'bc-i-CyberFootball',
    //     color: '#308231'
    // }
};
