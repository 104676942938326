import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slices/auth';
import casinoReducer from './slices/casino';
import sportsReducer from './slices/sports';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: []
};

const rootReducer = combineReducers({
    auth: authReducer,
    casino: casinoReducer,
    sports: sportsReducer
});

export default rootReducer;
