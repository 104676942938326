// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {
    odd: number;
    change: number;
};

const CalendarOdd = ({ odd, change }: Props) => (
    <Button
        sx={{
            px: 1,
            py: 0.4,
            width: 104,
            minWidth: 0,
            borderRadius: 0,
            flexWrap: 'nowrap',
            textAlign: 'center',
            position: 'relative',
            alignItems: 'center',
            transition: 'all 0.24s',
            justifyContent: 'center',
            bgcolor: 'translucent.02',
            '&:hover': {
                bgcolor: 'primary.main',
                span: { color: 'common.white' },
                i: { borderColor: 'transparent transparent #fff transparent' }
            }
        }}
    >
        <Stack direction="row" alignItems="center">
            <Typography
                component="span"
                sx={{
                    flex: 'auto',
                    textAlign: 'start',
                    fontSize: 13,
                    lineHeight: '15px',
                    color: 'gold',
                    transition: 'color 0.24s'
                }}
            >
                {odd}
            </Typography>
            {change !== 0 && (
                <Box
                    component="i"
                    className={change < 0 ? 'market-arrow-up-down-bc is-down' : 'market-arrow-up-down-bc is-up'}
                    sx={{
                        right: 2,
                        width: 0,
                        height: 0,
                        display: 'block',
                        position: 'absolute',
                        borderStyle: 'solid',
                        transition: 'all 0.24s',
                        borderWidth: '0 0 8px 8px',
                        animation: 'odds-arrow-animation-change 7s forwards',

                        ...(change < 0
                            ? {
                                  bottom: 3,
                                  borderColor: 'transparent transparent red transparent'
                              }
                            : {
                                  top: 3,
                                  transform: 'rotate(270deg)',
                                  borderColor: 'transparent transparent #16c72e transparent'
                              })
                    }}
                />
            )}
        </Stack>
    </Button>
);

export default CalendarOdd;
