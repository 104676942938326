import { useMemo, useState } from 'react';
// @mui
import { Badge, BadgeProps, Button, Stack, styled, Typography, useTheme } from '@mui/material';
// other
import { Icon } from '@iconify/react';
// hook
import { usePathname, useRouter } from 'routes/hook';
import { paths } from 'routes/paths';
// store
import { useSelector } from 'store/store';
import BetSlipDialog from 'components/Modal/BetSlipDialog';

const StyledBtn = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
    flex: 1
}));
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        minWidth: '17px',
        height: '17px',
        right: -1,
        top: 5,
        padding: '0px',
        fontSize: '10px'
    }
}));

const BottomBar = () => {
    const theme = useTheme();
    const router = useRouter();
    const pathname = usePathname();
    const { odds } = useSelector((state) => state.sports);

    const [openBetSlip, setOpenBetSlip] = useState<boolean>(false);

    const selectedBtn = useMemo(() => {
        // 0 = live sports | 1 = pre sports | 2 = casino
        if (pathname.indexOf('live') > 0) {
            return 0;
        }
        if (pathname.indexOf('pre-match') > 0) {
            return 1;
        }
        return 2;
    }, [pathname]);

    const navigate = (path: string) => {
        router.replace(path);
    };

    return (
        <Stack
            direction="row"
            width={1}
            sx={{
                position: 'fixed',
                bottom: 0,
                zIndex: 999,
                height: '49px',
                bgcolor: 'background.bottomBar',
                alignItems: 'center',
                borderTop: '1px solid #ffffff4d',
                backdropFilter: 'blur(3px)',
                justifyContent: 'space-between',
                py: 2
            }}
        >
            <StyledBtn onClick={() => navigate(paths.sports.live.event_view)}>
                <StyledBadge badgeContent={4} color="primary">
                    <Icon
                        icon="carbon:circle-filled"
                        style={{ width: 22, height: 22 }}
                        color={selectedBtn === 0 ? 'white' : theme.palette.grey[500]}
                    />
                </StyledBadge>
                <Typography color={selectedBtn === 0 ? 'white' : 'textSecondary'} fontSize={10}>
                    Live
                </Typography>
            </StyledBtn>
            <StyledBtn onClick={() => navigate(paths.sports.pre.event_view)}>
                <Icon
                    icon="material-symbols:timer-outline"
                    style={{ width: 22, height: 22 }}
                    color={selectedBtn === 1 ? 'white' : theme.palette.grey[500]}
                />
                <Typography fontSize={10} color={selectedBtn === 1 ? 'white' : 'textSecondary'}>
                    Sports
                </Typography>
            </StyledBtn>
            <StyledBtn onClick={() => setOpenBetSlip(!openBetSlip)}>
                <StyledBadge badgeContent={odds.length} color="primary">
                    <Icon
                        icon="solar:hamburger-menu-bold"
                        style={{ width: 22, height: 22 }}
                        color={theme.palette.grey[500]}
                    />
                </StyledBadge>

                <Typography color="textSecondary" fontSize={10}>
                    BetSlip
                </Typography>
            </StyledBtn>
            <StyledBtn>
                <Icon icon="map:casino" style={{ width: 22, height: 22 }} color={theme.palette.grey[500]} />
                <Typography color="textSecondary" fontSize={10}>
                    Casino
                </Typography>
            </StyledBtn>
            <StyledBtn>
                <Icon icon="material-symbols:menu" style={{ width: 22, height: 22 }} color={theme.palette.grey[500]} />
                <Typography color="textSecondary" fontSize={10}>
                    Menu
                </Typography>
            </StyledBtn>
            <BetSlipDialog flag={openBetSlip} setFlag={setOpenBetSlip} />
        </Stack>
    );
};
export default BottomBar;
