import { ICompetitionMarket, ICompetitor, IMarket, ISelection } from 'types/sports';

export const getOddName = (market: ICompetitionMarket | IMarket, selection: ISelection, competitors: ICompetitor[]) => {
    let oddName = selection.name;
    if (oddName.includes('{$competitor1}')) {
        oddName = oddName.replaceAll('{$competitor1}', competitors[0].name);
    }
    if (oddName.includes('{$competitor2}')) {
        oddName = oddName.replaceAll('{$competitor2}', competitors[1].name);
    }
    if (oddName.includes('{!cornernr}')) {
        oddName = oddName.replaceAll('{$competitor2}', competitors[1].name);
    }
    if (oddName.includes('{+hcp}')) {
        oddName = oddName.replaceAll('{+hcp}', `${market.parameters.hcp}`);
    }
    if (oddName.includes('{-hcp}')) {
        oddName = oddName.replaceAll('{-hcp}', `${market.parameters.hcp}`);
    }
    if (oddName.includes('{hcp}')) {
        oddName = oddName.replaceAll('{hcp}', `${market.parameters.hcp}`);
    }
    if (oddName.includes('{total}')) {
        oddName = oddName.replaceAll('{total}', `${market.parameters.total}`);
    }
    if (oddName.includes('{MarketBand}')) {
        oddName = oddName.replaceAll('{MarketBand}', `${market.parameters.marketBand}`);
    }
    return oddName;
};

export const getMarketName = (market: IMarket, competitors: ICompetitor[]) => {
    let marketName = market.name;
    if (marketName.includes('{$competitor1}')) {
        marketName = marketName.replaceAll('{$competitor1}', competitors[0].name);
    }
    if (marketName.includes('{$competitor2}')) {
        marketName = marketName.replaceAll('{$competitor2}', competitors[1].name);
    }
    if (marketName.includes('{hcp}')) {
        marketName = marketName.replaceAll('{hcp}', market.parameters.hcp);
    }
    if (marketName.includes('{cornernr}')) {
        marketName = marketName.replaceAll('{$cornernr}', `${market.parameters.cornernr}`);
    }
    if (marketName.includes('{!goalnr}')) {
        marketName = marketName.replaceAll('{!goalnr}', `${market.parameters.goalnr}`);
    }
    return marketName;
};
