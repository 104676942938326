import { useEffect, useState } from 'react';
// hook
import { useBoolean } from 'hooks/use-boolean';
//  @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// api
import { getOpenBets } from 'api/sports';
// types
import { IBetItem } from 'types/sports';
import { formatDate } from 'utils/format-number';
import OpenBetOdd from './OpenbetOdd';

const OpenBets = () => {
    const loading = useBoolean(true);

    const [openBets, setOpenBets] = useState<IBetItem[]>([]);

    const loadOpenBets = async () => {
        try {
            loading.onTrue();
            const bets = await getOpenBets();
            setOpenBets(bets);
        } catch (error) {
            console.error(error);
        } finally {
            loading.onFalse();
        }
    };

    useEffect(() => {
        loadOpenBets();
        // eslint-disable-next-line
    }, []);
    return (
        <Stack>
            <Stack alignItems="end">
                <Link
                    sx={{
                        fontSize: '14px',
                        color: 'translucent.06',
                        cursor: 'pointer',
                        mr: 0.5,
                        textDecoration: 'underline'
                    }}
                >
                    Go to bet history
                </Link>
            </Stack>

            <Stack spacing={0.5}>
                {openBets.map((bet, i) => (
                    <Stack key={i} sx={{ borderRadius: 1, bgcolor: 'translucent.01', mb: '10px', p: '10px' }}>
                        <Stack flexDirection="row">
                            <Stack flexDirection="row" alignItems="center">
                                {bet.kind === 1 ? (
                                    <Box component="i" className="bet-history-express-icon bc-i-multiple-bet" />
                                ) : (
                                    <Box component="i" className="bet-history-express-icon bc-i-single-bet" />
                                )}
                                <Stack sx={{ color: 'translucent.06', mx: '5px' }}>
                                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                                        {bet.kind === 1 ? 'Multiple' : 'Single'}
                                    </Typography>
                                    <Stack
                                        flexDirection="row"
                                        sx={{ fontSize: '11px', alignItems: 'center', gap: 0.4 }}
                                    >
                                        <Typography sx={{ fontSize: '11px', color: 'translucent.03' }}>ID:</Typography>
                                        4822416869
                                        <Box component="i" className="bc-i-copy" />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack
                                justifyContent="center"
                                sx={{ marginInlineStart: '10px', fontSize: '11px', width: '100%' }}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        color: '#aa7f00',
                                        fontSize: '11px',
                                        textAlign: 'end'
                                    }}
                                >
                                    unsettled
                                </Typography>
                                <Box component="time" sx={{ color: '#ffffff73', textAlign: 'end' }}>
                                    {formatDate(bet.createdAt)}
                                </Box>
                            </Stack>
                        </Stack>
                        <Stack sx={{ mt: '10px', pt: '10px', borderTop: '1px solid #ffffff1a' }}>
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Typography fontSize="12px" sx={{ color: 'translucent.06' }}>
                                    Stake...
                                </Typography>
                                <Typography sx={{ fontSize: '12px', color: 'translucent.07' }}>{bet.amount}</Typography>
                            </Stack>
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Typography fontSize="12px" sx={{ color: 'translucent.06' }}>
                                    Odds (Decimal)
                                </Typography>
                                <Typography sx={{ fontSize: '12px', color: '#ffd700' }}>{bet.price}</Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{ mt: '10px', pt: '10px', borderTop: '1px solid #ffffff1a' }}>
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Typography fontSize="12px" sx={{ color: 'translucent.06' }}>
                                    Possible win
                                </Typography>
                                <Typography sx={{ fontSize: '12px', color: '#ffd700' }}>{bet.possibleWin}</Typography>
                            </Stack>
                        </Stack>
                        <OpenBetOdd bet={bet} />
                        <Stack sx={{ mt: '10px', pt: '10px', borderTop: '1px solid #ffffff1a' }}>
                            <Box
                                component="i"
                                className="bet-history-function-icon bc-i-repeat"
                                sx={{
                                    bgcolor: 'translucent.01',
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                            />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default OpenBets;
