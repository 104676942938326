import { ILavelNumber } from 'types/sports';

const { NODE_ENV } = process.env;

export const HOST_API_KEY =
    NODE_ENV === 'production' ? process.env.REACT_APP_HOST_API : process.env.REACT_APP_LOCAL_HOST_API;

export const PATH_AFTER_LOGIN = '/auth/login';

export const STYLE = {
    layoutWidth: 1670,
    headerHeight: 56,
    m_headerHeight: 49,
    subHeaderHeight: 36,
    borderRadius: '4px',
    backgroundDuration: '.24s'
};

export const intervalTimes = {
    prematchIntervalTime: 1000 * 60
};

// ======= Result Tab in Sports Page =======
export const RESULT_PAGES = ['live', 'finished'];

export const ACCEPT_ODD_TYPE: ILavelNumber[] = [
    { value: 0, label: 'Always ask' },
    { value: 2, label: 'Accept Higher odds' },
    { value: 1, label: 'Accept Any odds' }
];
