import { useEffect, useMemo, useState } from 'react';
// @mui
import { Box, Button, Collapse, IconButton, Popover, Skeleton, Stack, Typography, useTheme } from '@mui/material';
// others
import { Icon } from '@iconify/react';
import { paths } from 'routes/paths';
// components
import MarketBtn from 'components/Sports/MarketBtn';
import OddButton from 'components/Sports/OddButton';
// hooks
import { useParams, useRouter } from 'routes/hook';
import { useBoolean } from 'hooks/use-boolean';
import {
    Event,
    ICompetitionEvent,
    ILeagueList,
    ILeagueParams,
    IMarketType,
    IPrematchCateogryCompetition,
    ISportCategory,
    ISportParams
} from 'types/sports';
// store
import { RootState, useDispatch, useSelector } from 'store/store';
import { selectCompetition, setCompetitionEvents, setLeagueList } from 'store/slices/sports';
// api
import { getCompetitionEvents, getPrematchCategory } from 'api/sports';
import { getOddName } from 'utils/sport';
import { useSportContext } from 'hooks/use-sport-context';

const MatchItem = ({ league, selectedMarket }: { league: ICompetitionEvent; selectedMarket: IMarketType }) => {
    const theme = useTheme();
    const { sportId, countryId, competitionId } = useSportContext();
    const router = useRouter();
    const handleClickEvent = () => {
        router.push(`${paths.sports.pre.event_view}/${sportId}/${countryId}/${competitionId}/${league.eventId}`);
    };
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                handleClickEvent();
                e.stopPropagation();
            }}
        >
            <Stack direction="row" minHeight={52}>
                <Stack
                    flex={1}
                    sx={{
                        padding: '5px',
                        bgcolor: 'translucent.01',
                        gap: 0.3
                    }}
                >
                    {league.eventInfo.competitors.length > 0 ? (
                        <>
                            <Typography fontSize={12}>{league.eventInfo.competitors[0].name}</Typography>
                            <Typography fontSize={12}>{league.eventInfo.competitors[1].name}</Typography>
                        </>
                    ) : (
                        <Typography fontSize={12}>{league.eventInfo.name}</Typography>
                    )}
                </Stack>
                {(() => {
                    const market = league.markets.find((m) => m.id === selectedMarket.id);
                    if (!market) return null;
                    return market.selections.map((selection, i) => {
                        const oddName = getOddName(market, selection, league.eventInfo.competitors);
                        return (
                            <OddButton
                                key={i}
                                eventInfo={league.eventInfo}
                                oddName={oddName}
                                marketName={market.name}
                                marketId={market.id}
                                tradingStatus={market.tradingStatus}
                                selection={selection}
                            />
                        );
                    });
                })()}
            </Stack>
            <Stack
                flex={1}
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01'
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon
                        icon="material-symbols:timer-outline"
                        style={{ width: 15, height: 15 }}
                        color={theme.palette.grey[500]}
                    />
                    <Typography color="textSecondary" fontSize={11}>
                        02:45
                    </Typography>
                </Stack>
                <Typography color="textSecondary" fontSize={12}>
                    +319
                </Typography>
            </Stack>
        </Stack>
    );
};
const MatchGroupByDate = ({
    event,
    title,
    selectedMarket
}: {
    event: ICompetitionEvent[];
    title: string;
    selectedMarket: IMarketType;
}) => {
    const isOpened = useBoolean(true);
    return (
        <Box
            sx={{
                bgcolor: 'translucent.01',
                transition: 'all 0.24s'
            }}
            onClick={isOpened.onToggle}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 33,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        color="textSecondary"
                        sx={{
                            fontSize: 11,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Stack
                    position="relative"
                    direction="row"
                    justifyContent="space-around"
                    flex={1}
                    sx={{
                        boxShadow: '-1px 0 0 0 #000c24'
                    }}
                >
                    <Typography fontSize={11}>W1</Typography>
                    <Typography fontSize={11}>X</Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontSize={11}>W2</Typography>
                        <Box
                            component="i"
                            className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 11,
                                borderRadius: 1,
                                lineHeight: 23,
                                transition: 'color 0.24s, background 0.24s',
                                color: isOpened.value ? 'common.white' : 'translucent.06',
                                ...(isOpened.value && {
                                    transform: 'rotate(180deg)'
                                })
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    px={0.5}
                    sx={{
                        gap: 1,
                        pb: 0.5
                    }}
                >
                    {event.map((league, index) => (
                        <MatchItem key={index} league={league} selectedMarket={selectedMarket} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const SportMatches = () => {
    const router = useRouter();
    const { prematchCategory, countryId, sportId, competitionId, competitionEvents, competitionLoading, marketType } =
        useSportContext();
    const [leagueAanchor, setLeagueAnchor] = useState<HTMLElement | null>(null);
    const [selectedMarket, setSelectedMarket] = useState<IMarketType>({ id: 0, name: '' });
    const [selectedLeague, setSelectedLeague] = useState<IPrematchCateogryCompetition | null>(null);
    const [countryName, setCountryName] = useState<string>('');
    const [sportName, setSportName] = useState<string>('');
    const changeSMarket = (market: IMarketType) => {
        setSelectedMarket(market);
    };
    const openLeaguePopover = (event: React.MouseEvent<HTMLElement>) => {
        setLeagueAnchor(event.currentTarget as HTMLElement);
    };
    const closeLeaguePopover = (league: IPrematchCateogryCompetition | null) => {
        if (league) {
            setSelectedLeague(league);
            router.push(`${paths.sports.pre.event_view}/${sportId}/${countryId}/${league.competitionId}`);
        }
        setLeagueAnchor(null);
    };

    const leagues = useMemo(() => {
        const sport = prematchCategory.find((item) => String(item.sportId) === sportId);
        if (sport) {
            setSportName(sport.sportName);
            const country = sport.country.find((item) => String(item.countryId) === countryId);
            if (country) {
                setCountryName(country.countryName);
                const league = country.competition.find((item) => String(item.competitionId) === competitionId);
                if (league) {
                    setSelectedLeague(league);
                }
                return country.competition;
            }
            return [];
        }
        return [];
    }, [prematchCategory]);

    return (
        <Box sx={{ gridArea: 'unset', width: '100%' }}>
            {competitionLoading ? (
                <>
                    <Skeleton
                        sx={{
                            justifyContent: 'flex-start',
                            pl: 1,
                            background: 'transparent',
                            marginTop: -1,
                            height: 40,
                            width: '100%'
                        }}
                    ></Skeleton>
                    <Stack
                        direction="row"
                        px={1}
                        gap={0.5}
                        py={0.5}
                        width={1}
                        bgcolor="translucent.01"
                        alignItems="center"
                    >
                        <Skeleton
                            sx={{
                                px: 1,
                                justifyContent: 'space-between',
                                flex: 1,
                                py: 1.5,
                                bgcolor: 'translucent.05',
                                borderRadius: '4px'
                            }}
                        />
                    </Stack>
                    <Box
                        width={1}
                        sx={{
                            mt: 0.1,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            '& ::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '& ::-moz-scrollbar': {
                                display: 'none'
                            },
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none'
                        }}
                    >
                        <Skeleton sx={{ borderRadius: 1, py: 2 }} />
                    </Box>
                    <Stack sx={{ width: 1, minHeight: '100%', mt: 0.1 }} spacing={0.1}>
                        {new Array(2).fill(1).map((_, i) => (
                            <>
                                <Stack
                                    sx={{
                                        bgcolor: 'translucent.01',
                                        transition: 'all 0.24s',
                                        py: 1.5,
                                        mt: 0.4
                                    }}
                                >
                                    <Skeleton sx={{ width: 0.6, height: '10px' }} />
                                </Stack>
                                {new Array(3).fill(1).map((_, i) => (
                                    <Stack sx={{ mb: 0.5 }} key={i}>
                                        <Stack flexDirection="row">
                                            <Stack
                                                sx={{
                                                    width: 0.5,
                                                    gap: 1,
                                                    py: 1,
                                                    px: 0.5,
                                                    bgcolor: 'translucent.01'
                                                }}
                                            >
                                                <Skeleton sx={{ width: 0.6, height: '10px' }} />
                                                <Skeleton sx={{ width: 0.6, height: '10px' }} />
                                            </Stack>
                                            <Stack
                                                flexDirection="row"
                                                sx={{
                                                    width: 0.5,
                                                    bgcolor: 'translucent.02',
                                                    justifyContent: 'space-around',
                                                    px: 1,
                                                    py: 1
                                                }}
                                            >
                                                <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                                <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                                <Skeleton sx={{ width: '30px', height: '30px', borderRadius: 1 }} />
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            sx={{
                                                py: 0.5,
                                                bgcolor: 'translucent.01',
                                                mt: 0.1
                                            }}
                                        >
                                            <Skeleton sx={{ width: 0.6, height: '7px' }} />
                                        </Stack>
                                    </Stack>
                                ))}
                            </>
                        ))}
                    </Stack>
                </>
            ) : (
                <>
                    <Button
                        size="small"
                        onClick={() => router.back()}
                        fullWidth
                        sx={{
                            justifyContent: 'flex-start',
                            pl: 1,
                            background: 'transparent',
                            marginTop: -1,
                            height: 40
                        }}
                        startIcon={
                            <Icon icon="icon-park-solid:left-c" style={{ width: 22, height: 22, color: '#fffc' }} />
                        }
                    >
                        <Typography textTransform="uppercase" fontSize={14}>
                            {sportName} / {countryName}
                        </Typography>
                    </Button>
                    <Stack direction="row" px={1} gap={0.5} width={1} bgcolor="translucent.01" alignItems="center">
                        <Button
                            onClick={openLeaguePopover}
                            size="small"
                            endIcon={<Icon icon="lsicon:down-outline" width="24" height="24" />}
                            sx={{
                                px: 1,
                                justifyContent: 'space-between',
                                flex: 1,
                                bgcolor: 'translucent.05'
                            }}
                        >
                            {selectedLeague?.competitionName}
                        </Button>

                        <IconButton
                            sx={{
                                px: 0
                            }}
                        >
                            <Icon icon="mdi:star-outline" width="22" height="22" />
                        </IconButton>
                    </Stack>
                    <Box
                        width={1}
                        sx={{
                            mt: 0.1,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            '& ::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '& ::-moz-scrollbar': {
                                display: 'none'
                            },
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none'
                        }}
                    >
                        <Stack direction="row" sx={{ width: '100%', overflow: 'auto', position: 'relative' }}>
                            {marketType.map((market, i) => (
                                <MarketBtn
                                    changeSMarket={changeSMarket}
                                    isActive={selectedMarket?.name === market.name}
                                    market={market}
                                    key={i}
                                />
                            ))}
                        </Stack>
                    </Box>
                    <Stack sx={{ width: 1, minHeight: '100%', mt: 0.1 }} spacing={0.1}>
                        {Object.keys(competitionEvents).map((event, i) => (
                            <MatchGroupByDate
                                key={i}
                                event={competitionEvents[event]}
                                title={event}
                                selectedMarket={selectedMarket}
                            />
                        ))}
                    </Stack>
                </>
            )}

            {/* league popover */}
            <Popover
                id="market-popover"
                open={Boolean(leagueAanchor)}
                anchorEl={leagueAanchor}
                onClose={() => closeLeaguePopover(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: '90%',
                        marginLeft: '-7px',
                        maxHeight: 230,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 0px 0px #0000004d, inset 0 0 0 100px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {leagues.map((league, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLeaguePopover(league)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{league.competitionName}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};
export default SportMatches;
