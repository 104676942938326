// @mui
import { Stack } from '@mui/material';
import Live from './Live';
import PreMatch from './Prematch';

type propsType = {
    isLive: boolean;
};

const MobileListBar = (props: propsType) => {
    const { isLive } = props;

    return <Stack sx={{ width: '100%', transition: 'width 0.24s' }}>{isLive ? <Live /> : <PreMatch />}</Stack>;
};

export default MobileListBar;
