// axios
import axios, { endpoints } from 'utils/axios';

export const getPrematchCategory = async (period: string) => {
    const response = await axios.get(`${endpoints.sport}/prematch/category/${period}`);
    return response.data;
};

export const getCompetitionEvents = async (sportId: string, competitionId: string) => {
    const response = await axios.get(`${endpoints.sport}/event/${sportId}/${competitionId}`);
    return response.data;
};

export const getEvent = async (eventId: string) => {
    const response = await axios.get(`${endpoints.sport}/event/${eventId}`);
    return response.data;
};

export const placeBet = async (betData: any) => {
    const response = await axios.post(`${endpoints.sport}/bet`, betData);
    return response.data;
};

export const getLiveCategory = async () => {
    const response = await axios.get(`${endpoints.sport}/live/category`);
    return response.data;
};

export const getLiveCategoryEvent = async () => {
    const response = await axios.get(`${endpoints.sport}/live/category/events`);
    return response.data;
};

export const getLiveCategoryEvents = async () => {
    const response = await axios.get(`${endpoints.sport}/live/category/events`);
    return response.data;
};

export const getEventCount = async () => {
    const response = await axios.get(`${endpoints.sport}/event-count`);
    return response.data;
};

export const getPeriods = async () => {
    const response = await axios.get(`${endpoints.sport}/period`);
    return response.data;
};

export const getOpenBetCount = async () => {
    const response = await axios.get(`${endpoints.sport}/open-bet-count`);
    return response.data;
};

export const getOpenBets = async () => {
    const response = await axios.get(`${endpoints.sport}/open-bets`);
    return response.data;
};
