import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

interface Props extends React.HTMLAttributes<HTMLFormElement> {
    children: React.ReactNode;
    methods: UseFormReturn<any>;
    onSubmit?: VoidFunction;
}

export default function FormProvider({ children, onSubmit, methods, ...other }: Props) {
    return (
        <Form {...methods}>
            <form onSubmit={onSubmit} {...other}>
                {children}
            </form>
        </Form>
    );
}
