import { useState } from 'react';
// mui
import { Box, Button, IconButton, Popover, Skeleton, Stack, Typography } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
// assets
import { PERIOD_LIST } from 'assets/data/matchList';
// hook
import { useSportContext } from 'hooks/use-sport-context';
// component
import SportBtn from 'components/Sports/SportBtn';
// type
import UpcomingList from '../UpcomingList';

const matchesNavigation = [
    {
        title: 'Upcoming Matchs',
        icon: <Icon icon="material-symbols:calendar-clock" width="14" height="14" color="#0097b7" />,
        color: '#0097b7'
    },
    {
        title: 'Popular Matchs',
        icon: <Icon icon="solar:cup-star-bold" width="14" height="14" color="#583de3" />,
        color: '#583de3'
    },
    {
        title: 'Popular competitions',
        icon: <Icon icon="solar:cup-star-bold" width="14" height="14" color="#2275e9" />,
        color: '#2275e9'
    }
];

const PreMatch = () => {
    const { period, prematchCategory, premacthLoading, upcomingCount, updatePeriod } = useSportContext();
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Box
                width={1}
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    minHeight: '36px',
                    '& ::-webkit-scrollbar': {
                        height: '0px',
                        display: 'none'
                    },
                    '& ::-moz-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                }}
            >
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        width: 'fit-content',
                        borderRadius: 1,
                        overflowX: 'scroll'
                    }}
                >
                    {matchesNavigation.map((item, key) => (
                        <Button
                            variant="soft"
                            key={key}
                            startIcon={item.icon}
                            sx={{
                                py: 0.5,
                                bgcolor: 'translucent.01',
                                minWidth: 'fit-content',
                                px: 2
                            }}
                        >
                            {item.title}
                        </Button>
                    ))}
                </Stack>
            </Box>
            <Stack sx={{ borderRadius: 1, py: 0.7 }} direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1}>
                    <Button
                        size="small"
                        onClick={openMarketPopover}
                        sx={{ bgcolor: 'translucent.04', py: 0, px: 1 }}
                        startIcon={
                            <Box
                                component="i"
                                className="select-icon-bc bc-i-time"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '14px !important',
                                    width: 26,
                                    height: 26,
                                    color: '#ffffffe6',
                                    textAlign: 'center'
                                }}
                            />
                        }
                        endIcon={
                            <Box
                                component="i"
                                className="form-control-icon-bc bc-i-small-arrow-down"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '10px !important',
                                    width: 20,
                                    height: 20,
                                    textAlign: 'center'
                                }}
                            />
                        }
                    >
                        {period.label}
                    </Button>
                    <Button variant="outlined" size="small">
                        <Box
                            component="i"
                            className="filter-icon-bc bc-i-bet-builder"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '14px !important',
                                width: 26,
                                height: 26,
                                color: '#ffffffe6',
                                textAlign: 'center'
                            }}
                        />
                    </Button>
                </Stack>

                <IconButton
                    sx={{
                        borderRadius: 1,
                        width: 36,
                        height: 36,
                        border: 'solid 1px translucent.10'
                    }}
                >
                    <Icon
                        icon="heroicons-solid:search"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: 'auto',
                            width: 22,
                            height: 22,
                            color: '#ffffff80'
                        }}
                    />
                </IconButton>
                <Popover
                    id="market-popover"
                    open={Boolean(marketAanchor)}
                    anchorEl={marketAanchor}
                    onClose={closeLangPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    sx={{
                        '.MuiPopover-paper': {
                            p: 0,
                            minWidth: 100,
                            maxHeight: 227,
                            bgcolor: 'background.default',
                            boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                        }
                    }}
                >
                    <Stack sx={{ p: 0.5 }} spacing={0.2}>
                        {PERIOD_LIST.map((item, index) => (
                            <Stack
                                key={index}
                                onClick={() => {
                                    updatePeriod(item);
                                    closeLangPopover();
                                }}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    height: 30,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    bgcolor: 'translucent.01',
                                    '&:hover': {
                                        bgcolor: 'translucent.02'
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{item.label}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Popover>
            </Stack>
            <Box
                width={1}
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '& ::-moz-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        mt: 0.2,
                        gap: 0.5,
                        mb: 0.5,
                        width: '100%',
                        overflow: 'auto',
                        position: 'relative'
                    }}
                >
                    {(() => {
                        if (premacthLoading) {
                            return new Array(6).fill(1).map((_, i) => (
                                <Skeleton
                                    key={`skeleton-${i}`}
                                    variant="rounded"
                                    sx={{
                                        width: '80px',
                                        minWidth: '80px',
                                        borderRadius: '4px',
                                        position: 'initial'
                                    }}
                                    height={60}
                                />
                            ));
                        }

                        // Directly return this if loading.value is true
                        return prematchCategory.map((item, i) => <SportBtn sport={item} key={i} />);
                    })()}
                </Stack>
            </Box>
            <UpcomingList period={period} isLoading={premacthLoading} />
        </>
    );
};

export default PreMatch;
