import { useEffect, useState } from 'react';
import { Box, Button, Popover, Skeleton, Stack, Typography } from '@mui/material';
// types
import { ICompetitionEvent, IMarketType } from 'types/sports';
// hooks
import { useSportContext } from 'hooks/use-sport-context';
import Matchs from '../Matchs';

export default function SingleColumnEvent({
    loading,
    eventList
}: {
    loading: boolean;
    eventList: { [key: string]: ICompetitionEvent[] };
}) {
    const { marketType } = useSportContext();
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);
    const [selectedMarket, setSelectedMarket] = useState<IMarketType>({ id: 0, name: '' });

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        if (!marketAanchor) {
            setMarketAnchor(event.currentTarget);
        }
    };

    useEffect(() => {
        if (marketType && marketType.length > 0) {
            setSelectedMarket(marketType[0]);
        } else {
            setSelectedMarket({ id: 0, name: '' });
        }
    }, [marketType]);

    const closeLangPopover = (item?: IMarketType) => {
        if (item) {
            setSelectedMarket(item);
        }
        setMarketAnchor(null);
    };

    return (
        <>
            {loading ? (
                <>
                    <Skeleton sx={{ width: 1, height: '30px', borderRadius: '2px' }} />
                    <Box>
                        {new Array(8).fill(1).map((_, i) => (
                            <Box sx={{ borderRadius: 1, overflow: 'hidden', mt: 0.25 }} key={i}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        minHeight: 34,
                                        mb: 0.1,
                                        py: 0.5,
                                        px: 1,
                                        bgcolor: 'translucent.04',
                                        transition: 'background 0.24s',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'translucent.09'
                                        }
                                    }}
                                >
                                    <Skeleton sx={{ width: '60%', height: '7px' }} />
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={0.1} width="100%">
                                    <Stack
                                        width="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ bgcolor: 'translucent.01', height: '35px', px: '10px' }}
                                    >
                                        <Skeleton sx={{ width: '50%', height: '7px' }} />
                                        <Skeleton sx={{ width: '25px', height: '25px', borderRadius: '4px' }} />
                                    </Stack>
                                    <Stack
                                        width="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ bgcolor: 'translucent.01', height: '35px', px: '10px' }}
                                    >
                                        <Skeleton sx={{ width: '50%', height: '7px' }} />
                                        <Skeleton sx={{ width: '25px', height: '25px', borderRadius: '4px' }} />
                                    </Stack>
                                    <Stack
                                        width="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ bgcolor: 'translucent.01', height: '35px', px: '10px' }}
                                    >
                                        <Skeleton sx={{ width: '50%', height: '7px' }} />
                                        <Skeleton sx={{ width: '25px', height: '25px', borderRadius: '4px' }} />
                                    </Stack>
                                </Stack>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        height: 24,
                                        mt: 0.1,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.01'
                                    }}
                                >
                                    <Skeleton width="100%" height="100%" sx={{ borderRadius: '0' }} />
                                </Stack>
                            </Box>
                        ))}
                    </Box>
                </>
            ) : (
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ borderRadius: 1, bgcolor: 'translucent.04', height: 28 }}
                    >
                        <Box sx={{ flex: 'auto', height: 1 }}>
                            <Button
                                onClick={openMarketPopover}
                                sx={{
                                    minWidth: '115px',
                                    height: 1,
                                    py: 0,
                                    px: 1.5,
                                    borderStartEndRadius: 0,
                                    borderEndEndRadius: 0,
                                    borderRight: '1px solid',
                                    borderColor: 'background.default'
                                }}
                                endIcon={
                                    <Box
                                        component="i"
                                        className="form-control-icon-bc bc-i-small-arrow-down"
                                        sx={{ fontSize: '12px !important', color: '#fffc' }}
                                    />
                                }
                            >
                                {selectedMarket.name || 'Select Market'}
                            </Button>
                        </Box>
                    </Stack>

                    <Stack spacing={0.2}>
                        {Object.keys(eventList).map((key) => (
                            <Matchs key={key} events={eventList[key]} date={key} marketType={selectedMarket} />
                        ))}
                    </Stack>
                </>
            )}

            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={() => closeLangPopover()} // Close without selecting a market
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 118,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {marketType.map((item) => (
                        <Stack
                            key={item.name}
                            onClick={() => closeLangPopover(item)}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>{item.name}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
}
