import { useMemo } from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { STYLE } from 'config';
import { paths } from 'routes/paths';
import { useMediaQuery, useTheme } from '@mui/material';

const sportNavigation = [
    {
        title: 'Event View',
        path: paths.sports.pre.event_view
    },
    {
        title: 'Live Calendar',
        path: paths.sports.pre.live_calendar
    },
    {
        title: 'Results',
        path: paths.sports.pre.results
    }
];

const SportHeader = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isHome = useMemo(() => pathname.indexOf('home') < 0, [pathname]);

    if (isHome) {
        return (
            <Stack
                component="nav"
                direction="row"
                alignItems="center"
                justifyContent={isMobile ? 'space-between' : 'center'}
                spacing={isMobile ? 0 : 3}
                sx={{
                    height: STYLE.subHeaderHeight,
                    bgcolor: isMobile ? 'translucent.01' : 'unset'
                }}
            >
                {sportNavigation.map((sport, rIndex) => {
                    const isActiveTab = pathname.split('/')[3] === sport.path.split('/')[3];
                    return (
                        <Stack
                            key={rIndex}
                            alignItems="center"
                            justifyContent="center"
                            component={ReactLink}
                            to={sport.path}
                            sx={{
                                p: 0,
                                height: 1,
                                width: { md: 'auto', xs: 1 },
                                borderRadius: 0,
                                letterSpacing: 0.5,
                                textTransform: isMobile ? 'uppercase' : 'capitalize',
                                color: isActiveTab ? 'common.white' : 'translucent.03',
                                boxShadow: isActiveTab
                                    ? `inset 0 -2px 0 0 ${theme.palette.primary.main}`
                                    : `inset 0 -2px 0 0 #ffffff0d`,
                                transition: 'color .24s, box-shadow .24s',
                                '&:hover': {
                                    color: 'common.white',
                                    boxShadow: `inset 0 -2px 0 0 ${theme.palette.primary.main}`
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? 10 : 16,
                                    fontWeight: 500
                                }}
                            >
                                {sport.title}
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>
        );
    }

    return null;
};

export default SportHeader;
