import { useState } from 'react';
// mui
import { Box, Stack, Tab, Tabs } from '@mui/material';

const RenderMarketName = ({ markets }: { markets: { id: number; name: string }[] }) => {
    const [value, setValue] = useState(0);

    const handleChange = (newValue: number) => {
        setValue(newValue);
    };

    return (
        <Stack
            direction="row"
            mt={1}
            sx={{
                position: 'sticky',
                top: 0,
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.default}`,
                background: 'background.default',
                zIndex: 1,
                borderRadius: 1,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: 1,
                    height: 36,
                    maxWidth: 36,
                    cursor: 'pointer',
                    zIndex: 3,
                    right: 0,
                    left: 0
                }}
            >
                <Box
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 37,
                        height: 36,
                        bgcolor: 'translucent.02',
                        position: 'absolute',
                        top: 0,
                        borderRadius: 0,
                        left: 0
                    }}
                >
                    <Box
                        component="i"
                        className="ss-icon-bc bc-i-search"
                        sx={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: 16,
                            height: 16,
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: 'auto',
                            fontSize: 16,
                            color: '#fffc'
                        }}
                    />
                </Box>
            </Box>

            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    gap: 0.1,
                    width: 1,
                    ml: 3.7,
                    position: 'relative',
                    willChange: 'scroll-position',
                    height: 36,
                    bgcolor: 'translucent.01',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    scrollbarWidth: 'none'
                }}
            >
                <Tabs
                    value={value}
                    onChange={(_, newValue) => handleChange(newValue)}
                    variant="scrollable"
                    scrollButtons={false}
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: 'primary.main'
                        }
                    }}
                    sx={{
                        // width: 'calc(100% - 37px - 61px)',
                        width: '100%',
                        minHeight: 0,
                        borderRadius: 0
                    }}
                >
                    {markets.map((market, i) => (
                        <Tab
                            key={i}
                            label={market.name}
                            sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                        />
                    ))}
                </Tabs>
            </Stack>

            {/* <Stack direction="row" alignItems="center" sx={{ px: 1, bgcolor: 'translucent.01', zIndex: 1 }}>
                <Box
                    component="i"
                    className="bc-i-one-block "
                    sx={{
                        color: 'translucent.03',
                        fontSize: 18,
                        cursor: 'pointer',
                        transition: 'color 0.24s'
                    }}
                />
                <Box
                    component="i"
                    className="bc-i-two-block active"
                    sx={{
                        ml: 0.5,
                        color: 'common.white',
                        fontSize: 18,
                        cursor: 'pointer',
                        transition: 'color 0.24s'
                    }}
                />
            </Stack> */}
        </Stack>
    );
};
export default RenderMarketName;
