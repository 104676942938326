import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, SelectProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = SelectProps & {
    name: string;
    label: string;
    options: { value: string; label: string }[]; // Array of options for the select
    helperText?: string;
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    borderRadius: '4px',
    '& .MuiFormLabel-root': {
        color: '#fff6',
        fontSize: '1rem',
        top: '3px'
    },
    '& .MuiInputLabel-shrink': {
        top: '16px!important',
        fontSize: '1rem'
    },
    '& .MuiFormHelperText-root.Mui-error': {
        borderRadius: '3px',
        margin: 0,
        marginTop: '3px',
        paddingLeft: '3px',
        color: 'white',
        background: '#ff344933',
        animation: 'form-control-message 0.24s ease'
    }
}));

export default function RHFSelect({ name, label, options, helperText, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <StyledFormControl fullWidth variant="outlined">
            <InputLabel>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <Select
                            {...field}
                            label={label}
                            error={!!error}
                            onChange={(event) => field.onChange(event.target.value)}
                            {...other}
                            sx={{
                                '&.MuiInputBase-root': {
                                    bgcolor: 'translucent.04'
                                }
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </>
                )}
            />
        </StyledFormControl>
    );
}
