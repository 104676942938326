import { Icon } from '@iconify/react';
// mui
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSportContext } from 'hooks/use-sport-context';

const RenderScoreBoard = () => {
    const { event, isLive } = useSportContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box>
            <Box sx={{ mb: 0.2 }}>
                <Box
                    sx={{
                        p: 1,
                        position: 'relative',
                        borderRadius: 1,
                        background: 'url(https://www.baladbet.com/assets/soccer-4729ed8e.png) no-repeat 50% 50%/cover',
                        '&::before': {
                            content: `""`,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            pointerEvents: 'none',
                            background: '#0006'
                        }
                    }}
                >
                    <Stack direction="row" alignItems="flex-start" sx={{ position: 'relative', width: 1, mb: 1 }}>
                        <Stack direction="row" alignItems="center" sx={{ flex: 'auto', flexWrap: 'wrap' }}>
                            <Box
                                component="i"
                                className="bc-i-Soccer"
                                sx={{ fontSize: 13, mb: 0.4, color: '#ffffffb3' }}
                            />
                            <Typography
                                component="span"
                                sx={{
                                    ml: 0.8,
                                    flex: 'auto',
                                    fontSize: 12,
                                    lineHeight: '14px',
                                    textTransform: 'capitalize',
                                    color: '#ccc',
                                    mb: 0.4
                                }}
                            >
                                {event.eventInfo.competition.name}
                            </Typography>
                        </Stack>
                        {/* <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            ml: 1,
                                            fontSize: 12,
                                            bgcolor: '#109121',
                                            borderRadius: 1,
                                            height: 30,
                                            px: 0.5,
                                            flexShrink: 0,
                                            fontWeight: 500,
                                            boxShadow: '0 4px 4px #0000000d'
                                        }}
                                    >
                                        Half Time
                                    </Stack> */}
                        {/* <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: 1,
                                fontSize: 12,
                                bgcolor: '#10912180',
                                borderStartStartRadius: 4,
                                borderEndStartRadius: 4,
                                height: 30,
                                px: 0.5,
                                flexShrink: 0,
                                fontWeight: 500,
                                boxShadow: '0 4px 4px #0000000d'
                            }}
                        >
                            2nd Half
                        </Stack> */}
                        {/* <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: 0.1,
                                fontSize: 12,
                                bgcolor: '#109121',
                                borderStartEndRadius: 4,
                                borderEndEndRadius: 4,
                                height: 30,
                                px: 0.5,
                                flexShrink: 0,
                                fontWeight: 500,
                                boxShadow: '0 4px 4px #0000000d'
                            }}
                        >
                            0&#39;
                        </Stack> */}
                    </Stack>

                    <Stack
                        direction="row"
                        sx={{
                            position: 'relative',
                            width: 1,
                            overflow: 'hidden',
                            py: 1,
                            background: '#0000004d',
                            boxShadow: '-11px 0 #0000004d, 11px 0 #0000004d'
                        }}
                    >
                        <Stack
                            sx={{
                                flex: 'auto',
                                overflow: 'hidden',
                                justifyContent: 'space-around'
                            }}
                        >
                            {(() => {
                                if (event) {
                                    if (event.eventInfo.competitors.length > 2) {
                                        return (
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    lineHeight: '20px',
                                                    color: '#f2f2f2',
                                                    flex: 'auto',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {event.eventInfo.name}
                                            </Typography>
                                        );
                                    }

                                    return event.eventInfo.competitors.map((competitor, i) => (
                                        <Stack direction="row" alignItems="center" key={i}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    mr: 0.4
                                                }}
                                            >
                                                <Icon icon="lsicon:clothes-filled" />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    lineHeight: '20px',
                                                    color: '#f2f2f2',
                                                    flex: 'auto',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {competitor.name}
                                            </Typography>
                                        </Stack>
                                    ));
                                }

                                return null;
                            })()}
                        </Stack>
                        <Stack sx={{ ml: 1 }}>
                            {(() => {
                                if (event) {
                                    const { eventInfo, scoreboard } = event;

                                    if (eventInfo.isOutright) return null;

                                    if (scoreboard && scoreboard.results.length > 0) {
                                        const { value: homeScore } = scoreboard.results[0];
                                        const { value: awayScore } = scoreboard.results[1];

                                        return (
                                            <>
                                                <Typography sx={{ fontSize: 16 }}>{homeScore}</Typography>
                                                <Typography sx={{ fontSize: 16 }}>{awayScore}</Typography>
                                            </>
                                        );
                                    }
                                    return null;
                                }
                                return null;
                            })()}
                        </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ position: 'relative', alignItems: 'flex-start', mt: 0.8 }}>
                        <Stack direction="row" sx={{ flex: 'auto', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: 12, lineHeight: '16px', color: '#ccc' }}>
                                {(() => {
                                    if (event) {
                                        const { eventInfo, scoreboard } = event;
                                        if (eventInfo.isOutright) return null;
                                        if (scoreboard && scoreboard.results.length > 0) {
                                            const homeScore = scoreboard.results[0].value;
                                            const awayScore = scoreboard.results[1].value;

                                            if (scoreboard.periods) {
                                                const period = scoreboard.periods.find(
                                                    (p) => p.id === scoreboard.currentPeriodId
                                                );
                                                if (
                                                    period &&
                                                    !period.isFinished &&
                                                    period.results &&
                                                    period.results.length
                                                ) {
                                                    const homePeriodScore = period.results[0].value;
                                                    const awayPeriodScore = period.results[1].value;
                                                    return `${homeScore} : ${awayScore}, (${homePeriodScore}:${awayPeriodScore})`;
                                                }
                                            }
                                            return `${homeScore} : ${awayScore}`;
                                        }
                                        return '0 : 0';
                                    }
                                    return null;
                                })()}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        spacing={0.5}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ position: 'relative', mt: 1, pt: 1, borderTop: '1px solid #a9a9c1' }}
                    >
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-head-t-head active"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-stats active"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-graph"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                    </Stack>
                </Box>
                {isLive && isMobile && (
                    <Box
                        sx={{
                            mt: 1,
                            mb: 0.2,
                            minHeight: 34,
                            overflow: 'hidden',
                            transition: 'min-height 0.24s'
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                mb: 0.2,
                                height: 34,
                                bgcolor: 'translucent.05',
                                borderRadius: 1
                            }}
                        >
                            <Box
                                component="i"
                                className="game-details-c-live-i-item-bc bc-i-field-soccer active"
                                sx={{
                                    color: 'common.white',
                                    width: 'auto',
                                    height: 20,
                                    fontSize: 14,
                                    px: 0.4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: '#0006',
                                    cursor: 'pointer',
                                    borderRadius: 1
                                }}
                            />
                        </Stack>
                        <Box>
                            <Box
                                sx={{
                                    width: 332,
                                    height: 198,
                                    mb: '-10px',
                                    transform: 'scale(.946)',
                                    transformOrigin: '0 0'
                                }}
                            >
                                <iframe
                                    title="event"
                                    src="https://widget-iframe.wadua.io"
                                    style={{ width: '100%', height: '100%', border: 0 }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
export default RenderScoreBoard;
