export const validatePassword = (password: string) => {
    const validations = {
        fieldRequire: password !== '',
        oneDigit: /\d/.test(password),
        space: !/\s/.test(password) && password !== '',
        english: /^[a-zA-Z0-9!@#$%^&*()]/.test(password) && password !== '',
        uplower: /[a-z]/.test(password) && /[A-Z]/.test(password) && password !== '',
        minimal: password.length >= 6,
        maximal: password.length <= 50 && password !== ''
    };

    const countPassed = Object.values(validations).filter(Boolean).length;

    return { validations, countPassed };
};
